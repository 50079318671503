import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  addOfficeLocation,
  updateOfficeLocation,
} from '../../actions/userActions/officelocationAction'
import { getCities } from '../../actions/userActions/cityAction'
import { Redirect, useLocation, useHistory } from 'react-router-dom'
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CancelButton from '../subComponents/buttons/CancelButton'
import SaveButton from '../subComponents/buttons/SaveButton'
import { CircularProgress } from '@mui/material'
import HrComponent from '../reuse_components/HrComponent'

function AddOfficeLocations() {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const initialValues = {
    address: '',
    contact1: '',
    contact2: '',
    email: '',
    fax: '',
    extensions: '',
    cityId: '',
    cityName: '',
    isHeadOffice: false,
  }
  const [page, setPage] = useState(1)
  const [isSubmit, setIsSubmit] = useState(false);

  const [cityFilters, setCityFilters] = useState({
    textSearch: '',
    countryId: '1',
  })
  const cityBody = {
    filter: cityFilters,
    pageNumber: page,
    pageSize: 100,
  }
  const [loading, setLoading] = useState(true); 
  const [formValues, setFormValues] = useState(initialValues)
  const [formErrors, setFormErrors] = useState({})
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getCities(cityBody));
        
        if (location.officeLocation && location.officeLocation.row) {
          setFormValues({
            ...formValues,
            address: location.officeLocation.row.address,
            contact1: location.officeLocation.row.contact1,
            contact2: location.officeLocation.row.contact2,
            email: location.officeLocation.row.email,
            fax: location.officeLocation.row.fax,
            extensions: location.officeLocation.row.extensions,
            isHeadOffice: location.officeLocation.row.isHeadOffice,
            cityId: location.officeLocation.row.cityId,
            id: location.id,
            cityName: location.officeLocation.row.cityName,
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); 
      }
    };

    fetchData();    
  }, []);

 

  

  const getOptions = (data, name) => {
    const getData =
      data && data !== 'isLoading'
        ? data.map((row, i) => ({
          value: row.id,
          label: row.name
            ? row.name
            : row.firstName && row.lastName
              ? `${row.firstName} ${row.lastName}`
              : row.address,
          name: name,
        }))
        : []
    return getData
  }

  const handleChange = (e) => {
    const val = e.target;
    const { name, value } = val;

    if (name === "contact1") {
      const isNumeric = /^[0-9]*$/.test(value); 
  
      if (isNumeric) {
        setFormValues({ ...formValues, [name]: value.slice(0, 13) });
      }
    } else if (name === "cityId") {
      const selectedCity = allCities && allCities !== "isLoading" && allCities.length !== 0 && allCities[0].cities.find(city => city.id === value);
      if (selectedCity) {
        setFormValues({ ...formValues, [name]: selectedCity.id, cityName: selectedCity.name });
      }
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
    if (name === 'isHeadOffice' && formValues.isHeadOffice === true) {
      setFormValues({ ...formValues, [name]: false })
    } else if (name === 'isHeadOffice' && formValues.isHeadOffice === false) {
      setFormValues({ ...formValues, [name]: true })
    }
  }
  const validate = (values) => {
    const errors = {}
    if (!values.address) {
      errors.address = 'Address is required!'
    } else if (values.address.length > 300) {
      errors.address = 'Address must be less than 300 characters!'
    }
    if (!values.contact1) {
      errors.contact1 = 'Contact number is required!'
    } else if (
      !/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(
        values.contact1,
      )
    ) {
      errors.contact1 = 'Please enter valid contact number!'
    }
    if (
      values.contact2 &&
      !/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(
        values.contact2,
      )
    ) {
      errors.contact2 = 'Please enter valid contact number!'
    }
    if (values.cityId === '') {
      errors.cityId = 'City is required!'
    }
    return errors
  }
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      location.officeLocation
        ? dispatch(updateOfficeLocation(formValues))
        : dispatch(addOfficeLocation(formValues));
    }
  }, [formErrors]);

  const allCities = useSelector((state) => state.cities_reducer.cities)
  const addOfficeLocations = useSelector(
    (state) => state.officeLocations_reducer.addOfficeLocation,
  )
  const updateOfficeLocations = useSelector(
    (state) => state.officeLocations_reducer.updateOfficeLocation,
  )
  const permissions = JSON.parse(localStorage.getItem('permissionsList'));
  const handleGoBack = () => {
    history.goBack();
  };
  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '75vh' }}>
        <CircularProgress size={60} style={{ color: '#002D72' }} />
      </div>
    );
  }
  const addSubmitHandle =(e) =>{
    e.preventDefault();
    const errors = validate(formValues);
      setFormErrors(errors);
      if (Object.keys(errors).length === 0) {
        if (location.officeLocation) {
           dispatch(updateOfficeLocation(formValues));
           
          } else {
            dispatch(addOfficeLocation(formValues));
          }
        }
      }
      
  return (
    <>
      {addOfficeLocations && addOfficeLocations != 'isLoading' && (
        <Redirect to="/admin/officeLocations" />
      )}
      {updateOfficeLocations && updateOfficeLocations != "isLoading" && <Redirect to="/admin/officeLocations" />}
      {!location.officeLocation && <Redirect to="/admin/AddOfficeLocations" />}
      {!permissions.includes('CanAddOfficeLocation') && (
        <Redirect to="/admin" />
      )}
      {!permissions.includes('CanUpdateOfficeLocation') && (
        <Redirect to="/admin" />
      )}

      <div>
        <div className="row">
          <div className="col-md-6">
            <h2 className="d-inine-block float-left">
              <i
                onClick={history.goBack}
                className="fas fa-arrow-left"
                aria-hidden="true"
                style={{ cursor: 'pointer' }}
              />{' '}
              {location.officeLocation
                ? 'Update Office Location'
                : 'Add Office Location'}
            </h2>

          </div>
         <HrComponent />
          <div className="col-md-6"></div>
        </div>
        <div className="row">
          <div className="col-md-9">
            <TextField
              style={{ width: "100%", fontSize: "46px" }}
              className="first_textfield"
              id="outlined-controlled"
              label="Address *"
              name="address"
              value={formValues.address}
              onChange={handleChange}
            />
            <div style={{ color: 'red' }}>{formErrors.address}</div>
          </div>
          <div className="col-md-3">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">City *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formValues.cityId}
                onChange={handleChange}
                label="City *"
                name='cityId'
                MenuProps={{ PaperProps: { style: { maxHeight: 210 } } }}
              >
                {allCities &&
                  allCities !== "isLoading" &&
                  allCities.length !== 0 &&
                  getOptions(allCities[0].cities, 'cityId')
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
              </Select>
              <div style={{ color: 'red' }}>{formErrors.cityId}</div>
            </FormControl>
          </div>
          <div className="col-md-12 mt-5">
            <TextField
              style={{ width: "100%", fontSize: "46px" }}
              className="first_textfield"
              id="outlined-controlled"
              label="Contact 1 *"
              name="contact1"
              value={formValues.contact1}
              onChange={handleChange}
            />
            <div style={{ color: 'red' }}>{formErrors.contact1}</div>
          </div>
          

          <div className="col-md-12 mt-5">
            <div className="form-group">
              <input
                name="isHeadOffice"
                style={{ cursor: "pointer", height: "16px", width: "16px" }}
                type="checkbox"
                checked={formValues.isHeadOffice}
                className=" mt-1 ml-1"
                onChange={handleChange}
              />

              <label
                style={{ paddingLeft: "10px", }}
                className="form-control-label"
              >
                Is Head Office?
              </label>
            </div>
          </div>
          <div className="col-lg-12" style={{ display: "flex", justifyContent: "flex-end", gap: "20px" }}>
            <CancelButton
              onClick={handleGoBack}
            />
            <SaveButton
              onClick={addSubmitHandle}
              isLoading={addOfficeLocations === "isLoading" || updateOfficeLocations === "isLoading"}
              text={location.officeLocation ? "Update" : "Save"}
            />

          </div>
        </div>
      </div>
    </>
  )
}

export default AddOfficeLocations
