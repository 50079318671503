import {
  PERMISSION_SUCCESS,
  PERMISSION_FAIL,
  SET_MESSAGE,
  PERMISSION_PENDING,
  ALL_PERMISSION_SUCCESS,
  ALL_PERMISSION_FAIL,
  ALL_PERMISSION_PENDING,
  PERMISSION_GROUP_SUCCESS,
  PERMISSION_GROUP_FAIL,
  PERMISSION_GROUP_PENDING,
  ADD_PERMISSION_SUCCESS,
  ADD_PERMISSION_FAIL,
  ADD_PERMISSION_PENDING,
  UPDATE_PERMISSION_SUCCESS,
  UPDATE_PERMISSION_FAIL,
  UPDATE_PERMISSION_PENDING,
} from "../types";
import { toast } from "react-toastify";
import UserService from "../../services/user.service";

export const getPermissions = (employeeId) => async (dispatch) => {
  dispatch({
    type: PERMISSION_PENDING,
    payload: { permissions: "isLoading" },
  });
  return await UserService.getPermissions(employeeId)
    .then(
      (data) => {
        const newArr = data.data.result[0].employeePermissions.map(myFunction);

        function myFunction(pos) {
          return pos.permissionName;
        }
        localStorage.setItem("permissionsList", JSON.stringify(newArr));

        data.data.status
          ? dispatch({
              type: PERMISSION_SUCCESS,
              payload: { permissions: newArr },
            })
          : dispatch({
              type: PERMISSION_FAIL,
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: PERMISSION_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};

//All permissions

export const getAllPermissions = (body) => async (dispatch) => {
  dispatch({
    type: ALL_PERMISSION_PENDING,
    payload: { allPermissions: "isLoading" },
  });
  return await UserService.getAllPermissions(body)
    .then(
      (data) => {
        
        data.data.status
          ? dispatch({
              type: ALL_PERMISSION_SUCCESS,
              payload: { allPermissions: data.data.result[0] },
            })
          : dispatch({
              type: ALL_PERMISSION_FAIL,
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: ALL_PERMISSION_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};
//All groups

export const getAllPermissionsGroup = (pageNumber, pageSize, searchQuery) => async (dispatch) => {
  dispatch({
    type: PERMISSION_GROUP_PENDING,
    payload: { allPermissionsGroup: "isLoading" },
  });
  return await UserService.getAllPermissionsGroup(pageNumber, pageSize, searchQuery)
    .then(
      (data) => {
        data.data.status
          ? dispatch({
              type: PERMISSION_GROUP_SUCCESS,
              payload: { allPermissionsGroup: data.data },
            })
          : dispatch({
              type: PERMISSION_GROUP_FAIL,
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: PERMISSION_GROUP_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};

// Add permissions
export const addPermissions = (formValues) => async (dispatch) => {
  dispatch({
    type: ADD_PERMISSION_PENDING,
    payload: { addPermission: "isLoading" },
  });
  return await UserService.addPermission(formValues)
    .then(
      (data) => {
        data.data.status
          ? toast.success("Permission added Successfully !", {
              autoClose: 2000,
            })
          : toast.error(data.data.message, { autoClose: 2000 });
        data.data.status
          ? dispatch({
              type: ADD_PERMISSION_SUCCESS,
              payload: { addPermission: data.data.status },
            })
          : dispatch({
              type: ADD_PERMISSION_FAIL,
              payload: { addPermission: data.data.status },
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: ADD_PERMISSION_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    }).finally(()=>{
      dispatch({
        type: ADD_PERMISSION_FAIL
      })
    });
};

// Update permissions
export const updatePermissions = (formValues) => async (dispatch) => {
  dispatch({
    type: UPDATE_PERMISSION_PENDING,
    payload: { updatePermission: "isLoading" },
  });
  return await UserService.updatePermission(formValues)
    .then(
      (data) => {
        data.data.status
          ? toast.success("Permission updated Successfully !", {
              autoClose: 2000,
            })
          : toast.error(data.data.message, { autoClose: 2000 });
        data.data.status
          ? dispatch({
              type: UPDATE_PERMISSION_SUCCESS,
              payload: { updatePermission: data.data.status },
            })
          : dispatch({
              type: UPDATE_PERMISSION_FAIL,
              payload: { updatePermission: data.data.status },
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: UPDATE_PERMISSION_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    }).finally(()=>{
      dispatch({
        type: UPDATE_PERMISSION_FAIL
      })
    });
};
