import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, useLocation, useHistory } from 'react-router-dom'
import TextField from "@mui/material/TextField";
import './CustomerRank.css'
import CancelButton from '../../subComponents/buttons/CancelButton'
import SaveButton from '../../subComponents/buttons/SaveButton'
import { addCustomerRank, updateCustomerRank } from '../../../actions/userActions/customerRankAction'
import HrComponent from '../../reuse_components/HrComponent';



const AddCustomerRank = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const initialValues = {
    rankName: '',
    rankDescription: '',
  }

  const [formValues, setFormValues] = useState(initialValues)
  const [formErrors, setFormErrors] = useState({})

  useEffect(() => {
    if (location.customerRank) {
      setFormValues({
        ...formValues,
        rankName: location.customerRank.row.rankName,
        rankDescription: location.customerRank.row.rankDescription,
        customerRankId: location.customerRank.row.customerRankId,
      });
    }
  }, [location]);

  const addCustomerRankHandle = (e) => {
    e.preventDefault();
    const errors = validate(formValues);
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      if (location.customerRank) {
        dispatch(updateCustomerRank(formValues));
      } else {
        dispatch(addCustomerRank(formValues));
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  
  const validate = (values) => {
    const errors = {}
    if (!values.rankName) {
      errors.rankName = 'Name is required!'
    }
    if (!values.rankDescription) {
      errors.rankDescription = 'Description is required!'
    }
    return errors
  }
    const addCustomerRanks = useSelector(
      (state) => state.customer_rank_reducer.addCustomerRank,
    )
    const updateCustomerRanks = useSelector(
      (state) => state.customer_rank_reducer.updateCustomerRank,
    )
  const permissions = JSON.parse(localStorage.getItem('permissionsList'));
  const handleGoBack = () => {
    history.goBack();
  };


  return (
    <>
      {addCustomerRanks && addCustomerRanks !== 'isLoading' &&  <Redirect to="/admin/customerRank" />}
      {!location.customerRank && <Redirect to="/admin/AddCustomerRank" />}
     {(!permissions.includes('CanAddCustomerRank') && !permissions.includes('CanUpdateCustomerRank'))  && <Redirect to="/admin" />}
      
      <div>
        <div className="row">
          <div className="col-md-6">
            <h2 className="d-inine-block float-left">
              <i
                onClick={history.goBack}
                className="fas fa-arrow-left"
                aria-hidden="true"
                style={{ cursor: "pointer" }}
              />{" "}
              {location.customerRank ? "Update Customer Rank" : "Add Customer Rank"}
            </h2>
          </div>
         <HrComponent />
          <div className="col-md-6"></div>
        </div>
        <div className="row">
          <div className='col-12 mt-5'>
            <TextField
              style={{ width: "100%", fontSize: "46px" }}
              className="first_textfield"
              id="outlined-controlled"
              label="Name *"
              name="rankName"
              value={formValues.rankName}
              onChange={handleChange}
            />
            <div style={{ color: "red" }}>{formErrors.rankName}</div>
          </div>
          
          <div className='col mt-5'>
            <TextField
              style={{ width: "100%" }}
              multiline
              rows={3}
              value={formValues.rankDescription}
              name="rankDescription"
              onChange={handleChange}
              label="Description *"
            />
            <div style={{ color: "red" }}>{formErrors.rankDescription}</div>
          </div>

       
          <div className="col-lg-12" style={{ display: "flex", justifyContent: "flex-end", gap: "20px", marginTop:"25px" }}>
            <CancelButton
              onClick={handleGoBack}
            />
            <SaveButton
              onClick={addCustomerRankHandle}
              isLoading={addCustomerRanks === "isLoading" || updateCustomerRanks === "isLoading"}
              text={location.customerRank ? "Update" : "Save"}
            />
          </div>
        </div>
      </div >
    </>
  )
}

export default AddCustomerRank