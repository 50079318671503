import RCPagination from 'rc-pagination'
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai'
import 'rc-pagination/assets/index.css'

const Pagination = (props) => {
  return (
    <RCPagination
      nextIcon={<AiOutlineArrowRight />}
      prevIcon={<AiOutlineArrowLeft />}
      {...props}
    />
  )
}

export default Pagination
