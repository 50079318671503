import {
    DOCTOR_SPECIALIZATION_SUCCESS,
    DOCTOR_SPECIALIZATION_PENDING,
    DOCTOR_SPECIALIZATION_FAIL,
    ADD_DOCTOR_SPECIALIZATION_SUCCESS,
    ADD_DOCTOR_SPECIALIZATION_PENDING,
    ADD_DOCTOR_SPECIALIZATION_FAIL,
    UPDATE_DOCTOR_SPECIALIZATION_SUCCESS,
    UPDATE_DOCTOR_SPECIALIZATION_PENDING,
    UPDATE_DOCTOR_SPECIALIZATION_FAIL,
} from "../../actions/types";

const initialState = { 
    specializations: [],
    addSpecializations :'',
    updateSpecializations : ''
    };

const doctor_specialization_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
            case DOCTOR_SPECIALIZATION_SUCCESS:
            return {
                ...state,
                specializations: payload.specializations,
            };
        case DOCTOR_SPECIALIZATION_PENDING:
            return {
                ...state,
                specializations: payload.specializations,
            };
        case DOCTOR_SPECIALIZATION_FAIL:
            return {
                ...state,
                specializations: []
            };
            case ADD_DOCTOR_SPECIALIZATION_SUCCESS:
                return {
                    ...state,
                    addSpecializations: payload.addSpecializations,
                };
            case ADD_DOCTOR_SPECIALIZATION_PENDING:
                return {
                    ...state,
                    addSpecializations: payload.addSpecializations,
                };
            case ADD_DOCTOR_SPECIALIZATION_FAIL:
                return {
                    ...state,
                    addSpecializations: false
                };
            case UPDATE_DOCTOR_SPECIALIZATION_SUCCESS:
                    return {
                        ...state,
                        updateSpecializations: payload.updateSpecializations,
                    };
            case UPDATE_DOCTOR_SPECIALIZATION_PENDING:
                    return {
                        ...state,
                        updateSpecializations: payload.updateSpecializations,
                    };
            case UPDATE_DOCTOR_SPECIALIZATION_FAIL:
                    return {
                        ...state,
                        updateSpecializations: false
                    };
        default:
            return state;
    }
}
export default doctor_specialization_reducer