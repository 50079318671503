import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { MODAL_STYLE } from '../../Constatns';
import { addUnitType, updateUnitType, nullUpdateUnitType } from '../../actions/userActions/unitTypeAction';
import CrossIcon from '../../assets/images/cross_icon.svg'
import CircularProgress from '@mui/material/CircularProgress';
import {
    Box,
    FormControlLabel,
    Checkbox,
    Typography,
    Modal,
    Button,
    TextField,
} from '@mui/material';

function AUUnitTypeModal(props) {

    const dispatch = useDispatch();
    const initialValues = {
        name: "",
        description: "",
        active: true
    };

    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const resetValues = () => {
        setFormErrors({});
        setFormValues(initialValues);
        setIsSubmit(false);
    }
    useEffect(() => {
        {
          props.type=="update" && props.unitType && setFormValues({
                ...formValues,
                ["name"]: props.unitType.name,
                ["description"]: props.unitType.description,
                ["active"]: props.unitType.active,
                ["id"]: props.unitType.id
            });
        }
    }, [])

    const hanleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    }


    const validate = (values) => {
        const errors = {};
        if (!values.name) {
            errors.name = "Unit type name is required!"
        }
        if (!values.description) {
            errors.description = "Unit type description is required!"
        }

        if (Object.keys(errors).length === 0) {
            setIsSubmit(true);
        } else {
            setIsSubmit(false);
        }
        return errors;
    }

    const addUnitTypes = useSelector(state => state.unit_types_reducer.addUnitTypes);
    const updateUnitTypes = useSelector(state => state.unit_types_reducer.updateUnitType);

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            if(props.type == 'add'){
                dispatch(addUnitType(formValues));
            }
            if(props.type == 'update'){
                dispatch(updateUnitType(formValues));
            }            
        }
    }, [formErrors])
    useEffect(() => {
        if(addUnitTypes && addUnitTypes !== 'isLoading'){
            if(props.type=='add'){
                props.onClose();
                resetValues();
                setIsSubmit(false);
            }
        }
    }, [addUnitTypes])

    useEffect(() => {
        if (updateUnitTypes && updateUnitTypes != "isLoading") {
            if(props.type == 'update'){
                props.onClose();
                setIsSubmit(false);
                dispatch(nullUpdateUnitType())
            }
        }
    }, [updateUnitTypes])

    const submitHandle = async (e) => {
        setFormErrors(validate(formValues));
    }

    return (
        <>
            <Modal
                open={props.open}
                onClose={() => { resetValues(); props.onClose() }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={MODAL_STYLE}>
                    <Typography sx={{ marginBottom: "30px !important", fontWeight: "600" }} className='text-center mb-5' id="modal-modal-title" variant="h5" component="h2">
                        {props.type == "add" && 'Add Unit'} {props.type == "update" && 'Edit Unit'} <img onClick={() => { resetValues(); props.onClose() }} style={{ position: "absolute", top: "35px", right: "30px", height: "30px", cursor: "pointer" }} src={CrossIcon} alt="" />
                    </Typography>
                        <div>
                            <div className="row mt-2">
                                <div className="col-12 pr-2">
                                    <TextField
                                        name="name"
                                        onChange={hanleChange}
                                        value={formValues.name}
                                        size="small"
                                        className='form-control'
                                        id="outlined-basic"
                                        label="Name"
                                        variant="outlined"
                                        required
                                        error={formErrors.name}
                                        helperText={formErrors.name} />
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-12 pr-2">
                                    <TextField
                                        error={formErrors.description}
                                        helperText={formErrors.description}
                                        name="description"
                                        value={formValues.description}
                                        onChange={hanleChange}
                                        size="small"
                                        multiline
                                        rows={4}
                                        className='form-control'
                                        id="outlined-basic"
                                        label="Description"
                                        variant="outlined"
                                        required
                                        />
                                </div>
                            </div>
                            {props.type == "update" &&
                                <div className="row mt-4">
                                    <div className="col-12">
                                        <FormControlLabel sx={{ fontSize: "14px" }} control={<Checkbox onChange={() => { setFormValues({ ...formValues, ["active"]: !formValues.active }); }} className='pl-3 pr-2' defaultChecked={formValues.active} />} label="Active" />
                                    </div>
                                </div>
                            }

                            <div className="row">
                                <div className="col-12">
                                    <Button sx={{ marginTop: "30px", fontWeight: "600" }} onClick={submitHandle} type='submit' name="submit" className=' btn btn-primary text-white w-100 text-capitalize' disabled={isSubmit && formErrors != {}}>{(addUnitTypes === "isLoading") || updateUnitTypes === "isLoading" ? <CircularProgress sx={{ color: "white" }} /> : props.type == 'add' ? "Add" : "Update"}</Button>
                                </div>
                            </div>
                        </div>
                </Box>
            </Modal>
        </>
    )
}


export default AUUnitTypeModal
