import "./Tabular.css";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Loader from "../Loader";
import { Link, useHistory } from "react-router-dom";
import { getCallAveragTasks } from "../../../actions/userActions/callAverageTasks";
import { getProducts } from "../../../actions/userActions/productAction";
import { getEmployeeSubOrdinates } from "../../../actions/userActions/employeeAction";
import { getCallAveragTable } from "../../../actions/userActions/callAverageTab";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs';
import Autocomplete from '@mui/material/Autocomplete';
import { Tooltip as MaterialTooltip } from "@mui/material";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Button, Menu, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { Tooltip } from "@mui/material";
import ApplyButton from "../buttons/ApplyButton";
import ResetButton from "../buttons/ResetButton";

export function Tabular({onChangeCallTable}) {
    const dispatch = useDispatch();
    const [showDetailsForRow, setShowDetailsForRow] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [customDate, setCustomDate] = useState(false);
    const [formError, setFormError] = useState({});
    const currentDate = moment();
    const [Filters, setFilters] = useState({
        productsIds: [],
        employeeIds: [],
        fromDate: moment(currentDate).subtract(7, "days").format("YYYY-MM-DD"),
        toDate: moment(currentDate).format("YYYY-MM-DD"),
    });
    const [selected, setSelected] = useState({
        productsIds: [],
        employeeIds: [],
        fromDate: moment(currentDate).subtract(7, "days").format("YYYY-MM-DD"),
        toDate: moment(currentDate).format("YYYY-MM-DD"),
    });
    const user = JSON.parse(localStorage.getItem("user"));
    const employeeId =
        user.result[0] !== undefined ? user.result[0].user.id : false
    const products = useSelector((state) => state.products_reducer.products);
    const employees = useSelector((state) => state.employees_reducer.employeeSubordinates);

    const callAvgTable = useSelector(
        (state) => state.call_avg_tab_reducer.callAvgTab
    );
    const handleDetails = (taskIDs, i) => {
        const body = {
            taskIDs: taskIDs
        };

        setShowDetailsForRow((prevState) => ({
            ...prevState,
            [i]: !prevState[i],
        }));

        dispatch(getCallAveragTasks(body));
    };
    const callAvgTasks = useSelector(
        (state) => state.call_avg_tasks_reducer.callAvgTasks
    );
    //
    const productBody = {
        filter: {
            textSearch: "",
        },
        pageNumber: -1,
        pageSize: 0,
    };
    useEffect(() => {
        if (open) {
            dispatch(getProducts(productBody))
                .then(() => {
                    dispatch(getEmployeeSubOrdinates(employeeId));
                })
                .catch(error => {
                    console.error("Error fetching data:", error);
                });

        }

    }, [open]);
    const handleApply = () => {
        if (validateForm()) {
            const body = {
                tableFilters: {
                    productsIds: Filters.productsIds?Filters.productsIds:[0],
                    employeeIds: Filters.employeeIds?Filters.employeeIds:[0],
                },
                startTime: Filters.fromDate?Filters.fromDate:new Date().toISOString(),
        endTime: Filters.toDate?Filters.toDate: new Date().toISOString(),
            };
            onChangeCallTable(body)
            dispatch(getCallAveragTable(body));
            //   choosefilters(Filters.employeeIds);
            handleClose();
        }
    };

    const handleReset = () => {
        setSelected({
            employeeIds: [],
            productsIds: [],
            fromDate: moment(currentDate).subtract(7, "days").format("YYYY-MM-DD"),
            toDate: moment(currentDate).format("YYYY-MM-DD"),
        });
        setFilters({
            employeeIds: [],
            productsIds: [],
            fromDate: moment(currentDate).subtract(7, "days").format("YYYY-MM-DD"),
            toDate: moment(currentDate).format("YYYY-MM-DD"),
        });
        setSelectedButton("last 7 days");
    }
    //
    const validateForm = () => {
        let errors = {};
        let isValid = true;
        if (!Filters.fromDate) {
            errors.fromDate = "From date is required.";
            isValid = false;
        }
        if (!Filters.toDate) {
            errors.toDate = "To date is required.";
            isValid = false;
        }

        setFormError(errors);
        return isValid;
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const [selectedButton, setSelectedButton] = useState("last 7 days");

    const handleToggleSelection = (event, newSelection) => {
        if (newSelection === "last 7 days") {
            setCustomDate(false);
            const fromDate = moment().subtract(6, "days").startOf("day").toDate(); 
            const toDate = moment().endOf("day").toDate(); 
            setSelected(prevValues => ({ ...prevValues, fromDate: fromDate, toDate: toDate }));
            setFilters(prevValues => ({ ...prevValues, fromDate: fromDate, toDate: toDate }));
        } else if (newSelection === "today") {
            setCustomDate(false);
            const fromDate = moment().startOf("day").toDate();
            const toDate = moment().endOf("day").toDate();
            setSelected(prevValues => ({ ...prevValues, fromDate: fromDate, toDate: toDate }));
            setFilters(prevValues => ({ ...prevValues, fromDate: fromDate, toDate: toDate }));
        } else if (newSelection === "yesterday") {
            setCustomDate(false);
            const fromDate = moment().subtract(1, "days").startOf("day").toDate();  
            const toDate = moment().subtract(1, "days").endOf("day").toDate();      
            setSelected(prevValues => ({ ...prevValues, fromDate: fromDate, toDate: toDate }));
            setFilters(prevValues => ({ ...prevValues, fromDate: fromDate, toDate: toDate }));
        }
        else if (newSelection === "last 30 days") {
            setCustomDate(false);
            const fromDate = moment().subtract(29, "days").toDate();
            const toDate = moment().endOf("day").toDate();
            setSelected(prevValues => ({ ...prevValues, fromDate: fromDate, toDate: toDate }));
            setFilters(prevValues => ({ ...prevValues, fromDate: fromDate, toDate: toDate }));
        } else if (newSelection === "this month") {
            setCustomDate(false);
            const fromDate = moment().startOf("month").toDate();
            const toDate = moment().endOf("month").toDate();
            setSelected(prevValues => ({ ...prevValues, fromDate: fromDate, toDate: toDate }));
            setFilters(prevValues => ({ ...prevValues, fromDate: fromDate, toDate: toDate }));
        } else if (newSelection === "last month") {
            setCustomDate(false);
            const fromDate = moment().subtract(1, "month").startOf("month").toDate();
            const toDate = moment().subtract(1, "month").endOf("month").toDate();
            setSelected(prevValues => ({ ...prevValues, fromDate: fromDate, toDate: toDate }));
            setFilters(prevValues => ({ ...prevValues, fromDate: fromDate, toDate: toDate }));
        } else if (newSelection === "last year") {
            setCustomDate(false);
            const fromDate = moment().subtract(1, "year").startOf("year").toDate();
            const toDate = moment().subtract(1, "year").endOf("year").toDate();
            setSelected(prevValues => ({ ...prevValues, fromDate: fromDate, toDate: toDate }));
            setFilters(prevValues => ({ ...prevValues, fromDate: fromDate, toDate: toDate }));
        } else if (newSelection === "custom") {
            setCustomDate(true);
            setSelected(prevValues => ({ ...prevValues, fromDate: "", toDate: "" }));
            setFilters(prevValues => ({ ...prevValues, fromDate: "", toDate: "" }));
        }

        setSelectedButton(newSelection);
    };
    const handleProductsChange = (event, selectedProducts) => {
        const selectedProductsIds = selectedProducts.map(Product => Product.id);
        setSelected(prevValues => ({
            ...prevValues,
            productsIds: selectedProducts,
        }));
        setFilters(prevValues => ({
            ...prevValues,
            productsIds: selectedProductsIds,
        }));
    };

    const handleEmployeesChange = (event, selectedEmployees) => {
        const selectedEmployeeIds = selectedEmployees.map(Employee => Employee.id);
        setSelected(prevValues => ({
            ...prevValues,
            employeeIds: selectedEmployees,
        }));
        setFilters(prevValues => ({
            ...prevValues,
            employeeIds: selectedEmployeeIds,
        }));
    };
    const handleFromDateChange = (date) => {
        const formattedDate = dayjs(date).startOf('day').toDate();
        setSelected(prevValues => ({
            ...prevValues,
            fromDate: formattedDate,
        }));
        setFilters(prevValues => ({
            ...prevValues,
            fromDate: formattedDate,
        }));
    };
    const handleToDateChange = (date) => {
        const formattedDate = dayjs(date).endOf('day').toDate(); 
        setSelected(prevValues => ({
            ...prevValues,
            toDate: formattedDate,
        }));
        setFilters(prevValues => ({
            ...prevValues,
            toDate: formattedDate,
        }));
    };
    return (
        <>
            <div className="main-container" style={{ marginTop: "16px" }}>
                <div>
                    <div className="row">
                        <div className="col-10"><p style={{
                            fontFamily: "Roboto",
                            fontSize: "18px",
                            fontWeight: "700",
                            lineHeight: "23px",
                            textAlign: "left",
                            color: "#000000",
                            height: "23px"
                        }}>Call average table</p>
                        </div>
                        <div className="col-2 d-flex justify-content-end">
                            <MaterialTooltip title="Apply Filter" arrow>
                                <Button
                                    id="basic-button"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                    startIcon={<FilterAltOutlinedIcon />}
                                    sx={{
                                        color: "#505157",
                                        borderColor: "#505157",
                                        backgroundColor: "#FFFFFF",
                                        border: "1px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        '& .MuiButton-startIcon': {
                                            margin: "0",
                                        },
                                        '&:hover': {
                                            color: "#2F54EB",
                                            borderColor: "#2F54EB",
                                            '& .MuiButton-startIcon': {
                                                color: "#2F54EB",
                                            }
                                        }
                                    }}
                                >
                                </Button>
                            </MaterialTooltip>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                PaperProps={{
                                    style: {
                                        width: "989px",
                                        top: "-47614px",
                                        left: "-4486px",
                                        borderRadius: "4px",
                                        background: "#FFFFFF",
                                        boxShadow: "0px 11px 15px -7px #00000033",
                                    },
                                }}
                            >
                                <div className="row" style={{
                                    padding: "16px 24px 16px 24px",
                                    justify: "space-between",
                                    display: "flex",
                                    gap: "24px"
                                }}>
                                    <div className="col-12 d-flex ml-2">
                                        <h6 style={{
                                            fontFamily: "Roboto",
                                            fontSize: "20px",
                                            fontWeight: "500",
                                            lineHeight: "32px",
                                            textAlign: "left",
                                            color: "#000000DE"
                                        }}>
                                            Filter Results
                                        </h6>
                                        <MaterialTooltip title="Close Filter" arrow>
                                            <CloseIcon style={{ marginLeft: "auto", cursor: "pointer" }} onClick={handleClose} />
                                        </MaterialTooltip>
                                    </div>
                                </div>
                                <div className="row" style={{
                                    gap: "24px",
                                    borderBottom: "1px solid #0000001F",
                                    padding: "24px 16px 24px 16px",
                                    alignItems: "center"
                                }}>
                                    <div className="row" style={{ gap: "16px" }}>
                                        <div className="col-12 d-flex justify-content-center align-items-center" style={{ height: "48px" }}>
                                            <ToggleButtonGroup
                                                exclusive
                                                value={selectedButton}
                                                onChange={handleToggleSelection}
                                                aria-label="button group"
                                            >
                                                <ToggleButton
                                                    value="today"
                                                    style={{
                                                        width: "114px",
                                                        height: "32px",
                                                        padding: "4px 8px 4px 8px",
                                                        gap: "8px",
                                                        borderRadius: "4px",
                                                        textTransform: "none",
                                                        backgroundColor: selectedButton === "today" ? "#2F54EB" : "inherit",
                                                        color: selectedButton === "today" ? "#fff" : "inherit"
                                                    }}
                                                >
                                                    Today
                                                </ToggleButton>
                                                <ToggleButton
                                                    value="yesterday"
                                                    style={{
                                                        width: "114px",
                                                        height: "32px",
                                                        padding: "4px 8px 4px 8px",
                                                        gap: "8px",
                                                        borderRadius: "4px",
                                                        textTransform: "none",
                                                        backgroundColor: selectedButton === "yesterday" ? "#2F54EB" : "inherit",
                                                        color: selectedButton === "yesterday" ? "#fff" : "inherit"
                                                    }}
                                                >
                                                    Yesterday
                                                </ToggleButton>
                                                <ToggleButton
                                                    value="last 7 days"
                                                    style={{
                                                        width: "114px",
                                                        height: "32px",
                                                        padding: "4px 8px 4px 8px",
                                                        gap: "8px",
                                                        borderRadius: "4px",
                                                        textTransform: "none",
                                                        backgroundColor: selectedButton === "last 7 days" ? "#2F54EB" : "inherit",
                                                        color: selectedButton === "last 7 days" ? "#fff" : "inherit"
                                                    }}
                                                >
                                                    Last 7 days
                                                </ToggleButton>
                                                <ToggleButton
                                                    value="last 30 days"
                                                    style={{
                                                        width: "114px",
                                                        height: "32px",
                                                        padding: "4px 8px 4px 8px",
                                                        gap: "8px",
                                                        borderRadius: "4px",
                                                        textTransform: "none",
                                                        backgroundColor: selectedButton === "last 30 days" ? "#2F54EB" : "inherit",
                                                        color: selectedButton === "last 30 days" ? "#fff" : "inherit"
                                                    }}
                                                >
                                                    Last 30 days
                                                </ToggleButton>
                                                <ToggleButton
                                                    value="this month"
                                                    style={{
                                                        width: "114px",
                                                        height: "32px",
                                                        padding: "4px 8px 4px 8px",
                                                        gap: "8px",
                                                        borderRadius: "4px",
                                                        textTransform: "none",
                                                        backgroundColor: selectedButton === "this month" ? "#2F54EB" : "inherit",
                                                        color: selectedButton === "this month" ? "#fff" : "inherit"
                                                    }}
                                                >
                                                    This Month
                                                </ToggleButton>
                                                <ToggleButton
                                                    value="last month"
                                                    style={{
                                                        width: "114px",
                                                        height: "32px",
                                                        padding: "4px 8px 4px 8px",
                                                        gap: "8px",
                                                        borderRadius: "4px",
                                                        textTransform: "none",
                                                        backgroundColor: selectedButton === "last month" ? "#2F54EB" : "inherit",
                                                        color: selectedButton === "last month" ? "#fff" : "inherit"
                                                    }}
                                                >
                                                    Last Month
                                                </ToggleButton>
                                                <ToggleButton
                                                    value="last year"
                                                    style={{
                                                        width: "114px",
                                                        height: "32px",
                                                        padding: "4px 8px 4px 8px",
                                                        gap: "8px",
                                                        borderRadius: "4px",
                                                        textTransform: "none",
                                                        backgroundColor: selectedButton === "last year" ? "#2F54EB" : "inherit",
                                                        color: selectedButton === "last year" ? "#fff" : "inherit"
                                                    }}
                                                >
                                                    Last Year
                                                </ToggleButton>
                                                <ToggleButton
                                                    value="custom"
                                                    style={{
                                                        width: "114px",
                                                        height: "32px",
                                                        padding: "4px 8px 4px 8px",
                                                        gap: "8px",
                                                        borderRadius: "4px",
                                                        textTransform: "none",
                                                        backgroundColor: selectedButton === "custom" ? "#2F54EB" : "inherit",
                                                        color: selectedButton === "custom" ? "#fff" : "inherit"
                                                    }}
                                                >
                                                    Custom
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                        </div>
                                        <div className="col-12 d-flex" style={{
                                            height: "56px",
                                        }}>
                                            <div className="col-6">
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        label="From"
                                                        valueType="date"
                                                        value={selected.fromDate && selected.fromDate.length != 0 ? dayjs(selected.fromDate) : null}
                                                        onChange={handleFromDateChange}
                                                        disabled={!customDate}
                                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                                        sx={{ width: "100%" }}
                                                    />
                                                </LocalizationProvider>
                                                {formError.fromDate && (
                                                    <span style={{ color: "red" }}>{formError.fromDate}</span>
                                                )}
                                            </div>
                                            <div className="col-6">
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        label="To"
                                                        valueType="date"
                                                        value={selected.toDate && selected.toDate.length != 0 ? dayjs(selected.toDate) : null}
                                                        onChange={handleToDateChange}
                                                        disabled={!customDate}
                                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                                        sx={{ width: "100%" }}
                                                    />
                                                </LocalizationProvider>
                                                {formError.toDate && (
                                                    <span style={{ color: "red" }}>{formError.toDate}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" style={{ gap: "16px" }}>
                                        <div className="col-12 d-flex" >
                                            <div className="col-6">
                                                <Autocomplete
                                                    multiple
                                                    limitTags={2}
                                                    id="multiple-limit-tags-employee"
                                                    onChange={handleProductsChange}
                                                    value={selected.productsIds}
                                                    menuprops={{ PaperProps: { style: { maxHeight: 200, width: 250 } } }}
                                                    options={products === "isLoading"
                                                        ? [{ name: "Loading...", id: -1 }]
                                                        : products.length === 0
                                                            ? [{ name: "No data", id: -1 }]
                                                            : products[0].products
                                                                .filter(product => product.active)
                                                                .sort((a, b) => a.name.localeCompare(b.name))
                                                    }
                                                    getOptionLabel={(option) => option.name}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Product" />
                                                    )}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <Autocomplete
                                                    multiple
                                                    limitTags={2}
                                                    id="multiple-limit-tags-employee"
                                                    onChange={handleEmployeesChange}
                                                    value={selected.employeeIds}
                                                    menuprops={{ PaperProps: { style: { maxHeight: 200, width: 250 } } }}
                                                    options={employees === "isLoading"
                                                        ? [{ name: "Loading...", id: -1 }]
                                                        : employees.length === 0
                                                            ? [{ name: "No data", id: -1 }]
                                                            : employees
                                                                .sort((a, b) => {
                                                                    const firstNameComparison = a.firstName.localeCompare(b.firstName);
                                                                    if (firstNameComparison !== 0) {
                                                                        return firstNameComparison;
                                                                    }
                                                                    return a.lastName.localeCompare(b.lastName);
                                                                })
                                                    }
                                                    getOptionKey={(option)=>option.id}
                                                    getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Employee" />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mr-4" style={{
                                    padding: "16px 24px 16px 24px",
                                    gap: "8px",
                                    display: "flex",
                                    justifyContent: "flex-end"
                                }}>
                                    <ApplyButton onClick={handleApply} />
                                    <ResetButton onClick={handleReset} />
                                </div>
                            </Menu>
                        </div>
                    </div>
                </div>

                <div className="table-container mt-5  mb-5" >
                    <table className="table table-striped" style={{ gap: "16px" }}>
                        <thead className="text-left">
                            <tr className="main-header-table"
                            >
                                <td className="main-header-table-td"
                                >
                                    {" "}
                                    <b>Employee</b>{" "}
                                </td>
                                <td className="main-header-table-td" >
                                    {" "}
                                    <b>Product</b>{" "}
                                </td>
                                <td className="main-header-table-td">
                                    {" "}
                                    <b>Calls</b>{" "}
                                </td>
                                <td className="main-header-table-td">
                                    {" "}
                                    <b>Date</b>{" "}
                                </td>
                                <td className="main-header-table-td">
                                    {" "}
                                    <b>View Details</b>{" "}
                                </td>
                            </tr>
                        </thead>
                        <tbody className="main-table-row" style={{ gap: "16px" }}>
                            {
                                (callAvgTable && callAvgTable.length === 0) ||
                                    (callAvgTable && callAvgTable.byTable && callAvgTable.byTable.length === 0) ? (
                                    <tr className="text-center" style={{
                                        backgroundColor: "transparent",
                                        "--x-table-accent-bg": "transparent",
                                    }}>
                                        <td colSpan={5}>No data found</td>
                                    </tr>
                                ) : null
                            }
                            {callAvgTable === "isLoading" && (
                                <tr style={{
                                    backgroundColor: "transparent",
                                    "--x-table-accent-bg": "transparent",
                                }}>
                                    <td
                                        colSpan={5}
                                    >
                                        <Loader colored={true} />
                                    </td>
                                </tr>
                            )}
                            {callAvgTable && callAvgTable !== "isLoading" &&
                                callAvgTable.byTable &&
                                callAvgTable.byTable.map((row, i) => (
                                    <React.Fragment key={i}>

                                        <tr className="main-table-row mb-2" style={{
                                            backgroundColor: "transparent",
                                            "--x-table-accent-bg": "transparent",
                                        }}>
                                            <td className="main-table-employee">
                                                <div className="row align-items-center" style={{
                                                    width: "186px",
                                                    height: "40px"

                                                }}>
                                                    <div className="col d-flex align-items-center justify-content-center" style={{ height: "40px", width: "40px" }}>
                                                        {/* <img src={SidebarIcons.person_icon} className="img-fluid" alt="Employee" style={{ height: "40px", width: "40px", borderRadius: "100%" }} />
                                                         */}
                                                        <div className="header-avatar-wrapper">
                                                            <div style={{ height: "40px", width: "40px" }}>
                                                                <p>
                                                                    {row.employeeName.split(' ').map(word => word.charAt(0)).join(' ')}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <p className="main-header-table-td-title">{row.employeeName}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="main-table-td">
                                                <p className="main-header-table-td-title mt-3">{row.productName}</p></td>
                                            <td className="main-table-td">
                                                <p className="main-header-table-td-title mt-3">{row.calls}</p></td>
                                            <td className="main-table-td"><p className="main-header-table-td-title mt-3">{moment(row.dateTime).format("MMM DD, YYYY")}</p></td>
                                            <td className="main-table-td">
                                                <Tooltip title="View detail" arrow>
                                                    <button
                                                        style={{ background: "none", marginTop: "10px" }}
                                                        onClick={() => {
                                                            handleDetails(row.taskIDs, i);
                                                        }}
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="black"
                                                            fillOpacity="0.56"
                                                        >
                                                            <path d="M16.59 8.29688L12 12.8769L7.41 8.29688L6 9.70687L12 15.7069L18 9.70687L16.59 8.29688Z" />
                                                        </svg>
                                                    </button>
                                                </Tooltip>
                                            </td>
                                        </tr>
                                        {
                                            showDetailsForRow[i] && (
                                                <>
                                                    <tr className="expansion-table-header">
                                                        <td className="expansion-table-header-td">
                                                            {" "}
                                                            <b>Title</b>{" "}
                                                        </td>
                                                        <td className="expansion-table-header-td">
                                                            {" "}
                                                            <b>Assigned to</b>{" "}
                                                        </td>
                                                        <td className="expansion-table-header-td">
                                                            {" "}
                                                            <b>Status</b>{" "}
                                                        </td>
                                                        <td className="expansion-table-header-td">
                                                            {" "}
                                                            <b>Priority</b>{" "}
                                                        </td>
                                                        <td className="expansion-table-header-td">
                                                            {" "}
                                                            <b>Action</b>{" "}
                                                        </td>
                                                    </tr>
                                                    {callAvgTasks === "isLoading" && (
                                                        <tr>
                                                            <td
                                                                colSpan={6}
                                                                style={{ backgroundColor: "#E6F4FF" }}
                                                            >
                                                                <Loader colored={true} />
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {callAvgTasks && callAvgTasks !== "isLoading" &&
                                                        callAvgTasks.tasks &&
                                                        callAvgTasks.tasks.map((row, i) => (
                                                            <tr style={{
                                                                height: "56px",
                                                                borderBottom: "0.5px solid #3A3A3A7A",
                                                                border: "0px 0px 1px 0px",
                                                                background: "#E6F4FF"

                                                            }}>
                                                                <td className="expansion-table-row-td"> <p className="expansion-table-row-td-title">{row.doctorName}</p>
                                                                    <span style={{ color: "grey" }}>{row.practiceLocationName}</span>

                                                                </td>
                                                                <td className="expansion-table-row-td">
                                                                    <p className="expansion-table-row-td-title">{row.assigneeName}</p></td>
                                                                <td className="expansion-table-row-td-status">
                                                                    <span className="task-status" style={{
                                                                        height: "24px",
                                                                        padding: "6px 8px 6px 8px",
                                                                        borderRadius: "4px",
                                                                        fontSize: "14px"
                                                                    }}>
                                                                        {row.taskStatusName}
                                                                    </span>
                                                                </td>
                                                                <td className="expansion-table-row-td-priority">
                                                                    <span className="task-priority" style={{
                                                                        color: row.taskPriorityColor,
                                                                        height: "24px",
                                                                        padding: "6px 8px 6px 8px",
                                                                        borderRadius: "4px",
                                                                        background: "#FF00001A",
                                                                        fontSize: "14px"
                                                                    }}>{row.taskPriorityName}
                                                                    </span>
                                                                </td>
                                                                {/* <td>{moment(row.taskCreateDate).format("MMM DD, YYYY")}</td> */}
                                                                <td className="expansion-table-row-td-action">
                                                                    <Tooltip title="View Task Detail" arrow>
                                                                        <Link
                                                                            to={{
                                                                                pathname: "/admin/task/view",
                                                                                state: { id: row.taskId },
                                                                            }}
                                                                            className="btn btn-sm icons_adjust"
                                                                        >
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="24"
                                                                                height="24"
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                            >
                                                                                <path
                                                                                    d="M12 6.5C15.79 6.5 19.17 8.63 20.82 12C19.17 15.37 15.8 17.5 12 17.5C8.2 17.5 4.83 15.37 3.18 12C4.83 8.63 8.21 6.5 12 6.5ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C13.38 9.5 14.5 10.62 14.5 12C14.5 13.38 13.38 14.5 12 14.5C10.62 14.5 9.5 13.38 9.5 12C9.5 10.62 10.62 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z"
                                                                                    fill="#505157"
                                                                                />
                                                                            </svg>
                                                                        </Link>
                                                                    </Tooltip>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </>
                                            )
                                        }
                                    </React.Fragment>
                                ))}
                        </tbody >
                    </table >
                </div >
            </div >
        </>
    );
}
