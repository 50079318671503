import React from "react";
import "./IconButton.css";

const IconButton = ({ onClick, icon, alt = "icon", size = 20 }) => {
  return (
    <button className="icon-button" onClick={onClick}>
      <img src={icon} alt={alt} className="button-icon" style={{ width: size, height: size }} />
    </button>
  );
};

export default IconButton;