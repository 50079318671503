import React from 'react'
import { Modal, Box, Typography } from '@mui/material'
import CrossIcon from '../../../assets/images/cross_icon.svg'
import { MODAL_STYLE } from '../../../Constatns'
import moment from 'moment';
import { DATE_FORMAT } from '../../../Constatns';
function ViewModal(props) {
    return (
        <>
            <Modal
                open={props.open}
                onClose={props.onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={MODAL_STYLE} >
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <div className="d-flex flex-row justify-content-between view-modal-header">
                            <h2>{props.title}</h2>
                            <img onClick={props.onClose} style={{ height: "35px" }} src={CrossIcon} alt="" />
                        </div>
                    </Typography>
                    <div className="view-modal-parent-section">
                        {props.headings && props.headings.map((d, i) => (
                            <>
                                {props.data[props.keys[i]] !== '' &&
                                    <div key={i} className='d-flex flex-row view-modal-section mb-5'>
                                        <img style={{ height: "22px" }} src={props.icons[i]} alt="" />
                                        <div className="d-flex flex-column">
                                            <p className='title' style={{ fontSize: "18px" }}>{d}</p>
                                            <p className='desc' style={{ fontSize: "16px", color: "black" }}>{
                                                props.keys[i] == 'active' ?
                                                    props.data[props.keys[i]] ? 'Active' : 'Not active'
                                                    :
                                                    moment(props.data[props.keys[i]], "Y-MM-DDTHH:mm:SS", true).isValid() ? moment(props.data[props.keys[i]]).format(DATE_FORMAT) :
                                                        props.data[props.keys[i]]
                                            }</p>
                                        </div>
                                    </div>
                                }
                            </>


                        ))}
                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default ViewModal