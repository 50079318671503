import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { getProducts } from '../../actions/userActions/productAction'
import Loader from '../subComponents/Loader'
import Pagination from '../paginatioin/pagination'
import AddButton from '../subComponents/buttons/AddButton'
import { Tooltip } from "@mui/material";
import ServerSideSearch from '../subComponents/search/ServerSideSearch'
import EditButton from '../subComponents/buttons/EditButton'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import IconButton from '@mui/material/IconButton';


function Products() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [page, setPage] = useState(1)
  const [isExpanded, setIsExpanded] = useState(false);


  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };
  const [selected, setSelected] = useState({
    textSearch: '',
  })
  const [filters, setFilters] = useState({
    textSearch: '',
  })
  const body = {
    filter: filters,
    pageNumber: page,
    pageSize: 10,
  }
  const products = useSelector((state) => state.products_reducer.products) || [];

  const permissions = JSON.parse(localStorage.getItem('permissionsList'));
  useEffect(() => {
    dispatch(getProducts(body))
  }, [page, filters])
  const getPaginationNo = (pageNum) => {
    const object = []
    if (products !== 'isLoading' && products.length !== 0) {
      for (let i = 0; i < products[0].page.totalPages; i++) {
        object.push(
          <button
            className={pageNum == i + 1 ? 'active' : ''}
            onClick={() => setPage(i + 1)}
          >
            {i + 1}
          </button>,
        )
      }
    }
    return object
  }

  const handleSearch = (e) => {
    setSelected({ ...selected, ['textSearch']: e.target.value })
    if (e.target.value.length >= 1) {
      setTimeout(() => {
        setFilters({ ...filters, ['textSearch']: e.target.value })
      }, 500)
    }
    if (e.target.value == '') {
      setFilters({ ...filters, ['textSearch']: e.target.value })
    }
  }
  const handleAddProductClick = () => {
    history.push('/admin/addProducts');
  };

  return (
    <>
      <div>
        <div className="row">
          <div className="col-6 md-5">
            <h2 className="d-inline-block">Products</h2>
          </div>
          <div className="col-6 md-4 d-flex justify-content-end" style={{ gap: "16px" }}>
            <ServerSideSearch
              placeholder="Search Product"
              onChange={handleSearch}
              value={selected.textSearch}
            />
            {permissions.includes('CanAddProduct') && (
              <AddButton onClick={handleAddProductClick} text="+ Add Product" />
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <table className="table table-striped  mt-4">
              <thead className="text-left" style={{ backgroundColor: "#f5f9fc" }}>
                <tr>
                  <td>
                    {" "}
                    <b className="heading_role">Name</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">Description</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">Status</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">Action</b>{" "}
                  </td>
                </tr>
              </thead>
              <tbody className="text-left" style={{
                backgroundColor: "transparent",
                "--x-table-accent-bg": "transparent",
              }}>
                {products && products?.length == 0 && (
                  <tr className="text-center" style={{
                    backgroundColor: "transparent",
                    "--x-table-accent-bg": "transparent",
                  }}>
                    <td colSpan={4}>No data found</td>
                  </tr>
                )}
                {products === 'isLoading' && (
                  <tr style={{
                    backgroundColor: "transparent",
                    "--x-table-accent-bg": "transparent",
                  }}>
                    <td
                      colSpan={4}
                    >
                      <Loader colored={true} />
                    </td>
                  </tr>
                )}
                {products !== 'isLoading' &&
                  products.length !== 0 &&
                  products[0].products
                    .slice()
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((row, i) => (
                      <tr
                        key={i}
                        style={{
                          backgroundColor: "transparent",
                          "--x-table-accent-bg": "transparent",
                        }}
                      >
                        <td style={{verticalAlign:"middle"}}>{row.name}</td>
                        <td
                        style={{
                          whiteSpace: "pre-line",
                          wordBreak: "break-word",
                          overflowWrap: "break-word",
                          verticalAlign: "middle",
                        }}
                      >
                        <div
                          style={{
                            maxHeight:
                              isExpanded || row?.description?.length <= 100
                                ? "none"
                                : "3.4em",
                            overflow: "hidden",
                            position: "relative",
                            width: "65%",
                          }}
                        >
                          {row?.description}
                          {row?.description?.length > 100 && (
                            <span
                              // className="toggle_expand"
                              style={{position:"absolute", right:"0px", bottom:"0", cursor:"pointer", fontSize:"20px"}}
                              onClick={toggleExpansion}
                            >
                              ...
                            </span>
                          )}
                        </div>
                      </td>
                        <td style={{verticalAlign:"middle"}}>
                          <span
                            style={{
                              color: row.active
                                ? "#2196F3"
                                : "var(--text-primary, rgba(0, 0, 0, 0.87))",
                              borderRadius: "100px",
                              border: `1px solid ${row.active
                                ? "var(--primary-main, #2196F3)"
                                : "var(--chip-defaultEnabledBorder, #BDBDBD)"
                                }`,
                              padding: "3px 10px",
                            }}
                          >
                            {row.active ? "Active" : "In-active"}
                          </span>
                        </td>

                        <td style={{verticalAlign:"middle"}}>
                          {permissions.includes('CanUpdatePermission') && (
                            <Tooltip title="Edit Product" arrow enterDelay={500} placement="bottom">
                              <Link
                                to={{
                                  pathname: '/admin/UpdateProducts',
                                  product: { row },
                                  id: row.id,
                                }}
                              >
                                <IconButton>
                                  <EditOutlinedIcon 
                                  />
                                </IconButton>
                               
                              </Link>
                            </Tooltip>
                          )}
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
        {products !== 'isLoading' && products.length !== 0 && (
          <div className="task-pagination-wrapper">
            <Pagination
              total={
                products[0].page != undefined &&
                products[0].page.totalPages * products[0].page.pageSize
              }
              current={products[0].page != undefined && products[0].page.pageNumber}
              pageSize={products[0].page != undefined && products[0].page.pageSize}
              onChange={(current) => setPage(current)}
              showLessItems
            />
          </div>
        )}
      </div>
    </>
  )
}

export default Products
