import {
  TASK_SUCCESS,
  TASK_FAIL,
  TASK_PENDING,
  TASK_DOCTOR_SUCCESS,
  TASK_DOCTOR_FAIL,
  TASK_DOCTOR_PENDING,
  TASK_DETAIL_SUCCESS,
  TASK_DETAIL_FAIL,
  TASK_DETAIL_PENDING,
  TASK_CITY_SUCCESS,
  TASK_CITY_FAIL,
  TASK_CITY_PENDING,
  TASK_PRACTICE_LOCATION_SUCCESS,
  TASK_PRACTICE_LOCATION_FAIL,
  TASK_PRACTICE_LOCATION_PENDING,
  TASK_PRODUCT_SUCCESS,
  TASK_PRODUCT_FAIL,
  TASK_PRODUCT_PENDING,
  TASK_SUB_ORDINATE_SUCCESS,
  TASK_SUB_ORDINATE_FAIL,
  TASK_SUB_ORDINATE_PENDING,
  TASK_SUB_TASK_SUCCESS,
  TASK_SUB_TASK_FAIL,
  TASK_SUB_TASK_PENDING,
  TASK_PRIORITY_SUCCESS,
  TASK_PRIORITY_FAIL,
  TASK_PRIORITY_PENDING,
  TASK_STATUS_SUCCESS,
  TASK_STATUS_FAIL,
  TASK_STATUS_PENDING,
  TASK_TYPE_SUCCESS,
  TASK_TYPE_FAIL,
  TASK_TYPE_PENDING,
  ADD_TASK_SUCCESS,
  ADD_TASK_FAIL,
  ADD_TASK_PENDING,
  TASK_NOTE_FAIL,
  TASK_NOTE_PENDING,
  TASK_NOTE_SUCCESS,
  ADD_TASK_NOTE_SUCCESS,
  ADD_TASK_NOTE_FAIL,
  ADD_TASK_NOTE_PENDING,
  SUB_ORDINATES_SUCCESS,
  SUB_ORDINATES_PENDING,
  SUB_ORDINATES_FAIL
} from "../../../actions/types";

const initialState = {
  tasks: "isLoading",
  taskDetails: [],
  taskDoctors: [],
  taskCities: [],
  taskPracticeLocations: [],
  taskProducts: [],
  taskSubOrdinates: [],
  taskSubTasks: [],
  taskPriorities: [],
  taskStatuses: [],
  taskTypes: [],
  addTask: false,
  taskNotes: [],
};

const tasks_reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case TASK_SUCCESS:
      return {
        ...state,
        tasks: payload.tasks,
      };
    case TASK_PENDING:
      return {
        ...state,
        tasks: payload.tasks,
      };
    case TASK_FAIL:
      return {
        ...state,
        tasks: [],
      };

    case TASK_DETAIL_SUCCESS:
      return {
        ...state,
        taskDetails: payload.taskDetails,
      };
    case TASK_DETAIL_PENDING:
      return {
        ...state,
        taskDetails: payload.taskDetails,
      };
    case TASK_DETAIL_FAIL:
      return {
        ...state,
        taskDetails: [],
      };

    case TASK_DOCTOR_SUCCESS:
      return {
        ...state,
        taskDoctors: payload.taskDoctors[0].doctors,
      };
    case TASK_DOCTOR_PENDING:
      return {
        ...state,
        taskDoctors: payload.taskDoctors,
      };
    case TASK_DOCTOR_FAIL:
      return {
        ...state,
        taskDoctors: [],
      };

    case TASK_CITY_SUCCESS:
      return {
        ...state,
        taskCities: payload.taskCities,
      };
    case TASK_CITY_PENDING:
      return {
        ...state,
        taskCities: payload.taskCities,
      };
    case TASK_CITY_FAIL:
      return {
        ...state,
        taskCities: [],
      };

    case TASK_PRACTICE_LOCATION_SUCCESS:
      return {
        ...state,
        taskPracticeLocations: payload.taskPracticeLocations,
      };
    case TASK_PRACTICE_LOCATION_PENDING:
      return {
        ...state,
        taskPracticeLocations: payload.taskPracticeLocations,
      };
    case TASK_PRACTICE_LOCATION_FAIL:
      return {
        ...state,
        taskPracticeLocations: [],
      };

    case TASK_PRODUCT_SUCCESS:
      return {
        ...state,
        taskProducts: payload.taskProducts[0].products,
      };
    case TASK_PRODUCT_PENDING:
      return {
        ...state,
        taskProducts: payload.taskProducts,
      };
    case TASK_PRODUCT_FAIL:
      return {
        ...state,
        taskProducts: [],
      };

    case TASK_SUB_ORDINATE_SUCCESS:
      return {
        ...state,
        taskSubOrdinates: payload.taskSubOrdinates,
      };
    case TASK_SUB_ORDINATE_PENDING:
      return {
        ...state,
        taskSubOrdinates: payload.taskSubOrdinates,
      };
    case TASK_SUB_ORDINATE_FAIL:
      return {
        ...state,
        taskSubOrdinates: [],
      };

    case TASK_SUB_TASK_SUCCESS:
      return {
        ...state,
        taskSubTasks: payload.taskSubTasks,
      };
    case TASK_SUB_TASK_PENDING:
      return {
        ...state,
        taskSubTasks: payload.taskSubTasks,
      };
    case TASK_SUB_TASK_FAIL:
      return {
        ...state,
        taskSubTasks: [],
      };

    case TASK_PRIORITY_SUCCESS:
      return {
        ...state,
        taskPriorities: payload.taskPriorities,
      };
    case TASK_PRIORITY_PENDING:
      return {
        ...state,
        taskPriorities: payload.taskPriorities,
      };
    case TASK_PRIORITY_FAIL:
      return {
        ...state,
        taskPriorities: [],
      };

    case TASK_STATUS_SUCCESS:
      return {
        ...state,
        taskStatuses: payload.taskStatuses,
      };
    case TASK_STATUS_PENDING:
      return {
        ...state,
        taskStatuses: payload.taskStatuses,
      };
    case TASK_STATUS_FAIL:
      return {
        ...state,
        taskStatuses: [],
      };

    case TASK_TYPE_SUCCESS:
      return {
        ...state,
        taskTypes: payload.taskTypes,
      };
    case TASK_TYPE_PENDING:
      return {
        ...state,
        taskTypes: payload.taskTypes,
      };
    case TASK_TYPE_FAIL:
      return {
        ...state,
        taskTypes: [],
      };

    case ADD_TASK_SUCCESS:
      return {
        ...state,
        addTask: payload.addTask,
      };
    case ADD_TASK_PENDING:
      return {
        ...state,
        addTask: payload.addTask,
      };
    case ADD_TASK_FAIL:
      return {
        ...state,
        addTask: false,
      };
      //
      case TASK_NOTE_SUCCESS:
        return {
          ...state,
          taskNotes: payload.taskNotes,
        };
      case TASK_NOTE_PENDING:
        return {
          ...state,
          taskNotes: payload.taskNotes,
        };
      case TASK_NOTE_FAIL:
        return {
          ...state,
          taskNotes: false,
        };

        //

        case ADD_TASK_NOTE_SUCCESS:
        return {
          ...state,
          addTasksNotes: payload.addTasksNotes,
        };
      case ADD_TASK_NOTE_PENDING:
        return {
          ...state,
          addTasksNotes: payload.addTasksNotes,
        };
      case ADD_TASK_NOTE_FAIL:
        return {
          ...state,
          addTasksNotes: false,
        };



        //

        case SUB_ORDINATES_SUCCESS:
        return {
          ...state,
          subOrdinates: payload.subOrdinates,
        };
      case SUB_ORDINATES_PENDING:
        return {
          ...state,
          subOrdinates: payload.subOrdinates,
        };
      case SUB_ORDINATES_FAIL:
        return {
          ...state,
          subOrdinates: false,
        };



    default:
      return state;
  }
};
export default tasks_reducer;
