import {
    LOGIN_SUCCESS,
    LOGIN_PENDING,
    LOGIN_FAIL,
    LOGOUT,
    SET_MESSAGE,
  } from "../types";
  import { toast} from 'react-toastify'; 
  import AuthService from "../../services/auth/auth.service";
import { getPermissions } from "../userActions/permissionsAction";

  
  export const login = (email, password) => (dispatch) => {
  
    dispatch({
      type: LOGIN_PENDING,
      payload: { isLogging:true },
    });
    return AuthService.login(email, password).then(
      (data) => {
        if(data.status){
        // Dispatch getPermissions action
        dispatch(getPermissions(data.result[0].user.id));
          toast.success(data.message, {autoClose: 2000 });
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data },
        })}
        else{
          toast.error(data.message, {autoClose: 2000 });
        dispatch({
          type: LOGIN_FAIL,
        })}
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: LOGIN_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
        toast.error(error.message, {autoClose: 2000 });
        return Promise.reject();
      }
    );
  };
  
  export const logout = (employeeId, currentPassword) => (dispatch) => {
    AuthService.logout(employeeId, currentPassword);
    dispatch({
      type: LOGOUT,
    });
  };
  