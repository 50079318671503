import React, { useState } from 'react'
import Search from '../subComponents/search/Search';
import AddButton from '../subComponents/buttons/AddButton';
/*
props needed to use this component
1- onButtonClick (callBack Function)
2- onSearchChange (callBack Function)
3- HeaderTitle (string)
4- VisibleButton (boolean)
5- ButtonTitle (string)
*/

const HeaderComponent = ({onButtonClick, onSearchChange, HeaderTitle,visibleButton, ButtonTitle}) => {
    const [query, setQuery] = useState("");
    
  return (
    <>
     <div className="row">
          <div className="col-md-6">
            <h2 className="d-inline-block">{HeaderTitle}</h2>
          </div>
          <div className="col-6 md-4 d-flex justify-content-end align-items-center" style={{ gap: "16px" }}>
            <Search
               setText={(value) => {
                setQuery(value); 
                onSearchChange(value); 
              }}
              text={query}
            />
            {visibleButton && (
              <AddButton onClick={onButtonClick} text= {ButtonTitle} />
            )}
          </div>
        </div>
    </>
  )
}

export default HeaderComponent