import {
  STORE_SUCCESS,
  STORE_FAIL,
  SET_MESSAGE,
  STORE_PENDING,
  ADD_STORE_SUCCESS,
  ADD_STORE_FAIL,
  ADD_STORE_PENDING,
  UPDATE_STORE_SUCCESS,
  UPDATE_STORE_FAIL,
  UPDATE_STORE_PENDING,
} from "../types";
import { toast } from "react-toastify";
import UserService from "../../services/user.service";

export const getStores = (body) => async (dispatch) => {
  dispatch({
    type: STORE_PENDING,
    payload: { stores: "isLoading" },
  });
  return await UserService.getStores(body)
    .then(
      (data) => {
        data.data.status
          ? dispatch({
              type: STORE_SUCCESS,
              payload: { stores: data.data.result[0] },
            })
          : dispatch({
              type: STORE_FAIL,
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: STORE_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};

//add store
export const addStore = (body) => async (dispatch) => {
  dispatch({
    type: ADD_STORE_PENDING,
    payload: { addStore: "isLoading" },
  });
  return await UserService.addStore(body)
    .then(
      (data) => {
        data.data.status
          ? toast.success("Store added Successfully !", { autoClose: 2000 })
          : toast.error(data.data.message, { autoClose: 2000 });
        data.data.status
          ? dispatch({
              type: ADD_STORE_SUCCESS,
              payload: { addStore: data.data.status },
            })
          : dispatch({
              type: ADD_STORE_FAIL,
              payload: { addStore: data.data.status },
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: ADD_STORE_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};
// Update stores
export const updateStore = (body) => async (dispatch) => {
  dispatch({
    type: UPDATE_STORE_PENDING,
    payload: { updateStore: "isLoading" },
  });
  return await UserService.updateStore(body)
    .then(
      (data) => {
        data.data.status
          ? toast.success("Store updated Successfully !", { autoClose: 2000 })
          : toast.error(data.data.message, { autoClose: 2000 });
        data.data.status
          ? dispatch({
              type: UPDATE_STORE_SUCCESS,
              payload: { updateStore: data.data.status },
            })
          : dispatch({
              type: UPDATE_STORE_FAIL,
              payload: { updateStore: data.data.status },
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: UPDATE_STORE_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};

export const nullUpdateStore = () => (dispatch) => {
  return dispatch({
    type: UPDATE_STORE_PENDING,
    payload: { updateStore: "" },
  });
};
