import {
    TASK_STATUS_SUCCESS,
    TASK_STATUS_FAIL,
    SET_MESSAGE,
    TASK_STATUS_PENDING
} from "../types";
import { toast } from "react-toastify";
import UserService from "../../services/user.service";


export const getTaskStatuses = (pageNumber, pageSize, searchQuery) => async(dispatch) => {
    dispatch({
        type: TASK_STATUS_PENDING,
        payload: { taskStatuses: "isLoading" },
    });
    return await UserService.getTaskStatuses(pageNumber, pageSize, searchQuery).then(
        (data) => {
            data.data.status ?
                dispatch({
                    type: TASK_STATUS_SUCCESS,
                    payload: { taskStatuses : data.data },
                }) :
                dispatch({
                    type: TASK_STATUS_FAIL,
                });
            return Promise.resolve();
        },
        (error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: TASK_STATUS_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        console.log(error)
    });
};
