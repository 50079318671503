import { useSelector } from 'react-redux';
import Login from './components/auth/login/Login';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {Switch,Route,BrowserRouter as   Router } from 'react-router-dom';
import Main from './components/main/Main';
import LoginProtected from './routes/LoginProtected';
import Employees from './components/employees/Employees';
import PreLoader from './components/preLoader/PreLoader';
import Dashboard from './components/dashboard/Dashboard';
import Permissions from './components/permissions/Permissions';
import OfficeLocations from './components/officeLocations/OfficeLocations';
import Teams from './components/teams/Teams';
import Products from './components/products/Products';
import Designations from './components/designations/Designations';
import Departments from './components/departments/Departments';
import EmploymentStatuses from './components/employmentStatuses/EmploymentStatuses';
import Cities from './components/cities/Cities';
import Countries from './components/countries/Countries';
import Roles from './components/roles/Roles';
import Doctors from './components/doctors/Doctors';
import PracticeLocations from './components/practiceLocations/PracticeLocations';
import AddEmployees from './components/employees/AddEmployees';
import AddPermissions from './components/permissions/AddPermissions';
import AddOfficeLocations from './components/officeLocations/AddOfficeLocations';
import AddTeams from './components/teams/AddTeams';
import AddProducts from './components/products/AddProducts';
import AddPracticeLocations from './components/practiceLocations/AddPracticeLocations';
import AddLocationTypes from './components/locationTypes/AddLocationTypes';
import LocationTypes from './components/locationTypes/LocationTypes';
import AddRoles from './components/roles/AddRoles';
import AddEmploymentStatuses from './components/employmentStatuses/AddEmploymentStatuses';
import AddCities from './components/cities/AddCities';
import AddDesignations from './components/designations/AddDesignations';
import AddDepartments from './components/departments/AddDepartments';
import DoctorSpecializations from './components/doctorSpecializations/DoctorSpecializations';
import AddDoctorSpecializations from './components/doctorSpecializations/AddDoctorSpecializations';
import DoctorRanks from './components/doctorRanks/DoctorRanks';
import AddDoctorRanks from './components/doctorRanks/AddDoctorRanks';
import AddDoctors from './components/doctors/AddDoctors';
import SubTasks from './components/subTasks/SubTasks';
import AddSubTasks from './components/subTasks/AddSubTasks';
import TaskPriorities from './components/taskPriorities/TaskPriorities';
import AddTaskPriorities from './components/taskPriorities/AddTaskPriorities';
import TaskStatuses from './components/taskStatuses/TaskStatuses';
import TaskTypes from './components/taskTypes/TaskTypes';
import AddTaskTypes from './components/taskTypes/AddTaskTypes';
import Tasks from './components/tasks/medical/Tasks';
import AddTasks from './components/tasks/medical/AddTasks';
import {Redirect} from 'react-router-dom';
import TaskDetail from './components/tasks/TaskDetail';
import Locate from './components/locate/Locate';
import Legacy from './components/legacy/Legacy';
import Stores from './components/stores/Stores';
import StoreTypes from './components/storeTypes/StoreTypes';
import UnitTypes from './components/unitTypes/UnitTypes';
import Stocks from './components/stocks/Stocks';
import GetOtp from './components/forgotPassword/GetOtp';
// import VerifyOtp from './components/forgotPassword/VerifyOtp';
import UpdatePassword from './components/forgotPassword/UpdatePassword';
import TaskTarget from './components/tasks/TaskTarget';
// import DetailsModal from './components/tasks/DetailsModal';
import ViewPermissions from './components/permissions/ViewPermissions';
// import UpdatePermissions from './components/permissions/UpdatePermissions';
import ViewEmployees from './components/employees/ViewEmployees';
import Profile from './components/employees/Profile';
import SummaryModal from './components/dashboard/SummaryModal';
import CustomerRank from './components/nav/customerRank/CustomerRank';
import AddCustomerRank from './components/nav/customerRank/AddCustomerRank';
import Forms from './components/forms/Forms';
import CompletedTask from './components/completedtask/CompletedTask';




function App() {
  const auth = useSelector(state => state.auth);
  return (
    <div className="App wrapper page-wrapper chiller-theme toggled" style={{overflowY:"hidden", paddingTop:"0"}}>
      {/* <PreLoader/> */}
      <Router>
        <Switch>
          <LoginProtected path={"/admin"}>
            <Main>
              <Switch>
                {/* manage routes */}
                <LoginProtected exact path={"/admin"} component={Dashboard}></LoginProtected>
                <LoginProtected exact path={"/admin/legacy"} component={Legacy}></LoginProtected>
                <LoginProtected exact path={"/admin/employees"} component={Employees}></LoginProtected>
                <LoginProtected exact path={"/admin/permissions"} component={Permissions}></LoginProtected>
                <LoginProtected exact path={"/admin/officeLocations"} component={OfficeLocations}></LoginProtected>
                <LoginProtected exact path={"/admin/teams"} component={Teams}></LoginProtected>
                <LoginProtected exact path={"/admin/products"} component={Products}></LoginProtected>
                <LoginProtected exact path={"/admin/designations"} component={Designations}></LoginProtected>
                <LoginProtected exact path={"/admin/customerRank"} component={CustomerRank}></LoginProtected>
                <LoginProtected exact path={"/admin/departments"} component={Departments}></LoginProtected>
                <LoginProtected exact path={"/admin/employmentStatuses"} component={EmploymentStatuses}></LoginProtected>
                <LoginProtected exact path={"/admin/cities"} component={Cities}></LoginProtected>
                <LoginProtected exact path={"/admin/countries"} component={Countries}></LoginProtected>
                <LoginProtected exact path={"/admin/roles"} component={Roles}></LoginProtected>
                <LoginProtected exact path={"/admin/doctors"} component={Doctors}></LoginProtected>
                <LoginProtected exact path={"/admin/practiceLocations"} component={PracticeLocations}></LoginProtected>
                <LoginProtected exact path={"/admin/locationTypes"} component={LocationTypes}></LoginProtected>
                <LoginProtected exact path={"/admin/doctorSpecializations"} component={DoctorSpecializations}></LoginProtected>
                <LoginProtected exact path={"/admin/doctorRanks"} component={DoctorRanks}></LoginProtected>
                <LoginProtected exact path={"/admin/subTasks"} component={SubTasks}></LoginProtected>
                <LoginProtected exact path={"/admin/taskPriorities"} component={TaskPriorities}></LoginProtected>
                <LoginProtected exact path={"/admin/taskStatuses"} component={TaskStatuses}></LoginProtected>
                <LoginProtected exact path={"/admin/taskTypes"} component={TaskTypes}></LoginProtected>
                <LoginProtected exact path={"/admin/unitTypes"} component={UnitTypes}></LoginProtected>
                <LoginProtected exact path={"/admin/stocks"} component={Stocks}></LoginProtected>
                <LoginProtected exact path={"/admin/stores"} component={Stores}></LoginProtected>
                <LoginProtected exact path={"/admin/storetypes"} component={StoreTypes}></LoginProtected>
                <LoginProtected exact path={"/admin/tasks"} component={Tasks}></LoginProtected>
                <LoginProtected exact path={"/admin/task/view"} component={TaskDetail}></LoginProtected>
                <LoginProtected exact path={"/admin/forms"} component={Forms}></LoginProtected>
                <LoginProtected exact path={"/admin/completedtask"} component={CompletedTask}></LoginProtected>
                {/* Add manage routes */}
                <LoginProtected exact path={"/admin/Addemployees"} component={AddEmployees}></LoginProtected>
                <LoginProtected exact path={"/admin/AddPermissions"} component={AddPermissions}></LoginProtected>
                <LoginProtected exact path={"/admin/AddOfficeLocations"} component={AddOfficeLocations}></LoginProtected>
                <LoginProtected exact path={"/admin/AddTeams"} component={AddTeams}></LoginProtected>
                <LoginProtected exact path={"/admin/AddProducts"} component={AddProducts}></LoginProtected>
                <LoginProtected exact path={"/admin/AddPracticeLocations"} component={AddPracticeLocations}></LoginProtected>
                <LoginProtected exact path={"/admin/AddLocationTypes"} component={AddLocationTypes}></LoginProtected>
                <LoginProtected exact path={"/admin/AddRoles"} component={AddRoles}></LoginProtected>
                <LoginProtected exact path={"/admin/AddEmploymentStatuses"} component={AddEmploymentStatuses}></LoginProtected>
                <LoginProtected exact path={"/admin/AddCustomerRank"} component={AddCustomerRank}></LoginProtected>
                <LoginProtected exact path={"/admin/UpdateCustomerRank"} component={AddCustomerRank}></LoginProtected>
                <LoginProtected exact path={"/admin/AddCities"} component={AddCities}></LoginProtected>
                <LoginProtected exact path={"/admin/AddDesignations"} component={AddDesignations}></LoginProtected>
                <LoginProtected exact path={"/admin/AddDepartments"} component={AddDepartments}></LoginProtected>
                <LoginProtected exact path={"/admin/AddDoctorSpecializations"} component={AddDoctorSpecializations}></LoginProtected>
                <LoginProtected exact path={"/admin/AddDoctorRanks"} component={AddDoctorRanks}></LoginProtected>
                <LoginProtected exact path={"/admin/AddDoctors"} component={AddDoctors}></LoginProtected>
                <LoginProtected exact path={"/admin/AddSubTasks"} component={AddSubTasks}></LoginProtected>
                <LoginProtected exact path={"/admin/AddTaskPriorities"} component={AddTaskPriorities}></LoginProtected>
                <LoginProtected exact path={"/admin/AddTaskTypes"} component={AddTaskTypes}></LoginProtected>
                <LoginProtected exact path={"/admin/AddTasks"} component={AddTasks}></LoginProtected>
                {/* Update manage routes */}
                <LoginProtected exact path={"/admin/UpdateRoles"} component={AddRoles}></LoginProtected>
                <LoginProtected exact path={"/admin/UpdatePermissions"} component={AddPermissions}></LoginProtected>
                <LoginProtected exact path={"/admin/ViewPermissions"} component={ViewPermissions}></LoginProtected>
                <LoginProtected exact path={"/admin/ViewEmployees"} component={ViewEmployees}></LoginProtected>
                <LoginProtected exact path={"/admin/Profile"} component={Profile}></LoginProtected>
                <LoginProtected exact path={"/admin/UpdatePermissionsGroup"} component={AddPermissions}></LoginProtected>
                <LoginProtected exact path={"/admin/UpdateCities"} component={AddCities}></LoginProtected>
                <LoginProtected exact path={"/admin/UpdateEmploymentStatuses"} component={AddEmploymentStatuses}></LoginProtected>
                <LoginProtected exact path={"/admin/UpdateProducts"} component={AddProducts}></LoginProtected>
                <LoginProtected exact path={"/admin/UpdateTeams"} component={AddTeams}></LoginProtected>
                <LoginProtected exact path={"/admin/UpdateOfficeLocations"} component={AddOfficeLocations}></LoginProtected>
                <LoginProtected exact path={"/admin/UpdateEmployees"} component={AddEmployees}></LoginProtected>
                <LoginProtected exact path={"/admin/UpdatePracticeLocations"} component={AddPracticeLocations}></LoginProtected>
                <LoginProtected exact path={"/admin/UpdateLocationTypes"} component={AddLocationTypes}></LoginProtected>
                <LoginProtected exact path={"/admin/UpdateDesignations"} component={AddDesignations}></LoginProtected>
                <LoginProtected exact path={"/admin/UpdateDepartments"} component={AddDepartments}></LoginProtected>
                <LoginProtected exact path={"/admin/UpdateDoctorSpecializations"} component={AddDoctorSpecializations}></LoginProtected>
                <LoginProtected exact path={"/admin/UpdateDoctorRanks"} component={AddDoctorRanks}></LoginProtected>
                <LoginProtected exact path={"/admin/UpdateDoctors"} component={AddDoctors}></LoginProtected>
                <LoginProtected exact path={"/admin/UpdateSubTasks"} component={AddSubTasks}></LoginProtected>
                <LoginProtected exact path={"/admin/UpdateTaskPriorities"} component={AddTaskPriorities}></LoginProtected>
                <LoginProtected exact path={"/admin/UpdateTaskTypes"} component={AddTaskTypes}></LoginProtected>
                <LoginProtected exact path={"/admin/TaskTarget"} component={TaskTarget}></LoginProtected>
                {/* <LoginProtected exact path={"/admin/DetailsModal"} component={DetailsModal}></LoginProtected> */}
                <LoginProtected exact path={"/admin/dashboard/SummaryModal"} component={SummaryModal}></LoginProtected>
                {/* locate route */}
                <LoginProtected exact path={"/admin/Locate"} component={Locate}></LoginProtected>
                
                <Redirect to="/" />
              </Switch>
            </Main>
          </LoginProtected>
        </Switch>
          {auth.isLoggedIn === true 
          ?
          <Redirect to="/admin" /> 
          :
          <Switch>
            <Route exact path={"/"} component={Login}>
           
            </Route>
            {/* <Redirect to="/" />  */}
            
          </Switch>  
          }
           <Switch>
            <Route exact path={"/forgotPassword"} component={GetOtp}></Route>
            {/* <Route exact path={"/forgotPassword/VerifyOtp"} component={VerifyOtp}></Route> */}
            <Route exact path={"/forgotPassword/UpdatePassword"} component={UpdatePassword}></Route>
          </Switch> 
      </Router>
    </div>
  );
}

export default App;
