import {
    PRODUCT_SUCCESS,
    PRODUCT_FAIL,
    PRODUCT_PENDING,
    ADD_PRODUCT_SUCCESS,
    ADD_PRODUCT_FAIL,
    ADD_PRODUCT_PENDING,
    UPDATE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_FAIL,
    UPDATE_PRODUCT_PENDING
} from "../../actions/types";

const initialState = { 
    products: [],
    addProducts :'',
    updateProducts : ''
    };

const products_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case PRODUCT_SUCCESS:
            return {
                ...state,
                products: payload.products,
            };
        case PRODUCT_PENDING:
            return {
                ...state,
                products: payload.products,
            };
        case PRODUCT_FAIL:
            return {
                ...state,
                products: []
            };
        case ADD_PRODUCT_SUCCESS:
            return {
                ...state,
                addProducts: payload.addProducts,
            };
        case ADD_PRODUCT_PENDING:
            return {
                ...state,
                addProducts: payload.addProducts,
            };
        case ADD_PRODUCT_FAIL:
            return {
                ...state,
                addProducts: false
            };
        case UPDATE_PRODUCT_SUCCESS:
                return {
                    ...state,
                    updateProducts: payload.updateProducts,
                };
        case UPDATE_PRODUCT_PENDING:
                return {
                    ...state,
                    updateProducts: payload.updateProducts,
                };
        case UPDATE_PRODUCT_FAIL:
                return {
                    ...state,
                    updateProducts: false
                };
        default:
            return state;
    }
}
export default products_reducer