import {
    STOCK_SUCCESS,
    STOCK_FAIL,
    STOCK_PENDING,
    ADD_STOCK_SUCCESS,
    ADD_STOCK_FAIL,
    ADD_STOCK_PENDING,
    UPDATE_STOCK_SUCCESS,
    UPDATE_STOCK_FAIL,
    UPDATE_STOCK_PENDING,
} from "../../actions/types";

const initialState = { 
    stocks: [],
    addStock : '',
    updateStock : '',
    };

const stocks_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case STOCK_SUCCESS:
            return {
                ...state,
                stocks: payload.stocks,
            };
        case STOCK_PENDING:
            return {
                ...state,
                stocks: payload.stocks,
            };
        case STOCK_FAIL:
            return {
                ...state,
                stocks: []
            };
        case ADD_STOCK_SUCCESS:
            return {
                ...state,
                addStock: payload.addStock,
            };
        case ADD_STOCK_PENDING:
            return {
                ...state,
                addStock: payload.addStock,
            };
        case ADD_STOCK_FAIL:
            return {
                ...state,
                addStock: false
            };
            case UPDATE_STOCK_SUCCESS:
                return {
                    ...state,
                    updateStock: payload.updateStock,
                };
            case UPDATE_STOCK_PENDING:
                return {
                    ...state,
                    updateStock: payload.updateStock,
                };
            case UPDATE_STOCK_FAIL:
                return {
                    ...state,
                    updateStock: false
                };
        default:
            return state;
    }
}
export default stocks_reducer