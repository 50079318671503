
import taskService from "../../../services/task.service";
import { TASK_TARGET_FAIL, TASK_TARGET_PENDING, TASK_TARGET_SUCCESS } from "../../types";

export const getTaskTarget = (body) => async (dispatch) => {
    dispatch({
      type: TASK_TARGET_PENDING,
      payload: { tasksTarget: "isLoading" },
    });
    return await taskService.getTaskTarget(body)
      .then(
        (data) => {
            console.log(data, "data of task target");
          data.data.status
            ? dispatch({
                type: TASK_TARGET_SUCCESS,
                payload: { tasksTarget: data.data.result},
              })
            : dispatch({
                type: TASK_TARGET_FAIL,
              });
          return Promise.resolve();
        },
        (error) => {
          console.log("error......", error);
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
  
          dispatch({
            type: TASK_TARGET_FAIL,
          });
  
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
  
          return Promise.reject();
        }
      )
      .catch((error) => {
        console.log(error);
      });
  };