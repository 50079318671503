 const Task = [
    'CanViewTask',
    'CanViewSubOrdinateTask',
    'CanLocateSubOrdinate'
    ];
 const Manage = ['CanViewEmployee',
    'CanViewPermission',
    'CanViewOfficeLocation',
    'CanViewCustomerRank',
    'CanViewTeam',
    'CanViewProduct',
    'CanViewDesignation',
    'CanViewDoctor',
    'CanViewPracticeLocation',
    'CanViewDepartment',
    'CanViewEmploymentStatus',
    'CanViewCity',
    'CanViewCountry',
    'CanViewRole',
    'CanViewDoctorSpecialization',
    'CanViewDoctorSpecialization',
    'CanViewDoctorRank',
    'CanViewSubTask',
    'CanViewTaskStatus',
    'CanViewTaskType',
    'CanViewTaskPriority',
    "CanViewStore",
    "CanViewStoreType",
    "CanViewUnitType"
    ];
 const Billing = [
    'CanViewPendingBill',
    'CanViewPreviousBill'
    ];
 const Invoice = [
    'CanGenerateInvoice',
    'CanUpdateInvoice'
    ];
 var Tasks = false ;
 var Manages = false ;
 var Billings = false;
 var Invoices = false;
 export const TaskExist = (permissions, localPermissions) => {
    Task.every(function (element) {
      if(permissions || localPermissions){
        if(permissions.includes(element) || localPermissions.includes(element)){
            Tasks = true;
        }
    }
    return Tasks;
    });
    return Tasks;
}
export const ManageExist = (permissions, localPermissions) => {
    Manage.every(function (element) {
      if(permissions || localPermissions){
        if(permissions.includes(element) || localPermissions.includes(element)){
            Manages = true;
        }
    }
    return Manages
    });
    return Manages
}
export const BillingExist = (permissions, localPermissions) => {
    Billing.every(function (element) {
      if(permissions || localPermissions){
        if(permissions.includes(element) || localPermissions.includes(element)){
            Billings = true;
        }
    }
    return Billings
    });
    return Billings
}
export const InvoiceExist = (permissions, localPermissions) => {
    Invoice.every(function (element) {
      if(permissions || localPermissions){
        if(permissions.includes(element) || localPermissions.includes(element)){
            Invoices = true;
        }
    }
    return Invoices
    });
    return Invoices
}