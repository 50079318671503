import React from 'react';
import { Button } from "@mui/material";

const ApplyButton = ({ onClick }) => {
    return (
        <Button
            style={{
                width: "77px",
                height: "32px",
                padding: "4px 14px 4px 14px",
                gap: "8px",
                borderRadius: "4px",
                background: "#002D72",
                border: "1px solid #002D72",
                boxShadow: "2px #0000000B",
                border: "1px solid #D9D9D9",
                color: "#FFFFFF",
                textTransform: "none",
                cursor: "pointer",
                transition: "background-color 0.3s ease"
            }}
            onMouseOver={(e) => e.currentTarget.style.backgroundColor = "#004BB4"}
            onMouseOut={(e) => e.currentTarget.style.backgroundColor = "#002D72"}
            onClick={onClick}
        >Apply</Button>
    );
}

export default ApplyButton;