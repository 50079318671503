import {
    SUB_TASK_SUCCESS,
    SUB_TASK_FAIL,
    SUB_TASK_PENDING,
    ADD_SUB_TASK_SUCCESS,
    ADD_SUB_TASK_FAIL,
    ADD_SUB_TASK_PENDING,
    UPDATE_SUB_TASK_SUCCESS,
    UPDATE_SUB_TASK_FAIL,
    UPDATE_SUB_TASK_PENDING
} from "../../actions/types";

const initialState = { 
    subTasks: [],
    addSubTasks :'',
    updateSubTasks : ''
    };

const sub_tasks_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SUB_TASK_SUCCESS:
            return {
                ...state,
                subTasks: payload.subTasks,
            };
        case SUB_TASK_PENDING:
            return {
                ...state,
                subTasks: payload.subTasks,
            };
        case SUB_TASK_FAIL:
            return {
                ...state,
                subTasks: []
            };
        case ADD_SUB_TASK_SUCCESS:
            return {
                ...state,
                addSubTasks: payload.addSubTasks,
            };
        case ADD_SUB_TASK_PENDING:
            return {
                ...state,
                addSubTasks: payload.addSubTasks,
            };
        case ADD_SUB_TASK_FAIL:
            return {
                ...state,
                addSubTasks: false
            };
        case UPDATE_SUB_TASK_SUCCESS:
                return {
                    ...state,
                    updateSubTasks: payload.updateSubTasks,
                };
        case UPDATE_SUB_TASK_PENDING:
                return {
                    ...state,
                    updateSubTasks: payload.updateSubTasks,
                };
        case UPDATE_SUB_TASK_FAIL:
                return {
                    ...state,
                    updateSubTasks: false
                };
        default:
            return state;
    }
}
export default sub_tasks_reducer