import React from "react";
import { Redirect,Route } from "react-router-dom";

const LoginProtected = ({component:Cmp,...rest}) => (

    <Route
        {...rest}
        render={()=>(
            localStorage.getItem("user") ?(
                <Cmp />
            ):
            <Redirect to="/"/>
        )}
    />
    );
    export default LoginProtected;