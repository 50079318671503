import React from 'react'
import AlertIcon from '../../../assets/images/alert_icon.svg'
import { MODAL_STYLE } from '../../../Constatns';
import {Modal,Box,Button} from '@mui/material';

function DeleteModal(props) {
    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={MODAL_STYLE}>
                <div className="d-flex">
                    <img style={{ height: '26px' }} src={AlertIcon} alt="" />
                    <h3 className='ml-2' >{props.title}</h3>
                </div>
                <p className='mt-4'>Are you sure you want to delete this {props.type}? This action cannot be reversed.</p>
                <div className="d-flex mt-5 float-right">
                    <Button className="btn delete-btn mr-2">Delete</Button>
                    <Button className='btn cancel-btn' onClick={props.onClose}>Cancel</Button>
                </div>
            </Box>
        </Modal>
    )
}

export default DeleteModal