import {
  PRACTICE_LOCATION_PENDING,
  PRACTICE_LOCATION_SUCCESS,
  PRACTICE_LOCATION_FAIL,
  ADD_PRACTICE_LOCATION_PENDING,
  ADD_PRACTICE_LOCATION_SUCCESS,
  ADD_PRACTICE_LOCATION_FAIL,
  UPDATE_PRACTICE_LOCATION_PENDING,
  UPDATE_PRACTICE_LOCATION_SUCCESS,
  UPDATE_PRACTICE_LOCATION_FAIL,
  PRACTICE_LOCATION_TYPE_PENDING,
  PRACTICE_LOCATION_TYPE_SUCCESS,
  PRACTICE_LOCATION_TYPE_FAIL,
  SET_MESSAGE,
} from "../types";
import { toast } from "react-toastify";
import UserService from "../../services/user.service";

export const getPracticeLocations = (body) => async (dispatch) => {
  dispatch({
    type: PRACTICE_LOCATION_PENDING,
    payload: { practiceLocations: "isLoading" },
  });
  return await UserService.getPracticeLocations(body)
    .then(
      (data) => {
        data.data.status
          ? dispatch({
              type: PRACTICE_LOCATION_SUCCESS,
              payload: { practiceLocations: data.data.result },
            })
          : dispatch({
              type: PRACTICE_LOCATION_FAIL,
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: PRACTICE_LOCATION_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};

export const getPracticeLocationTypes = (pageNumber, pageSize, searchQuery) => async (dispatch) => {
  dispatch({
    type: PRACTICE_LOCATION_TYPE_PENDING,
    payload: { practiceLocationTypes: "isLoading" },
  });
  return await UserService.getLocationType(pageNumber, pageSize, searchQuery)
    .then(
      (data) => {
        data.data.status
          ? dispatch({
              type: PRACTICE_LOCATION_TYPE_SUCCESS,
              payload: { practiceLocationTypes: data.data.result },
            })
          : dispatch({
              type: PRACTICE_LOCATION_TYPE_FAIL,
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: PRACTICE_LOCATION_TYPE_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};

export const addPracticeLocation = (body) => async (dispatch) => {
  dispatch({
    type: ADD_PRACTICE_LOCATION_PENDING,
    payload: { addPracticeLocation: "isLoading" },
  });
  return await UserService.addPracticeLocation(body)
    .then(
      (data) => {
        console.log(body);
        data.data.status
          ? toast.success("Practice location added Successfully !", {
              autoClose: 2000,
            })
          : toast.error(data.data.message, { autoClose: 2000 });
        data.data.status
          ? dispatch({
              type: ADD_PRACTICE_LOCATION_SUCCESS,
              payload: { addPracticeLocation: data.data.status },
            })
          : dispatch({
              type: ADD_PRACTICE_LOCATION_FAIL,
              payload: { addPracticeLocation: data.data.status },
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: ADD_PRACTICE_LOCATION_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    }).finally(()=>{
      dispatch({
        type: ADD_PRACTICE_LOCATION_FAIL,
      });
    });
};

//update Practice Location
export const updatePracticeLocations = (body) => async (dispatch) => {
  dispatch({
    type: UPDATE_PRACTICE_LOCATION_PENDING,
    payload: { updatePracticeLocation: "isLoading" },
  });
  return await UserService.updatePracticeLocation(body)
    .then(
      (data) => {
        console.log(body);
        data.data.status
          ? toast.success("Practice location updated Successfully !", {
              autoClose: 2000,
            })
          : toast.error(data.data.message, { autoClose: 2000 });
        data.data.status
          ? dispatch({
              type: UPDATE_PRACTICE_LOCATION_SUCCESS,
              payload: { updatePracticeLocation: data.data.status },
            })
          : dispatch({
              type: UPDATE_PRACTICE_LOCATION_FAIL,
              payload: { updatePracticeLocation: data.data.status },
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: UPDATE_PRACTICE_LOCATION_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    }).finally(()=>{
      dispatch({
        type: UPDATE_PRACTICE_LOCATION_FAIL,
      });
    });
};
