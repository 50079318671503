import React from 'react'
import './Login.css'
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { login } from '../../../actions/auth/auth'
import { Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../subComponents/Loader';
import logo from "../../../assets/images/Logo.svg";
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/VisibilityOutlined';
import VisibilityOff from '@mui/icons-material/VisibilityOffOutlined';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Button } from '@mui/material';
import login_image from "../../../assets/images/login_image.svg";
import CopyrightIcon from '@mui/icons-material/Copyright';
import mobile_image_login from "../../../assets/images/mobile_image_login.svg";
import SidebarIcons from '../../nav/SidebarIcons';
import { DOWNLOAD_APP_URL } from '../../../Constatns';


function Login() {
  const initialValues = { email: "", password: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const download_app = DOWNLOAD_APP_URL;
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const user = JSON.parse(localStorage.getItem("user"));
  const [randomSubtitle, setRandomSubtitle] = useState('');
  const subtitles = [
    "Analyzing previous trends using AI and ensuring that you always make the right decision and achieve your goals with confidence and efficiency.",
    "Set Priority Levels: Prioritize your tasks by setting their importance. Use color-coded tags to easily identify high-priority items.",
    "Custom Reminders: Never miss a deadline by setting custom reminders for your tasks. You can choose to be notified minutes, hours, or days in advance.",
    "Collaborate Seamlessly: Assign tasks to team members and track their progress in real-time. Enhance collaboration and ensure everyone stays on the same page",
    "Daily Summaries: Start your day with a quick overview of your tasks and priorities with our daily summary feature.",
    "Track Progress: Use the progress tracker to monitor the completion of tasks and stay motivated by seeing your progress."
  ];

  useEffect(() => {
    // Set a random subtitle on component mount
    const randomIndex = Math.floor(Math.random() * subtitles.length);
    setRandomSubtitle(subtitles[randomIndex]);
  }, []);
  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        loginHandle();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };

  }, [formValues])

  const currentYear = new Date().getFullYear();


  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      dispatch(login(formValues.email.trim(), formValues.password));
    }
  }, [formErrors])

  const loginHandle = async () => {
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    { user && !user.status && localStorage.clear() }
  }
  const validate = (values) => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!values.email) {
      errors.email = "Email is required!"
    } else if (!emailRegex.test(values.email.trim())) {
      errors.email = "Invalid email!";
    }
    if (!values.password) {
      errors.password = "Password is required!"
    }
    return errors;
  }
  if (user && user.status) {
    return <Redirect to="/admin" />
  }

  else return (
    <>
      {user && !user.status && <ToastContainer />}
      <div className="container-fluid p-0">
        <div className="row">
          <div
            className="col-lg-6 col-sm-12 d-flex flex-column justify-content-between"
            style={{ minHeight: "100vh" }}
          >
            <div className="form-wrapper">
              <div className="form-container">
                <div className="logo">
                  <img src={logo} alt="Logo" />
                </div>
                <div className="login-form">
                  <div className="login-fields">
                    <div className="fields-container">
                      <div className="email">
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel htmlFor="outlined-email">
                            Email
                          </InputLabel>
                          <OutlinedInput
                            name="email"
                            id="email"
                            type="text"
                            value={formValues.email}
                            onChange={handleChange}
                            startAdornment={
                              <InputAdornment position="start">
                                <EmailOutlinedIcon />
                              </InputAdornment>
                            }
                            label="Email"
                          />
                          <p style={{ color: "red" }}>{formErrors.email}</p>
                        </FormControl>
                      </div>
                      <div className="password">
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel htmlFor="outlined-adornment-password">
                            Password
                          </InputLabel>
                          <OutlinedInput
                            name="password"
                            id="password"
                            type={showPassword ? "text" : "password"}
                            value={formValues.password}
                            onChange={handleChange}
                            startAdornment={
                              <InputAdornment position="start">
                                <LockOutlinedIcon />
                              </InputAdornment>
                            }
                            endAdornment={
                              <InputAdornment
                                position="end"
                                sx={{ marginRight: 0 }}
                              >
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                  sx={{
                                    backgroundColor: "transparent",
                                    "&:hover": {
                                      backgroundColor: "transparent",
                                    },
                                    padding: "5px",
                                  }}
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Password"
                          />
                          <p style={{ color: "red" }}>{formErrors.password}</p>
                        </FormControl>
                      </div>
                      {/* <div className='check-box'>
                        <FormControlLabel control={<Checkbox size="small" />} label="Keep logged in" />
                      </div> */}
                    </div>
                    <div className="login-button">
                      <Button
                        onSubmit={loginHandle}
                        onClick={loginHandle}
                        disabled={auth.isLogging}
                        style={{
                          position: "relative",
                          height: "40px",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: " 4px 14px 4px 14px",
                          gap: "8px",
                          borderRadius: "6px",
                          border: "1px solid #D9D9D9",
                          background: "#002D72",
                          color: "#FFFFFF",
                          boxShadow: "2px #0000000B",
                          textTransform: "none",
                          cursor: "pointer",
                          transition: "background-color 0.3s ease",
                        }}
                        onMouseOver={(e) =>
                          (e.currentTarget.style.backgroundColor = "#004BB4")
                        }
                        onMouseOut={(e) =>
                          (e.currentTarget.style.backgroundColor = "#002D72")
                        }
                        fullWidth
                      >
                        {auth.isLogging ? (
                          <div
                            style={{
                              display: "inline-block",
                              marginTop: "8px",
                            }}
                          >
                            <Loader />
                          </div>
                        ) : (
                          "Login"
                        )}
                      </Button>
                    </div>
                    <p className="text-center mt-2">
                      <a href="/forgotPassword">Forgot Password?</a>
                    </p>
                    <Button
                      className="download-app-button"
                      onClick={() => (window.location.href = download_app)}
                      style={{
                        position: "relative",
                        marginTop: "24px",
                        width: "100%",
                        height: "48px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: " 4px 14px 4px 14px",
                        gap: "8px",
                        borderRadius: "6px",
                        border: "1px solid #D9D9D9",
                        color: "#002D72",
                        boxShadow: "2px #0000000B",
                        textTransform: "none",
                        cursor: "pointer",
                        transition: "background-color 0.3s ease",
                      }}
                      fullWidth
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ marginRight: "8px" }}
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                      >
                        <path
                          d="M17.4525 9.74864L19.2795 7.83282C19.4161 7.6845 19.4917 7.48583 19.49 7.27963C19.4883 7.07342 19.4094 6.87617 19.2704 6.73035C19.1313 6.58453 18.9432 6.50182 18.7465 6.50003C18.5499 6.49824 18.3605 6.57751 18.219 6.72077L16.089 8.95432C14.874 8.38492 13.4925 8.06247 12 8.06247C10.5075 8.06247 9.126 8.38492 7.911 8.95432L5.781 6.72077C5.63955 6.57751 5.4501 6.49824 5.25345 6.50003C5.0568 6.50182 4.86869 6.58453 4.72964 6.73035C4.59058 6.87617 4.5117 7.07342 4.50999 7.27963C4.50829 7.48583 4.58388 7.6845 4.7205 7.83282L6.5475 9.74864C4.368 11.2964 3 13.7879 3 16.7135C3 16.9221 3.07902 17.1222 3.21967 17.2697C3.36032 17.4171 3.55109 17.5 3.75 17.5H20.25C20.4489 17.5 20.6397 17.4171 20.7803 17.2697C20.921 17.1222 21 16.9221 21 16.7135C21 13.7879 19.632 11.2964 17.4525 9.74864ZM8.25 14.3542C8.05109 14.3542 7.86032 14.2713 7.71967 14.1238C7.57902 13.9763 7.5 13.7763 7.5 13.5677C7.5 13.3591 7.57902 13.1591 7.71967 13.0116C7.86032 12.8641 8.05109 12.7812 8.25 12.7812C8.44891 12.7812 8.63968 12.8641 8.78033 13.0116C8.92098 13.1591 9 13.3591 9 13.5677C9 13.7763 8.92098 13.9763 8.78033 14.1238C8.63968 14.2713 8.44891 14.3542 8.25 14.3542ZM15.75 14.3542C15.5511 14.3542 15.3603 14.2713 15.2197 14.1238C15.079 13.9763 15 13.7763 15 13.5677C15 13.3591 15.079 13.1591 15.2197 13.0116C15.3603 12.8641 15.5511 12.7812 15.75 12.7812C15.9489 12.7812 16.1397 12.8641 16.2803 13.0116C16.421 13.1591 16.5 13.3591 16.5 13.5677C16.5 13.7763 16.421 13.9763 16.2803 14.1238C16.1397 14.2713 15.9489 14.3542 15.75 14.3542Z"
                          fill="#002D72"
                        />
                      </svg>
                      Download Android APP
                    </Button>
                    <div className="footer-1">
                      <CopyrightIcon />
                      <p>Copyright Field Force {currentYear}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-6 col-md-6 col-sm-12"
            style={{
              background: `url(${login_image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="main-right">
              <div className="top-right">
                <div className="col">
                  <div className="title">
                    <p>Empower Your Productivity</p>
                    <div className="sub-title mt-2">
                      <span>
                        Effortlessly manage tasks, enhance productivity, and
                        achieve your goals with FieldForce-AI.
                      </span>
                    </div>
                    <Button
                      onClick={() => (window.location.href = download_app)}
                      style={{
                        position: "relative",
                        marginTop: "24px",
                        width: "100%",
                        height: "48px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: " 4px 14px 4px 14px",
                        gap: "8px",
                        borderRadius: "6px",
                        border: "1px solid #D9D9D9",
                        background: "#002D72",
                        color: "#FFFFFF",
                        boxShadow: "2px #0000000B",
                        textTransform: "none",
                        cursor: "pointer",
                        transition: "background-color 0.3s ease",
                      }}
                      onMouseOver={(e) =>
                        (e.currentTarget.style.backgroundColor = "#004BB4")
                      }
                      onMouseOut={(e) =>
                        (e.currentTarget.style.backgroundColor = "#002D72")
                      }
                      fullWidth
                    >
                      <img
                        src={SidebarIcons.download_icon}
                        width={25}
                        height={25}
                        alt="Download "
                        style={{ marginRight: "8px" }}
                      />
                      Download Android APP
                    </Button>
                  </div>
                </div>

                <div className="col mobile-image">
                  <img src={mobile_image_login} />
                </div>
              </div>
              <div className="bottom-right">
                <div className="title-bottom">
                  <p>Introducing new features</p>
                </div>
                <div className="sub-title-bottom">
                  <span id="randomText">{randomSubtitle}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login
