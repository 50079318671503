import {
    EMPLOYMENT_STATUS_SUCCESS,
    EMPLOYMENT_STATUS_FAIL,
    SET_MESSAGE,
    EMPLOYMENT_STATUS_PENDING,
    ADD_EMPLOYMENT_STATUS_SUCCESS,
    ADD_EMPLOYMENT_STATUS_FAIL,
    ADD_EMPLOYMENT_STATUS_PENDING,
    UPDATE_EMPLOYMENT_STATUS_SUCCESS,
    UPDATE_EMPLOYMENT_STATUS_FAIL,
    UPDATE_EMPLOYMENT_STATUS_PENDING
} from "../types";
import { toast} from 'react-toastify'; 
import UserService from "../../services/user.service";


export const getEmploymentStatuses = (pageNumber, pageSize, searchQuery) => async(dispatch) => {
    dispatch({
        type: EMPLOYMENT_STATUS_PENDING,
        payload: { employmentStatuses: "isLoading" },
    });
    return await UserService.getEmploymentStatuses(pageNumber, pageSize, searchQuery).then(
        (data) => {
            data.data.status ?
                dispatch({
                    type: EMPLOYMENT_STATUS_SUCCESS,
                    payload: { employmentStatuses: data.data },
                }) :
                dispatch({
                    type: EMPLOYMENT_STATUS_FAIL,
                });
            return Promise.resolve();
        },
        (error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: EMPLOYMENT_STATUS_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        return Promise.reject();
    });
};

    // Add Employment Status 
    export const addEmploymentStatuses = (body) => async(dispatch) => {
        dispatch({
            type: ADD_EMPLOYMENT_STATUS_PENDING,
            payload: { addEmploymentStatus : "isLoading" },
        });
        return await UserService.addEmploymentStatus(body).then(
            (data) => {
                data.data.status ? toast.success("Employment Status added Successfully !", {autoClose: 2000 }): toast.error(data.data.message, {autoClose: 2000 });
                data.data.status ?
                    dispatch({
                        type: ADD_EMPLOYMENT_STATUS_SUCCESS,
                        payload: { addEmploymentStatus: data.data.status},
                    })
                 :
                    dispatch({
                        type: ADD_EMPLOYMENT_STATUS_FAIL,
                        payload: { addEmploymentStatus: data.data.status},
                    });
                return Promise.resolve();
            },
            (error) => {
                console.log("error......", error);
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
    
                dispatch({
                    type: ADD_EMPLOYMENT_STATUS_FAIL,
                });
    
                dispatch({
                    type: SET_MESSAGE,
                    payload: message,
                });
    
                return Promise.reject();
            }
        ).catch((error) => {
            console.log(error)
        }).finally(()=>{
            dispatch({
              type: ADD_EMPLOYMENT_STATUS_FAIL,
            });
          });
    };

     // Update Employment Status 
     export const updateEmploymentStatuses = (body) => async(dispatch) => {
        dispatch({
            type: UPDATE_EMPLOYMENT_STATUS_PENDING,
            payload: { updateEmploymentStatus : "isLoading" },
        });
        return await UserService.updateEmploymentStatus(body).then(
            (data) => {
                data.data.status ? toast.success("Employment Status updated Successfully !", {autoClose: 2000 }): toast.error(data.data.message, {autoClose: 2000 });
                data.data.status ?
                    dispatch({
                        type: UPDATE_EMPLOYMENT_STATUS_SUCCESS,
                        payload: { updateEmploymentStatus: data.data.status},
                    })
                 :
                    dispatch({
                        type: UPDATE_EMPLOYMENT_STATUS_FAIL,
                        payload: { updateEmploymentStatus: data.data.status},
                    });
                return Promise.resolve();
            },
            (error) => {
                console.log("error......", error);
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
    
                dispatch({
                    type: UPDATE_EMPLOYMENT_STATUS_FAIL,
                });
    
                dispatch({
                    type: SET_MESSAGE,
                    payload: message,
                });
    
                return Promise.reject();
            }
        ).catch((error) => {
            console.log(error)
        }).finally(()=>{
            dispatch({
              type: UPDATE_EMPLOYMENT_STATUS_FAIL,
            });
          });
    };
