import {
    DEPARTMENT_SUCCESS,
    DEPARTMENT_FAIL,
    DEPARTMENT_PENDING,
    ADD_DEPARTMENT_SUCCESS,
    ADD_DEPARTMENT_FAIL,
    ADD_DEPARTMENT_PENDING,
    UPDATE_DEPARTMENT_SUCCESS,
    UPDATE_DEPARTMENT_FAIL,
    UPDATE_DEPARTMENT_PENDING,
} from "../../actions/types";

const initialState = { 
    departments: [],
    addDepartment :'',
    updateDepartment : ''
    };

const departments_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case DEPARTMENT_SUCCESS:
            return {
                ...state,
                departments: payload.departments,
            };
        case DEPARTMENT_PENDING:
            return {
                ...state,
                departments: payload.departments,
            };
        case DEPARTMENT_FAIL:
            return {
                ...state,
                departments: []
            };
            case ADD_DEPARTMENT_SUCCESS:
                return {
                    ...state,
                    addDepartment: payload.addDepartment,
                };
            case ADD_DEPARTMENT_PENDING:
                return {
                    ...state,
                    addDepartment: payload.addDepartment,
                };
            case ADD_DEPARTMENT_FAIL:
                return {
                    ...state,
                    addDepartment: false
                };
            case UPDATE_DEPARTMENT_SUCCESS:
                    return {
                        ...state,
                        updateDepartment: payload.updateDepartment,
                    };
            case UPDATE_DEPARTMENT_PENDING:
                    return {
                        ...state,
                        updateDepartment: payload.updateDepartment,
                    };
            case UPDATE_DEPARTMENT_FAIL:
                    return {
                        ...state,
                        updateDepartment: false
                    };
        default:
            return state;
    }
}
export default departments_reducer