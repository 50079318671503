import {
  DELETE_FORM_FAIL,
  DELETE_FORM_PENDING,
  DELETE_FORM_SUCCESS,
    FORMS_GET_FAIL,
    FORMS_GET_PENDING,
    FORMS_GET_SUCCESS,
    SET_MESSAGE,
    UPLOAD_FORM_FAIL,
    UPLOAD_FORM_PENDING,
    UPLOAD_FORM_SUCCESS,
  } from "../types";
  import UserService from "../../services/user.service";
  import { toast} from 'react-toastify'; 

  export const getForms = () => async (dispatch) => {
    dispatch({
      type: FORMS_GET_PENDING,
      payload: { forms: "isLoading" },
    });
    return await UserService.getForms()
      .then(
        (data) => {
            
          data.data.status
            ? dispatch({
                type: FORMS_GET_SUCCESS,
                payload: { forms: data.data },
              })
            : dispatch({
                type: FORMS_GET_FAIL,
              });
          return Promise.resolve();
        },
        (error) => {
          console.log("error......", error);
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
  
          dispatch({
            type: FORMS_GET_FAIL,
          });
  
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
  
          return Promise.reject();
        }
      )
      .catch((error) => {
        console.log(error);
      });
  };


  export const upLoadForms = (file) => async (dispatch) => {
    dispatch({
      type: UPLOAD_FORM_PENDING,
      payload: { uploadForm: "isLoading" },
    });
    return await UserService.uploadForm(file)
      .then(
        (data) => {
            
          data.data.status
            ? dispatch({
                type: UPLOAD_FORM_SUCCESS,
                payload: { uploadForm: data.data },
              })
            : dispatch({
                type: UPLOAD_FORM_FAIL,
              });
          return Promise.resolve();
        },
        (error) => {
          console.log("error......", error);
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
  
          dispatch({
            type: UPLOAD_FORM_FAIL,
          });
  
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
  
          return Promise.reject();
        }
      )
      .catch((error) => {
        console.log(error);
      });
  };

  export const deleteForm = (id) => async (dispatch) => {
    dispatch({
      type: DELETE_FORM_PENDING,
      payload: { deleteForm: "isLoading" },
    });
    return await UserService.deleteForm(id)
      .then(
      (data) => {
        if (data.data.status) {
          dispatch({
            type: DELETE_FORM_SUCCESS,
            payload: { id: id },
          });
          toast.success(data.data.message, {autoClose: 2000 });
        
        } else {
          dispatch({
            type: DELETE_FORM_FAIL,
          });
          toast.error("Failed to delete form", {
            autoClose: 2000,
          });
        }

          return Promise.resolve();
        },
        (error) => {
          console.log("error......", error);
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
  
          dispatch({
            type: DELETE_FORM_FAIL,
          });
  
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
  
          return Promise.reject();
        }
      )
      .catch((error) => {
        console.log(error);
      });
  };
  