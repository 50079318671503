import React, { useState, useEffect } from "react";
import "../locate/Locate.css";
import Loader from "../subComponents/Loader.jsx";
import { getFirestore, collection, getDocs,onSnapshot  } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';
import { getEmployeeSubOrdinates } from "../../actions/userActions/employeeAction.js";
import { GOOGLE_MAP_API_KEY } from "../../Constatns";
import MyMap from "../gMap/MyMap";

const Locate = () => {
  const db = getFirestore();
  const colRef = collection(db, "Location");
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const employeeId = user?.result[0]?.user?.id || false;

  const [locations, setLocations] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [availableEmployees, setAvailableEmployees] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
  const [mapZoom, setMapZoom] = useState(2);
  const [loadMap, setLoadMap] = useState(false);
  const employees = useSelector(
    (state) => state.employees_reducer.employeeSubordinates
  );
  

  useEffect(() => {
    const unsubscribe = onSnapshot(colRef, (snapshot) => {
      const locs = snapshot.docs.map((doc) => ({ ...doc.data() }));
      setLocations([...locs]);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    dispatch(getEmployeeSubOrdinates(employeeId));
  }, [dispatch, employeeId]);

  useEffect(() => {
    if (Array.isArray(employees) && employees.length > 0) {
      setAvailableEmployees(employees);
    }
  }, [employees]);

  useEffect(() => {
    if (Array.isArray(locations) && locations.length > 0) {
      const employeeMarkers = selectedEmployees
        .map((employee) => {
          const location = locations.find((loc) => loc.id === employee.id);
          return location
            ? {
                designation: location.designation,
                email: location.email,
                title: `${location.first_name} ${location.last_name}`,
                id: location.id,
                lat: parseFloat(location.latitude),
                lng: parseFloat(location.longitude),
                time_stamp: moment
                  .unix(location.time_stamp.seconds)
                  .format("MMMM Do YYYY, h:mm:ss a"),
                reportsTo: location.reports_to,
              }
            : null;
        })
        .filter(Boolean);

      setMarkers(employeeMarkers);
      if (employeeMarkers.length > 0) {
        const avgLat = employeeMarkers.reduce(
          (sum, marker) => sum + marker.lat,
          0
        ) / employeeMarkers.length;

        const avgLng = employeeMarkers.reduce(
          (sum, marker) => sum + marker.lng,
          0
        ) / employeeMarkers.length;

        setMapCenter({ lat: avgLat, lng: avgLng });
        setMapZoom(7);
      } else {
        setMapZoom(2);
      }
    }
  }, [locations, selectedEmployees]);

  const handleEmployeeChange = (event, newValue) => {
    setSelectedEmployees(newValue);

    const updatedAvailableEmployees = Array.isArray(employees)
      ? employees.filter(
          (employee) =>
            !newValue.some((selected) => selected.id === employee.id)
        )
      : [];
    setAvailableEmployees(updatedAvailableEmployees);
  };

  const loadGoogleMapScript = (callback) => {
    if (
      typeof window.google === "object" &&
      typeof window.google.maps === "object"
    ) {
      callback();
    } else {
      const googleMapScript = document.createElement("script");
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}`;
      window.document.body.appendChild(googleMapScript);
      googleMapScript.addEventListener("load", callback);
    }
  };

  useEffect(() => {
    loadGoogleMapScript(() => {
      setLoadMap(true);
    });
  }, []);

 
  

  return (
    <>
      <div className="row">
        <div className="col-12">
          {employees && 
          <Autocomplete
          multiple
          limitTags={5}
          value={selectedEmployees}
          options={Array.isArray(employees)&&employees.sort((a, b) => a.firstName.localeCompare(b.firstName))}
          getOptionLabel={(option) =>
            `${option.firstName} ${option.lastName}`
          }
          getOptionKey={(option)=>option.id}
          onChange={handleEmployeeChange}
          renderInput={(params) => (
            <TextField {...params} label="Employees *" />
          )}
          sx={{ width: "100%" }}
        />}

          <br />
        </div>
      </div>
      <div className="locate-map-container" id="map">
        {!loadMap ? (
          <div className="mt-40">
            <Loader colored={true} />
          </div>
        ) : (
          <MyMap markerList={markers} mapCenter={mapCenter} mapZoom={mapZoom} />
        )}
      </div>
    </>
  );
};

export default Locate;