import React from 'react'

const HrComponent = () => {
  return (
    <>
    <hr
                        style={{
                            marginLeft: "14px",
                            backgroundColor: "rgba(0, 0, 0, 0.12)",
                            height: "1.3px",
                            marginTop: "15px",
                            width: "97%",
                        }}
                    />
    </>
  )
}

export default HrComponent