import { toast } from "react-toastify";
import userService from "../../services/user.service";
import { GENERATE_REPORT_FAIL, GENERATE_REPORT_PENDING, GENERATE_REPORT_SUCCESS } from "../types";

export const generateReport = (body) => async(dispatch) => {
   
    dispatch({
        type: GENERATE_REPORT_PENDING,
        payload: { report: "isLoading" },
    });
    return await userService.generateReport(body)
    .then(
        (data) => {
          data.data.status
            ? toast.success("DOWNLOADED the file Successfully !", { autoClose: 2000 })
            : toast.error(data.data.message, { autoClose: 2000 });
          data.data.status
            ? dispatch({
                type: GENERATE_REPORT_SUCCESS,
                payload: { report: data.data.status },
              })
            : dispatch({
                type: GENERATE_REPORT_FAIL,
                payload: { report: data.data.status },
              });
  
          return Promise.resolve();
        },
        (error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: GENERATE_REPORT_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        console.log(error, "error in generate report");
        
        return Promise.reject();
    });
};