import {
    TASK_TYPE_SUCCESS,
    TASK_TYPE_FAIL,
    TASK_TYPE_PENDING,
    ADD_TASK_TYPE_SUCCESS,
    ADD_TASK_TYPE_FAIL,
    ADD_TASK_TYPE_PENDING,
    UPDATE_TASK_TYPE_SUCCESS,
    UPDATE_TASK_TYPE_FAIL,
    UPDATE_TASK_TYPE_PENDING
} from "../../actions/types";

const initialState = { 
    taskTypes: [],
    addTaskTypes :'',
    updateTaskTypes : ''
    };

const task_types_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case TASK_TYPE_SUCCESS:
            return {
                ...state,
                taskTypes: payload.taskTypes,
            };
        case TASK_TYPE_PENDING:
            return {
                ...state,
                taskTypes: payload.taskTypes,
            };
        case TASK_TYPE_FAIL:
            return {
                ...state,
                taskTypes: []
            };
        case ADD_TASK_TYPE_SUCCESS:
            return {
                ...state,
                addTaskTypes: payload.addTaskTypes,
            };
        case ADD_TASK_TYPE_PENDING:
            return {
                ...state,
                addTaskTypes: payload.addTaskTypes,
            };
        case ADD_TASK_TYPE_FAIL:
            return {
                ...state,
                addTaskTypes: false
            };
        case UPDATE_TASK_TYPE_SUCCESS:
                return {
                    ...state,
                    updateTaskTypes: payload.updateTaskTypes,
                };
        case UPDATE_TASK_TYPE_PENDING:
                return {
                    ...state,
                    updateTaskTypes: payload.updateTaskTypes,
                };
        case UPDATE_TASK_TYPE_FAIL:
                return {
                    ...state,
                    updateTaskTypes: false
                };
        default:
            return state;
    }
}
export default task_types_reducer