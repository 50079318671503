import {
  STOCK_SUCCESS,
  STOCK_FAIL,
  SET_MESSAGE,
  STOCK_PENDING,
  ADD_STOCK_SUCCESS,
  ADD_STOCK_FAIL,
  ADD_STOCK_PENDING,
  UPDATE_STOCK_SUCCESS,
  UPDATE_STOCK_FAIL,
  UPDATE_STOCK_PENDING,
} from "../types";
import { toast } from "react-toastify";
import UserService from "../../services/user.service";

export const getStocks = (body) => async (dispatch) => {
  dispatch({
    type: STOCK_PENDING,
    payload: { stocks: "isLoading" },
  });
  return await UserService.getStocks(body)
    .then(
      (data) => {
        data.data.status
          ? dispatch({
              type: STOCK_SUCCESS,
              payload: { stocks: data.data.result[0] },
            })
          : dispatch({
              type: STOCK_FAIL,
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: STOCK_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};

//add stock
export const addStock = (body) => async (dispatch) => {
  dispatch({
    type: ADD_STOCK_PENDING,
    payload: { addStock: "isLoading" },
  });
  return await UserService.addStock(body)
    .then(
      (data) => {
        data.data.status
          ? toast.success("Stock added Successfully !", { autoClose: 2000 })
          : toast.error(data.data.message, { autoClose: 2000 });
        data.data.status
          ? dispatch({
              type: ADD_STOCK_SUCCESS,
              payload: { addStock: data.data.status },
            })
          : dispatch({
              type: ADD_STOCK_FAIL,
              payload: { addStock: data.data.status },
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: ADD_STOCK_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};
// Update stocks
export const updateStock = (body) => async (dispatch) => {
  dispatch({
    type: UPDATE_STOCK_PENDING,
    payload: { updateStock: "isLoading" },
  });
  return await UserService.updateStock(body)
    .then(
      (data) => {
        data.data.status
          ? toast.success("Stock updated Successfully !", { autoClose: 2000 })
          : toast.error(data.data.message, { autoClose: 2000 });
        data.data.status
          ? dispatch({
              type: UPDATE_STOCK_SUCCESS,
              payload: { updateStock: data.data.status },
            })
          : dispatch({
              type: UPDATE_STOCK_FAIL,
              payload: { updateStock: data.data.status },
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: UPDATE_STOCK_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};

export const nullUpdateStock = () => (dispatch) => {
  return dispatch({
    type: UPDATE_STOCK_PENDING,
    payload: { updateStock: "" },
  });
};
