import React from 'react'
import './Search.css'
import TextField from '@mui/material/TextField';

const ServerSideSearch = ({ placeholder, onChange, value }) => {
  return (
    <TextField
      sx={{
        width: 220,
        height: 40,
        '& .MuiOutlinedInput-root': {
          borderRadius: '2px',
        },
      }}
      type="text"
      placeholder="Search"
      onChange={onChange}
      value={value}
      variant="outlined"
      InputProps={{
        style: {
          height: '100%',
        },
      }}
    />
  );
};

export default ServerSideSearch;
