import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addProducts, deleteProduct, updateProducts } from '../../actions/userActions/productAction';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import TextField from "@mui/material/TextField";
import { SaveButton } from "../subComponents/buttons/SaveButton";
import { CancelButton } from "../subComponents/buttons/CancelButton";
import HrComponent from '../reuse_components/HrComponent';
import AddDocument from '../subComponents/buttons/AddDocument';
import Add_Document_Icon from '../../../src/assets/images/add_document_icon.svg';
import Excel_icon from "../../assets/images/Excel_icon.svg";
import Pdf_icon from "../../assets/images/Pdf_icon.svg";
import Word_icon from "../../assets/images/Word_icon.svg";
import Ppt_icon from "../../assets/images/Ppt_icon.svg";
import Access_icon from "../../assets/images/Access_icon.svg";
import Txt_icon from "../../assets/images/Txt_icon.svg";
import Folder_icon from "../../assets/images/Folder_icon.svg";
import { toast } from "react-toastify";
import './Product.css';
function AddProducts() {
    const dispatch = useDispatch();
    const location = useLocation();
    const fileInputRef = useRef(null);
    const history = useHistory();
    const initialValues = { name: "", description: "", active: false, file: [], urls: [] };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const addProduct = useSelector(state => state.products_reducer.addProducts);
    const updateProduct = useSelector(state => state.products_reducer.updateProducts);
    const permissions = JSON.parse(localStorage.getItem("permissionsList"));
    const getFileIcon = (fileName) => {
        const extension = fileName?.split('.').pop().toLowerCase();
        switch (extension) {
            case "pdf":
                return Pdf_icon;
            case "xls":
            case "xlsx":
                return Excel_icon;
            case "doc":
            case "docx":
                return Word_icon;
            case "ppt":
            case "pptx":
                return Ppt_icon;
            case "accdb":
                return Access_icon;
            case "txt":
                return Txt_icon;
            default:
                return Folder_icon;
        }
    };
    useEffect(() => {
        if (location.product) {
            setFormValues({
                ...formValues,
                ["name"]: location.product.row.name,
                ["description"]: location.product.row.description,
                ["id"]: location.id,
                ["active"]: location.product.row.active,
                ["urls"]: location.product.row.urls || []
            });
        }
    }, [location.product]);
    
    const addProductHandle = (e) => {
        e.preventDefault();
        const errors = validate(formValues);
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            const data = new FormData();
            data.append('name', formValues.name);
            data.append('description', formValues.description);
            data.append('active', formValues.active);
            formValues.file.forEach(file => data.append('file', file));
            if (location.product) {
                data.append('id', formValues.id);
                dispatch(updateProducts(data));
            } else {
                dispatch(addProducts(data));
            }
        }
    };
    const handleGoBack = () => {
        history.goBack();
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === "checkbox") {
            setFormValues(prevValues => ({
                ...prevValues,
                [name]: checked
            }));
        } else {
            setFormValues(prevValues => ({
                ...prevValues,
                [name]: value
            }));
        }
    };
    
    const getfileName = (text) => {
      const fileNameWithExtension = text?.split("forms/").pop();
      if (fileNameWithExtension) {
          const fileNameWithoutExtension = fileNameWithExtension.split('.').slice(0, -1).join('.');
          return fileNameWithoutExtension;
      }
      return ''; 
  };
  
    const handleUploadButtonClick = () => {
        fileInputRef.current.click();
    };
    const handleFileUpload = (e) => {
        const newFiles = e.target.files;
        const updatedFiles = [...formValues.file];
        const updatedUrls = [...formValues.urls];
        let filesAdded = false;
    
        const uniqueFiles = Array.from(newFiles).filter((file) => {
            const fileNamesInUrls = updatedUrls.map(url => url.split('/').pop());
            return !fileNamesInUrls.includes(file.name);
        });
    
        uniqueFiles.forEach((file) => {
            updatedFiles.push(file);
            updatedUrls.push(file.name); 
            filesAdded = true;
        });
    
        if (filesAdded) {
            setFormValues(prevValues => ({
                ...prevValues,
                file: updatedFiles,
                urls: updatedUrls, 
            }));
        }
    };
    
    const validate = (values) => {
        const errors = {};
        if (!values.name) errors.name = "Name is required!";
        if (!values.description) errors.description = "Description is required!";
        return errors;
    };

    const handleDeleteProduct = (id, fileName) => {
      const updatedFiles = formValues.file.filter((file) => {
        return !file.name.includes(fileName);
      });
      
      const updatedUrls = formValues.urls.filter((url) => {
          return !url.includes(fileName); 
      });
      setFormValues({
          ...formValues,
          file: updatedFiles,
          urls: updatedUrls,
      });
  
      dispatch(deleteProduct(id, fileName));
  };
  
  
    return (
        <>
            {addProduct && addProduct !== "isLoading" && <Redirect to="/admin/products" />}
            {updateProduct && updateProduct !== "isLoading" && <Redirect to="/admin/products" />}
            {!permissions.includes("CanAddProduct") && <Redirect to="/admin" />}
            {!permissions.includes("CanUpdateProduct") && <Redirect to="/admin" />}
            <div className="product-form-container">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="d-inline-block float-left">
                            <i onClick={history.goBack} className="fas fa-arrow-left" aria-hidden="true" style={{ cursor: "pointer" }} />
                            {location.product ? "Update Product" : "Add Product"}
                        </h2>
                    </div>
                </div>
                <HrComponent />
                <div className="row">
                    <div className="col-md-12 mt-5">
                        <TextField
                            fullWidth
                            label="Name *"
                            name="name"
                            value={formValues.name}
                            onChange={handleChange}
                            error={!!formErrors.name}
                            helperText={formErrors.name}
                        />
                    </div>
                    <div className="col-md-12 mt-5">
                        <TextField
                            fullWidth
                            multiline
                            rows={3}
                            label="Description *"
                            name="description"
                            value={formValues.description}
                            onChange={handleChange}
                            error={!!formErrors.description}
                            helperText={formErrors.description}
                        />
                    </div>
                    
                    <div className="col-md-9 mt-5">
                        <h6>Attached Documents</h6>
                        {Array.isArray(formValues.urls) && formValues.urls.filter(url => url.trim() !== "").length > 0 ? (
    <div className="document-grid">
        {formValues.urls.map((file, index) => (
            file.trim() !== "" && (
                <div key={index} className="add_document">
                    <img src={getFileIcon(file)} alt="File Type Icon" style={{ width: "32px", height: "32px" }} />
                    <p className="filename">{getfileName(file)}</p>
                    <svg
                        onClick={() => {
                            handleDeleteProduct(formValues.id, getfileName(file)); 
                        }}
                        className="trash-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <polyline points="3 6 5 6 21 6"></polyline>
                        <path d="M19 6l-1 14a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2L5 6"></path>
                        <path d="M10 11v6"></path>
                        <path d="M14 11v6"></path>
                        <path d="M9 6V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v2"></path>
                    </svg>
                </div>
            )
        ))}
    </div>
) : (
    <p className="no-files-text">No files attached</p>
)}

                    </div>
                    <div className="col-md-3 mt-5" style={{ paddingLeft: "53px" }}>
                        <AddDocument
                            text={<><img src={Add_Document_Icon} alt="Add Document Icon" /> Add Documents</>}
                            onClick={handleUploadButtonClick}
                        />
                    </div>
                    <div className="col-md-12 mt-5">
                        <div className="form-group" style={{ display: "flex", alignItems: "flex-start" }}>
                            <input
                                name="active"
                                type="checkbox"
                                checked={formValues.active}
                                style={{ cursor: "pointer", height: "16px", width: "16px" }}
                                onChange={handleChange}
                            />
                            <label style={{ paddingLeft: "10px" }} className="form-control-label">Is this product active?</label>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-5" style={{ display: "flex", justifyContent: "flex-end", gap: "20px" }}>
                        <CancelButton onClick={handleGoBack} />
                        <SaveButton
                            onClick={addProductHandle}
                            isLoading={addProduct === "isLoading" || updateProduct === "isLoading"}
                            text={location.product ? "Update" : "Save"}
                        />
                    </div>
                </div>
            </div>
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileUpload}
                accept=".pdf, .xls, .xlsx, .doc, .docx, .ppt, .pptx, .txt, .accdb"
                multiple
            />
        </>
    );
}
export default AddProducts;
