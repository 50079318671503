import {
    STORE_TYPE_SUCCESS,
    STORE_TYPE_FAIL,
    STORE_TYPE_PENDING,
    ADD_STORE_TYPE_SUCCESS,
    ADD_STORE_TYPE_FAIL,
    ADD_STORE_TYPE_PENDING,
    UPDATE_STORE_TYPE_SUCCESS,
    UPDATE_STORE_TYPE_FAIL,
    UPDATE_STORE_TYPE_PENDING,
} from "../../actions/types";

const initialState = { 
    storeTypes: [],
    addStoreTypes : '',
    updateStoreType : '',
    };

const store_types_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case STORE_TYPE_SUCCESS:
            return {
                ...state,
                store_types: payload.storeTypes,
            };
        case STORE_TYPE_PENDING:
            return {
                ...state,
                store_types: payload.storeTypes,
            };
        case STORE_TYPE_FAIL:
            return {
                ...state,
                store_types: []
            };
        case ADD_STORE_TYPE_SUCCESS:
            return {
                ...state,
                addStoreTypes: payload.addStoreTypes,
            };
        case ADD_STORE_TYPE_PENDING:
            return {
                ...state,
                addStoreTypes: payload.addStoreTypes,
            };
        case ADD_STORE_TYPE_FAIL:
            return {
                ...state,
                addStoreTypes: false
            };
            case UPDATE_STORE_TYPE_SUCCESS:
                return {
                    ...state,
                    updateStoreType: payload.updateStoreType,
                };
            case UPDATE_STORE_TYPE_PENDING:
                return {
                    ...state,
                    updateStoreType: payload.updateStoreType,
                };
            case UPDATE_STORE_TYPE_FAIL:
                return {
                    ...state,
                    updateStoreType: false
                };
        default:
            return state;
    }
}
export default store_types_reducer