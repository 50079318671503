import {
    CALL_AVERAGE_PRO_SUCCESS,
    CALL_AVERAGE_PRO_FAIL,
    SET_MESSAGE,
    CALL_AVERAGE_PRO_PENDING,
  } from "../types";
  import UserService from "../../services/user.service";
  import { toast } from "react-toastify";
  
  export const getCallAveragProduct = (body) => async (dispatch) => {
    dispatch({
      type: CALL_AVERAGE_PRO_PENDING,
      payload: { callAvgPro: "isLoading" },
    });
    return await UserService.getCallAvgPro(body)
      .then(
        (data) => {
          console.log(data);
          data.data.status
            ? dispatch({
                type: CALL_AVERAGE_PRO_SUCCESS,
                payload: { callAvgPro: data.data.result[0].byProducts },
              })
            : dispatch({
                type: CALL_AVERAGE_PRO_FAIL,
              });
          return Promise.resolve();
        },
        (error) => {
          console.log("error......", error);
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
  
          dispatch({
            type: CALL_AVERAGE_PRO_FAIL,
          });
  
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
  
          return Promise.reject();
        }
      )
      .catch((error) => {
        console.log(error);
      });
  };
  