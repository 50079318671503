import { Button } from '@mui/material';
import React from 'react';

export const ResetButton = ({ onClick }) => {
    return (
        <Button
            onClick={onClick}
            style={{
                width: "75px",
                height: "32px",
                padding: "4px 14px 4px 14px",
                gap: "10px",
                borderRadius: "4px",
                background: "#FFFFFF",
                boxShadow: "2px #00000004",
                border: "1px solid #D9D9D9",
                color: "#000000D9",
                borderColor: "#505157"
            }}
            onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = "#FFFFFF";
                e.currentTarget.style.color = "#004BB4";
                e.currentTarget.style.borderColor = "#004BB4";
            }}
            onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = "#FFFFFF";
                e.currentTarget.style.color = "#000000DE";
                e.currentTarget.style.borderColor = "#D9D9D9";
            }}
        >
            Reset
        </Button>
    );
};

export default ResetButton;