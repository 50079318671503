import {
    TASK_STATUS_SUCCESS,
    TASK_STATUS_FAIL,
    TASK_STATUS_PENDING
} from "../../actions/types";

const initialState = { 
    taskStatuses: [],
    };

const task_statuses_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case TASK_STATUS_SUCCESS:
            return {
                ...state,
                taskStatuses: payload.taskStatuses,
            };
        case TASK_STATUS_PENDING:
            return {
                ...state,
                taskStatuses: payload.taskStatuses,
            };
        case TASK_STATUS_FAIL:
            return {
                ...state,
                taskStatuses: []
            };
        default:
            return state;
    }
}
export default task_statuses_reducer