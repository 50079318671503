import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getStocks } from '../../actions/userActions/stockAction'
import InnerHeader from '../InnerHeader/InnderHeader'
import AUModal from './AUStockModal'
import TableComponent from '../subComponents/table/TableComponent'
import { CircularProgress } from '@mui/material'
import ProductblueIcon from '../../assets/images/product__blue_icon.svg'
import StoreBlueIcon from '../../assets/images/store_blue_icon.svg'
import QuantityBlueIcon from '../../assets/images/quantity_blue_icon.svg'
import UnitTypeBlueIcon from '../../assets/images/unit_type_blue_icon.svg'
import CalendarBlueIcon from '../../assets/images/calendar_blue_icon.svg'
// import StockBlueIcon from '../../assets/images/stock_blue_icon.svg'
// import StockTypeBlueIcon from '../../assets/images/stock_type_blue_icon.svg'
import LocationBlueIcon from '../../assets/images/location_blue_icon.svg'
import EmployeeBlueIcon from '../../assets/images/employee_icon.svg'
import CallBlueIcon from '../../assets/images/call_blue_icon.svg'
import StatusblueIcon from '../../assets/images/status_blue_icon.svg'
import ViewModal from '../subComponents/modals/ViewModal'
import DeleteModal from '../subComponents/modals/DeleteModal'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css'
import Pagination from '../paginatioin/pagination'
import moment from 'moment'
import Stores from '../stores/Stores'
import Products from '../products/Products'
import Select from 'react-select'
import { getProducts } from '../../actions/userActions/productAction'
import { getStores } from '../../actions/userActions/storeAction'
import { get } from 'jquery'

function Stocks() {
  const currentDate = moment()
  const [stock, setStock] = useState()

  const [openAdd, setOpenAdd] = useState(false)
  const handleOpenAdd = () => setOpenAdd(true)
  const handleCloseAdd = () => setOpenAdd(false)

  const [openEdit, setOpenEdit] = useState(false)
  const handleOpenEdit = () => setOpenEdit(true)
  const handleCloseEdit = () => {
    setOpenEdit(false)
    setStock(null)
  }

  const [openView, setOpenView] = useState(false)
  const handleOpenView = () => {
    setOpenView(true)
  }
  const handleCloseView = () => {
    setOpenView(false)
    setStock(null)
  }

  const [openDelete, setOpenDelete] = useState(false)
  const handleOpenDelete = () => setOpenDelete(true)
  const handleCloseDelete = () => setOpenDelete(false)

  const dispatch = useDispatch()
  const addStocks = useSelector((state) => state.stocks_reducer.addStock)
  const updateStocks = useSelector((state) => state.stocks_reducer.updateStock)


  const permissions = JSON.parse(localStorage.getItem('permissionsList'))
  const [query, setQuery] = useState('')
  const [page, setPage] = useState(1)

  const [Filters, setFilters] = useState({
    textSearch: '',
    product: [],
    store: [],
    quantity: [],
    fromDate: moment(currentDate).subtract(7, 'days').format('YYYY-MM-DD'),
    toDate: moment(currentDate).format('YYYY-MM-DD'),
  })
  const [selected, setSelected] = useState({
    textSearch: '',
    isActive: true,
  })
  const body = {
    filter: Filters,
    pageNumber: page,
    pageSize: 10,
  }

  const handleSearch = (e) => {
    setSelected({ ...selected, ['textSearch']: e.target.value })
    if (e.target.value.length >= 4) {
      setTimeout(() => {
        setFilters({ ...Filters, ['textSearch']: e.target.value })
      }, 500)
    }
    if (e.target.value == '') {
      setFilters({ ...Filters, ['textSearch']: e.target.value })
    }
  }

  // store body
  const storeBody = {
    "filter": {
      "isActive": true,
      "textSearch": "",
      "type": [],
      "state": [],
      "city": [],
      "area": [],
      "block": []
    },
    "pageNumber": -1,
    "pageSize": 0
  }
  const productBody = {
    "filter": {
      "textSearch": ""
    },
    "pageNumber": -1,
    "pageSize": 0
  }
  useEffect(() => {
    dispatch(getStocks(body))
    dispatch(getProducts(productBody))
    dispatch(getStores(storeBody))
    
  }, [addStocks, updateStocks, page, Filters])

  const getOptions = (data, name, activecheck) => {
    const getData =
      data && data !== 'isLoading'
        ? !activecheck
          ? data.map((row, i) => ({
              value: row.id,
              label: row.name
                ? row.name
                : row.firstName && row.lastName
                ? `${row.firstName} ${row.lastName}`
                : row.address,
              name: name,
            }))
          : data
              .filter((row) => row.active === true)
              .map((row, i) => ({
                value: row.id,
                label: row.name
                  ? row.name
                  : row.firstName && row.lastName
                  ? `${row.firstName} ${row.lastName}`
                  : row.address,
                name: name,
              }))
        : []

    return getData
  }



  const getPaginationNo = (pageNum) => {
    const object = []
    if (stocks !== 'isLoading' && stocks.length !== 0) {
      for (let i = 0; i < stocks.page.totalPages; i++) {
        object.push(
          <button
            className={pageNum == i + 1 ? 'active' : ''}
            onClick={() => setPage(i + 1)}
          >
            {i + 1}
          </button>,
        )
      }
    }
    return object
  }

  const handleChange = (e, name) => {
    if (name == 'reset') {
      setSelected({
        ...Filters,

        textSearch: '',
        product: [],
        store: [],

      })
      setFilters({
        ...Filters,
        textSearch: '',
        product: [],
        store: [],
        
      })
    } else {
      setFilters({ ...Filters, [name]: e.map((row, i) => row.value) })
      setSelected({
        ...selected,
        [name]: e.map((row, i) => ({
          value: row.value,
          label: row.label,
          name: row.name,
        })),
      })
    }
  }

  const dateChange = (start, end) => {
    let startDate = start.format('YYYY-MM-DD')
    let endDate = end.format('YYYY-MM-DD')
    setFilters({
      ...Filters,
      ['fromDate']: startDate,
      ['toDate']: endDate,
    })
  }
  const stocks = useSelector((state) => state.stocks_reducer.stocks)
  const stores = useSelector((state) => state.stores_reducer.stores)
  const products = useSelector((state) => state.products_reducer.products)
  

  return (
    <>
      <div className="container mt-5">
        <InnerHeader
          handleSearch={handleSearch}
          value={selected.textSearch}
          serverSideSearch={true}
          title="Stocks"
          description="Manage all your stocks or add new one"
          searchPlaceholder="Search stock"
          permission={permissions.includes('CanAddStock')}
          buttonTitle="+ Add Stock"
          onClick={handleOpenAdd}
        />
        
        <div className="container mt-5">
          <div className="row mt-4">

          <div  className="col-2">
          <h4>Filter Result</h4>
          </div>

          <div className='col-3'>
            <Select
              value={selected.products}
              isSearchable
              isMulti
              options={getOptions(products.products,"products", true)}
              name='product'
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select Product"
              onChange={(e) => handleChange(e, 'product')}
            
            />
          </div>

          <div className='col-3'>
            <Select
              value={selected.stores}
              isSearchable
              isMulti
              options={getOptions(stores.stores,"stores", true)}
              name='store'
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select Store"
              onChange={(e) => handleChange(e, 'store')}
            
            />
          </div>


          <div className="col-2">
          <DateRangePicker
                  initialSettings={{
                    startDate: moment().subtract(6, 'days').toDate(),
                    endDate: moment().toDate(),
                    ranges: {
                      Today: [moment().toDate(), moment().toDate()],
                      Yesterday: [
                        moment().subtract(1, 'days').toDate(),
                        moment().subtract(1, 'days').toDate(),
                      ],
                      'Last 7 Days': [
                        moment().subtract(6, 'days').toDate(),
                        moment().toDate(),
                      ],
                      'Last 30 Days': [
                        moment().subtract(29, 'days').toDate(),
                        moment().toDate(),
                      ],
                      'This Month': [
                        moment().startOf('month').toDate(),
                        moment().endOf('month').toDate(),
                      ],
                      'Last Month': [
                        moment().subtract(1, 'month').startOf('month').toDate(),
                        moment().subtract(1, 'month').endOf('month').toDate(),
                      ],
                      'Last Year': [
                        moment().subtract(1, 'year').startOf('year').toDate(),
                        moment().subtract(1, 'year').endOf('year').toDate(),
                      ],
                    },
                  }}
                  onCallback={dateChange}
                
                >
                  {/* <input type="text" className="form-control" placeholder='Select Date Range' /> */}
                  <button className="btn btn-primary float-right">
                    Date Range
                  </button>
                </DateRangePicker>

          </div>

          <div className="col-2">
            <button
              className="btn btn-primary d-block m-auto task-filter-reset-btn"
              onClick={(e) => handleChange(e, 'reset')}
            >
              {' '}
              Reset filter
            </button>
          </div>

          </div>
          
         

        </div>

        <div className="row">
          <div className="col-md-12">
            {stocks === 'isLoading' && (
              <div style={{ height: '70vh' }}>
                <div className="d-flex my-5" style={{ height: '100%' }}>
                  <CircularProgress
                    sx={{ margin: 'auto', color: 'rgba(0, 45, 114, 1)' }}
                  />
                </div>
              </div>
            )}
            {stocks && stocks !== 'isLoading' && stocks.stocks && (
              <>
                <TableComponent
                  data={stocks.stocks}
                  headers={['Store', 'Product', 'Quantity', 'Date']}
                  keys={[
                    'storeName',
                    'productName',
                    'quantityWithType',
                    'recordDate',
                  ]}
                  doubleTitle={true}
                  descriptionKeys={['storeLocation']}
                  setData={setStock}
                  onEyeClick={handleOpenView}
                  onEditClick={handleOpenEdit}
                  onDeleteClick={handleOpenDelete}
                />
                {stocks !== 'isLoading' && (
                  <div className="task-pagination-wrapper">
                    <Pagination
                      total={
                        stocks.page != undefined &&
                        stocks.page.totalPages * stocks.page.pageSize
                      }
                      current={
                        stocks.page != undefined && stocks.page.pageNumber
                      }
                      pageSize={
                        stocks.page != undefined && stocks.page.pageSize
                      }
                      onChange={(current) => setPage(current)}
                      showLessItems
                    />
                  </div>
                )}
                {/* <div className="row">
                  <div className="col-12">
                    <div className="task-pagination-wrapper">
                      {getPaginationNo(page)}
                    </div>
                  </div>
                </div> */}
              </>
            )}
          </div>
        </div>
      </div>
     


      <DeleteModal
        title="Delete Stock "
        type="stock"
        open={openDelete}
        onClose={handleCloseDelete}
      />
      {stock && (
        <ViewModal
          title="Stock"
          headings={[
            'Product',
            'Store',
            'Store Location',
            'Quantity',
            'Unit Type',
            'Date of record',
            'Added By',
          ]}
          keys={[
            'productName',
            'storeName',
            'storeLocation',
            'quantity',
            'unitName',
            'recordDate',
            'createdByName',
          ]}
          icons={[
            ProductblueIcon,
            StoreBlueIcon,
            LocationBlueIcon,
            QuantityBlueIcon,
            UnitTypeBlueIcon,
            CalendarBlueIcon,
            EmployeeBlueIcon,
          ]}
          data={stock}
          open={openView}
          onClose={handleCloseView}
        />
      )}
      <AUModal
        type="add"
        onViewClose={handleCloseView}
        open={openAdd}
        onClose={handleCloseAdd}
      />
      {stock && (
        <AUModal
          type="update"
          onViewClose={handleCloseView}
          stock={stock}
          open={openEdit}
          onClose={handleCloseEdit}
        />
      )}
    </>
  )
}

export default Stocks
