import {
  TASK_SUCCESS,
  TASK_FAIL,
  SET_MESSAGE,
  TASK_PENDING,
  TASK_DOCTOR_SUCCESS,
  TASK_DOCTOR_FAIL,
  TASK_DOCTOR_PENDING,
  TASK_DETAIL_SUCCESS,
  TASK_DETAIL_FAIL,
  TASK_DETAIL_PENDING,
  TASK_CITY_SUCCESS,
  TASK_CITY_FAIL,
  TASK_CITY_PENDING,
  TASK_PRACTICE_LOCATION_SUCCESS,
  TASK_PRACTICE_LOCATION_FAIL,
  TASK_PRACTICE_LOCATION_PENDING,
  TASK_PRODUCT_SUCCESS,
  TASK_PRODUCT_FAIL,
  TASK_PRODUCT_PENDING,
  TASK_SUB_ORDINATE_SUCCESS,
  TASK_SUB_ORDINATE_FAIL,
  TASK_SUB_ORDINATE_PENDING,
  TASK_SUB_TASK_SUCCESS,
  TASK_SUB_TASK_FAIL,
  TASK_SUB_TASK_PENDING,
  TASK_PRIORITY_SUCCESS,
  TASK_PRIORITY_FAIL,
  TASK_PRIORITY_PENDING,
  TASK_STATUS_SUCCESS,
  TASK_STATUS_FAIL,
  TASK_STATUS_PENDING,
  TASK_TYPE_SUCCESS,
  TASK_TYPE_FAIL,
  TASK_TYPE_PENDING,
  ADD_TASK_SUCCESS,
  ADD_TASK_FAIL,
  ADD_TASK_PENDING,
  TASK_NOTE_SUCCESS,
  TASK_NOTE_PENDING,
  TASK_NOTE_FAIL,
  ADD_TASK_NOTE_SUCCESS,
  ADD_TASK_NOTE_PENDING,
  ADD_TASK_NOTE_FAIL,
  SUB_ORDINATES_PENDING,
  SUB_ORDINATES_SUCCESS,
  SUB_ORDINATES_FAIL,
  UPDATE_SUBTASK_PENDING,
  UPDATE_SUBTASK_SUCCESS,
  UPDATE_SUBTASK_FAIL,
} from "../../types";
import TaskService from "../../../services/task.service";
import { toast } from "react-toastify";

export const getTasks = (body) => async (dispatch) => {
  
  dispatch({
    type: TASK_PENDING,
    payload: { tasks: "isLoading" },
  });
  return await TaskService.getTasks(body)
    .then(
      (data) => {
        console.log('task data',data);
        data.data.status
          ? dispatch({
              type: TASK_SUCCESS,
              payload: { tasks: data.data.result[0] },
            })
          : dispatch({
              type: TASK_FAIL,
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: TASK_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};


export const addTaskNotes = (noteBody) => async (dispatch) => {
  dispatch({
    type: ADD_TASK_NOTE_PENDING,
    payload: { addTasksNotes: "isLoading" },
  });

  try {

    const response = await TaskService.addTaskNotes(noteBody);


    if (response.data.message) {
      toast.success("Task added Successfully!", { autoClose: 2000 });

      dispatch({
        type: ADD_TASK_NOTE_SUCCESS,
        payload: { addTasksNotes: response.data.status },
      });
    } else {
      toast.error(response.data.status === 'False' || 'Failed to add task', { autoClose: 2000 });

      dispatch({
        type: ADD_TASK_NOTE_FAIL,
        payload: { addTasksNotes: response.data.status },
      });
    }

    return Promise.resolve();
  } catch (error) {
    console.error("API Error:", error);

    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();

    dispatch({
      type: ADD_TASK_NOTE_FAIL,
    });

    dispatch({
      type: SET_MESSAGE,
      payload: message,
    });

    return Promise.reject();
  }
};


export const getTaskDetails = (id) => async (dispatch) => {
  dispatch({
    type: TASK_DETAIL_PENDING,
    payload: { taskDetails: "isLoading" },
  });
  return await TaskService.getTaskDetails(id)
    .then(
      (data) => {
        data.data.status
          ? dispatch({
              type: TASK_DETAIL_SUCCESS,
              payload: { taskDetails: data.data.result[0] },
            })
          : dispatch({
              type: TASK_DETAIL_FAIL,
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: TASK_DETAIL_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};

// export const getTaskSubOrdinates = (id) => async (dispatch) => {
//   dispatch({
//     type: SUB_ORDINATES_PENDING,
//     payload: { subOrdinates: "isLoading" },
//   });
//   return await TaskService.getTaskSubOrdinates(id)
//     .then(
//       (data) => {
//         console.log(data, "sub ordinates data");
//         data.data.status
//           ? dispatch({
//               type: SUB_ORDINATES_SUCCESS,
//               payload: { subOrdinates: data.data.result[0] },
//             })
//           : dispatch({
//               type: SUB_ORDINATES_FAIL,
//             });
//         return Promise.resolve();
//       },
//       (error) => {
//         console.log("error......", error);
//         const message =
//           (error.response &&
//             error.response.data &&
//             error.response.data.message) ||
//           error.message ||
//           error.toString();

//         dispatch({
//           type: SUB_ORDINATES_FAIL,
//         });

//         dispatch({
//           type: SET_MESSAGE,
//           payload: message,
//         });

//         return Promise.reject();
//       }
//     )
//     .catch((error) => {
//       console.log(error);
//     });
// };



export const getTaskNotes = (id) => async (dispatch) => {
  dispatch({
    type: TASK_NOTE_PENDING,
    payload: { taskNotes: "isLoading" },
  });
  return await TaskService.getTaskNotes(id)
    .then(
      (data) => {
        data.data.status
          ? dispatch({
              type: TASK_NOTE_SUCCESS,
              payload: { taskNotes: data.data.result },
            })
          : dispatch({
              type: TASK_NOTE_FAIL,
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: TASK_NOTE_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};

export const getTaskCities = () => async (dispatch) => {
  dispatch({
    type: TASK_CITY_PENDING,
    payload: { taskCities: "isLoading" },
  });
  return await TaskService.getTaskCities()
    .then(
      (data) => {
        data.data.status
          ? dispatch({
              type: TASK_CITY_SUCCESS,
              payload: { taskCities: data.data.result },
            })
          : dispatch({
              type: TASK_CITY_FAIL,
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: TASK_CITY_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};

export const getTaskDoctors = (body) => async (dispatch) => {
  dispatch({
    type: TASK_DOCTOR_PENDING,
    payload: { taskDoctors: "isLoading" },
  });
  return await TaskService.getTaskDoctors(body)
    .then(
      (data) => {
        data.data.status
          ? dispatch({
              type: TASK_DOCTOR_SUCCESS,
              payload: { taskDoctors: data.data.result },
            })
          : dispatch({
              type: TASK_DOCTOR_FAIL,
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: TASK_DOCTOR_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};

export const getTaskPracticeLocations = (id) => async (dispatch) => {
  dispatch({
    type: TASK_PRACTICE_LOCATION_PENDING,
    payload: { taskPracticeLocations: "isLoading" },
    
  });
  return await TaskService.getTaskPracticeLocations(id)
    .then(
      (data) => {
        data.data.status
          ? dispatch({
              type: TASK_PRACTICE_LOCATION_SUCCESS,
              payload: { taskPracticeLocations: data.data.result},
            })
          : dispatch({
              type: TASK_PRACTICE_LOCATION_FAIL,
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: TASK_PRACTICE_LOCATION_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};

export const getTaskProducts = (body) => async (dispatch) => {
  dispatch({
    type: TASK_PRODUCT_PENDING,
    payload: { taskProducts: "isLoading" },
  });
  return await TaskService.getTaskProducts(body)
    .then(
      (data) => {
        data.data.status
          ? dispatch({
              type: TASK_PRODUCT_SUCCESS,
              payload: { taskProducts: data.data.result },
            })
          : dispatch({
              type: TASK_PRODUCT_FAIL,
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: TASK_PRODUCT_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};


//

export const updateSubTask = (body) => async (dispatch) => {
  dispatch({
    type: UPDATE_SUBTASK_PENDING,
    payload: { updateTask: "isLoading" },
  });
  return await TaskService.updateSubTask(body)
    .then(
      (data) => {
        data.data.status
          ? dispatch({
              type: UPDATE_SUBTASK_SUCCESS,
              payload: { updateTask: data.data.result },
            })
          : dispatch({
              type: UPDATE_SUBTASK_FAIL,
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: UPDATE_SUBTASK_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};

export const getTaskSubOrdinates = (id) => async (dispatch) => {
  dispatch({
    type: TASK_SUB_ORDINATE_PENDING,
    payload: { taskSubOrdinates: "isLoading" },
  });
  return await TaskService.getTaskSubOrdinates(id)
    .then(
      (data) => {
        data.data.status
          ? dispatch({
              type: TASK_SUB_ORDINATE_SUCCESS,
              payload: { taskSubOrdinates: data.data.result },
            })
          : dispatch({
              type: TASK_SUB_ORDINATE_FAIL,
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: TASK_SUB_ORDINATE_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};




//


export const getSubOrdinates = (id) => async (dispatch) => {
  dispatch({
    type: SUB_ORDINATES_PENDING,
    payload: { subOrdinates: "isLoading" },
  });
  return await TaskService.getSubOrdinates(id)
    .then(
      (data) => {
        console.log(data, "Subordinates data");
        data.data.status
          ? dispatch({
              type: SUB_ORDINATES_SUCCESS,
              payload: { subOrdinates: data.data.result },
            })
          : dispatch({
              type: SUB_ORDINATES_FAIL,
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: SUB_ORDINATES_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};








export const getTaskSubTasks = () => async (dispatch) => {
  dispatch({
    type: TASK_SUB_TASK_PENDING,
    payload: { taskSubTasks: "isLoading" },
  });
  return await TaskService.getTaskSubTasks()
    .then(
      (data) => {
        data.data.status
          ? dispatch({
              type: TASK_SUB_TASK_SUCCESS,
              payload: { taskSubTasks: data.data.result },
            })
          : dispatch({
              type: TASK_SUB_TASK_FAIL,
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: TASK_SUB_TASK_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};



export const getTaskStatuses = () => async (dispatch) => {
  dispatch({
    type: TASK_STATUS_PENDING,
    payload: { taskStatuses: "isLoading" },
  });
  return await TaskService.getTaskStatuses()
    .then(
      (data) => {
        data.data.status
          ? dispatch({
              type: TASK_STATUS_SUCCESS,
              payload: { taskStatuses: data.data.result },
            })
          : dispatch({
              type: TASK_STATUS_FAIL,
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: TASK_STATUS_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};

export const getTaskTypes = () => async (dispatch) => {
  dispatch({
    type: TASK_TYPE_PENDING,
    payload: { taskTypes: "isLoading" },
  });
  return await TaskService.getTaskTypes()
    .then(
      (data) => {
        data.data.status
          ? dispatch({
              type: TASK_TYPE_SUCCESS,
              payload: { taskTypes: data.data.result },
            })
          : dispatch({
              type: TASK_TYPE_FAIL,
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: TASK_TYPE_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};

// Add tasks
export const addTasks = (body) => async (dispatch) => {
  dispatch({
    type: ADD_TASK_PENDING,
    payload: { addTask: "isLoading" },
  });

  try {

    const response = await TaskService.addTask(body);


    if (response.data.message) {
      toast.success("Task added Successfully!", { autoClose: 2000 });

      dispatch({
        type: ADD_TASK_SUCCESS,
        payload: { addTask: response.data.status },
      });
    } else {
      toast.error(response.data.status === 'False' || 'Failed to add task', { autoClose: 2000 });

      dispatch({
        type: ADD_TASK_FAIL,
        payload: { addTask: response.data.status },
      });
    }

    return Promise.resolve();
  } catch (error) {
    console.error("API Error:", error);

    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();

    dispatch({
      type: ADD_TASK_FAIL,
    });

    dispatch({
      type: SET_MESSAGE,
      payload: message,
    });

    return Promise.reject();
  }
};

