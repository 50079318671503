import {
    DESIGNATION_SUCCESS,
    DESIGNATION_FAIL,
    DESIGNATION_PENDING,
    ADD_DESIGNATION_SUCCESS,
    ADD_DESIGNATION_FAIL,
    ADD_DESIGNATION_PENDING,
    UPDATE_DESIGNATION_SUCCESS,
    UPDATE_DESIGNATION_FAIL,
    UPDATE_DESIGNATION_PENDING
} from "../../actions/types";

const initialState = { 
    designations: [],
    addDesignation : '',
    updateDesignation : '',
    };

const designations_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case DESIGNATION_SUCCESS:
            return {
                ...state,
                designations: payload.designations,
            };
        case DESIGNATION_PENDING:
            return {
                ...state,
                designations: payload.designations,
            };
        case DESIGNATION_FAIL:
            return {
                ...state,
                designations: []
            };
        case ADD_DESIGNATION_SUCCESS:
            return {
                ...state,
                addDesignation: payload.addDesignation,
            };
        case ADD_DESIGNATION_PENDING:
            return {
                ...state,
                addDesignation: payload.addDesignation,
            };
        case ADD_DESIGNATION_FAIL:
            return {
                ...state,
                addDesignation: false
            };
        case UPDATE_DESIGNATION_SUCCESS:
            return {
                ...state,
                updateDesignation: payload.updateDesignation,
            };
        case UPDATE_DESIGNATION_PENDING:
            return {
                ...state,
                updateDesignation: payload.updateDesignation,
            };
        case UPDATE_DESIGNATION_FAIL:
            return {
                ...state,
                updateDesignation: false
            };
        default:
            return state;
    }
}
export default designations_reducer