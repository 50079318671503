import {
    TEAM_SUCCESS,
    TEAM_FAIL,
    TEAM_PENDING,
    ADD_TEAM_SUCCESS,
    ADD_TEAM_FAIL,
    ADD_TEAM_PENDING,
    UPDATE_TEAM_SUCCESS,
    UPDATE_TEAM_FAIL,
    UPDATE_TEAM_PENDING
} from "../../actions/types";

const initialState = { 
    teams: [],
    addTeams : '',
    updateTeams: ''
    };

const teams_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case TEAM_SUCCESS:
            return {
                ...state,
                teams: payload.teams,
            };
        case TEAM_PENDING:
            return {
                ...state,
                teams: payload.teams,
            };
        case TEAM_FAIL:
            return {
                ...state,
                teams: []
            };
        case ADD_TEAM_SUCCESS:
            return {
                ...state,
                addTeams: payload.addTeams,
            };
        case ADD_TEAM_PENDING:
            return {
                ...state,
                addTeams: payload.addTeams,
            };
        case ADD_TEAM_FAIL:
            return {
                ...state,
                addTeams: false
            };
        case UPDATE_TEAM_SUCCESS:
            return {
                ...state,
                updateTeams: payload.updateTeams,
            };
        case UPDATE_TEAM_PENDING:
            return {
                ...state,
                updateTeams: payload.updateTeams,
            };
        case UPDATE_TEAM_FAIL:
            return {
                ...state,
                updateTeams: false
            };
        default:
            return state;
    }
}
export default teams_reducer