import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addPermissions,
  updatePermissions,
  getAllPermissions,
  getAllPermissionsGroup,
} from "../../actions/userActions/permissionsAction";
import PreLoader from "../preLoader/PreLoader";
import Loader from "../subComponents/Loader";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";
import { CircularProgress, InputLabel } from "@mui/material";
import SaveButton from "../subComponents/buttons/SaveButton";
import CancelButton from "../subComponents/buttons/CancelButton";
function AddPermissions() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const initialValues = { name: "", active: false, description: "", permission: [] };
  const [loading, setLoading] = useState(true); // Loading state
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [selected, setSelected] = useState([])

  const body = {
    filter: {
      textSearch: "",
      permissionGroup: [0],
    },
    pageNumber: -1,
    pageSize: 0,
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getAllPermissions(body));
        
        if (location && location.permission) {
          setFormValues({
            ...formValues,
            name: location.permission.row.name,
            active: location.permission.row.active,
            description: location.permission.row.description,
            id: location.id,
            permission: location.permission.row.permission,
          });
          setSelected(location.permission.row.permission)
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false once data is fetched
      }
    };
    fetchData();
  }, []);

  const addPermissionHandle = async (e) => {
    const errors = validate(formValues);
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      if (location.permission !== null && location.permission !== undefined) {
        await dispatch(updatePermissions(formValues));
      } else {
        await dispatch(addPermissions(formValues));
      }
      dispatch(getAllPermissionsGroup());
      history.push("/admin/permissions");
    }
  };




  const handlePermissionChange = (e, newValue) => {
    const updatedFormValues = {
      ...formValues,
      permission: newValue
    };

    setFormValues(updatedFormValues);
    setSelected(newValue);
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    if (name === "active" && formValues.active === true) {
      setFormValues({ ...formValues, [name]: false });
    } else if (name === "active" && formValues.active === false) {
      setFormValues({ ...formValues, [name]: true });
    }
  };

  const handleDescriptionChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };


  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Name is required!";
    }
    if (!values.description) {
      errors.description = "Description is required!";
    }
    if (values.permission.length === 0) {
      errors.permission = "Permission is required!";
    }
    return errors;
  };

  const allPermissions = useSelector(
    (state) => state.permissions_reducer.allPermissions
  );

  const addPermission = useSelector(
    (state) => state.permissions_reducer.addPermission
  );
  const updatePermission = useSelector(
    (state) => state.permissions_reducer.updatePermission
  );
  const permissions = JSON.parse(localStorage.getItem("permissionsList"));

  const totalPermissions = allPermissions.permissions;

  const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
        box-sizing: border-box;
        width: 320px;
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        padding: 8px 12px;
        border-radius: 8px;
        color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
        background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
        border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]
      };
        box-shadow: 0px 2px 2px ${theme.palette.mode === "dark" ? grey[900] : grey[50]
      };
    
        &:hover {
          border-color: ${blue[400]};
        }
    
        &:focus {
          border-color: ${blue[400]};
          box-shadow: 0 0 0 3px ${theme.palette.mode === "dark" ? blue[600] : blue[200]
      };
        }
    
        // firefox
        &:focus-visible {
          outline: 0;
        }
      `
  );
  const blue = {
    100: "#DAECFF",
    200: "#b6daff",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E5",
    900: "#003A75",
  };

  const grey = {
    50: "#F3F6F9",
    100: "#E5EAF2",
    200: "#DAE2ED",
    300: "#C7D0DD",
    400: "#B0B8C4",
    500: "#9DA8B7",
    600: "#6B7A90",
    700: "#434D5B",
    800: "#303740",
    900: "#1C2025",
  };
  const handleGoBack = () => {
    history.goBack();
  };
  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '75vh' }}>
        <CircularProgress size={60} style={{ color: '#002D72' }} />
      </div>
    );
  }
  return (
    <>
      {addPermission && addPermission != "isLoading" && (
        <Redirect to="/admin/permissions" />
      )}
       {updatePermission && updatePermission != "isLoading" && (
        <Redirect to="/admin/permissions" />
      )}
      {!location.permission && <Redirect to="/admin/AddPermissions" />}
      {!permissions.includes("CanAddPermission") && <Redirect to="/admin" />}
      {!permissions.includes("CanUpdatePermission") && <Redirect to="/admin" />}
      <div>
        <div className="row">
          <div className="col-md-8"
          >
            <h2 className="d-inline-block float-left">
              <i
                onClick={history.goBack}
                className="fas fa-arrow-left"
                aria-hidden="true"
                style={{ cursor: "pointer" }}
              />{" "}
              {location.permission ? formValues.name : "Add Permission"}
            </h2>
            <p style={{ fontSize: "14px" }}></p>
          </div>

        </div>
        <hr
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.12)",
            height: "1.3px",
            marginTop: "15px",
          }}
        />

        <div className="row mt-5">
          <div className="col-6">
            <TextField
              style={{ width: "100%" }}
              className="first_textfield"
              id="outlined-controlled"
              label="Name *"
              name="name"
              value={formValues.name}
              onChange={handleChange}
            />
            <div style={{ color: "red" }}>{formErrors.name}</div>
          </div>
          <div className="col-6">
            
          <Autocomplete
  multiple
  limitTags={2}
  id="multiple-limit-tags"
  value={selected}
  options={allPermissions.permissions.filter((permission)=>!selected.some((sel)=>sel.id===permission.id))} 
  getOptionLabel={(option) => option.name}
  onChange={handlePermissionChange}
  name="permission"
  placeholder="Select Permissions"
  renderInput={(params) => (
    <TextField
      {...params}
      label="Permissions *"
      placeholder={selected.length === 0 ? "Select Permissions" : ""}
    />
  )}
  sx={{ width: "100%" }}
/>


            <div style={{ color: "red" }}>{formErrors.permission}</div>

          </div>

        </div>

        <div className="row mt-5">
          <div className="col-md-12">
            <TextField
              style={{ width: "100%" }}
              value={formValues.description}
              name="description"
              onChange={handleDescriptionChange}
              aria-label="minimum height"
              label="Description *"
              multiline
              rows={3}
            />
            <div style={{ color: "red" }}>{formErrors.description}</div>
          </div>
          <div className="col-md-12 mt-5">
            <div className="form-group">
              <input
                name="active"
                style={{ cursor: "pointer", height: "16px", width: "16px" }}
                type="checkbox"
                checked={formValues.active}
                className="ml-2 mt-2"
                onChange={handleChange}
              />
              <label
                style={{ paddingLeft: "10px", }}
                className="form-control-label"
              >
                Is permission group Active?
              </label>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end", gap: "20px" }}>
          <CancelButton onClick={handleGoBack} />
          <SaveButton
            onClick={addPermissionHandle}
            isLoading={addPermission === "isLoading" || addPermission === "isLoading"}
            text={location.permission ? "Update" : "Save"}
          />
        </div>
      </div>
    </>
  );
}

export default AddPermissions;
