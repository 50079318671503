import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getStoreTypes } from '../../actions/userActions/storeTypeAction'
import InnerHeader from '../InnerHeader/InnderHeader'
import AUModalType from './AUStoreTypeModal'
import TableComponent from '../subComponents/table/TableComponent'
import { CircularProgress } from '@mui/material'
import StoreTypeBlueIcon from '../../assets/images/store_type_blue_icon.svg'
import StatusblueIcon from '../../assets/images/status_blue_icon.svg'
import DescriptionBlueIcon from '../../assets/images/descrition_blue_icon.svg'
import ViewModal from '../subComponents/modals/ViewModal'
import DeleteModal from '../subComponents/modals/DeleteModal'
import Pagination from '../paginatioin/pagination'

function StoreTypes() {
  const [storeType, setStoreType] = useState()

  const [openAdd, setOpenAdd] = useState(false)
  const handleOpenAdd = () => setOpenAdd(true)
  const handleCloseAdd = () => setOpenAdd(false)

  const [openEdit, setOpenEdit] = useState(false)
  const handleOpenEdit = () => setOpenEdit(true)
  const handleCloseEdit = () => {
    setOpenEdit(false)
    setStoreType(null)
  }

  const [openView, setOpenView] = useState(false)
  const handleOpenView = () => {
    setOpenView(true)
  }
  const handleCloseView = () => {
    setOpenView(false)
    setStoreType(null)
  }

  const [openDelete, setOpenDelete] = useState(false)
  const handleOpenDelete = () => setOpenDelete(true)
  const handleCloseDelete = () => setOpenDelete(false)
  const [page, setPage] = useState(1)

  const [Filters, setFilters] = useState({
    textSearch: '',
    isActive: true,
  })
  const [selected, setSelected] = useState({
    textSearch: '',
    isActive: true,
  })
  const body = {
    filter: Filters,
    pageNumber: page,
    pageSize: 10,
  }

  const dispatch = useDispatch()
  const addStoreTypes = useSelector(
    (state) => state.store_types_reducer.addStoreTypes,
  )
  const updateStoreTypes = useSelector(
    (state) => state.store_types_reducer.updateStoreType,
  )

  useEffect(() => {
    dispatch(getStoreTypes(body))
  }, [addStoreTypes, updateStoreTypes, page, Filters])

  const storeTypes = useSelector(
    (state) => state.store_types_reducer.store_types,
  )
  const permissions = JSON.parse(localStorage.getItem('permissionsList'))
  const [query, setQuery] = useState('')

  const handleSearch = (e) => {
    setSelected({ ...selected, ['textSearch']: e.target.value })
    if (e.target.value.length >= 4) {
      setTimeout(() => {
        setFilters({ ...Filters, ['textSearch']: e.target.value })
      }, 500)
    }
    if (e.target.value == '') {
      setFilters({ ...Filters, ['textSearch']: e.target.value })
    }
  }

  const getPaginationNo = (pageNum) => {
    const object = []
    if (storeTypes !== 'isLoading' && storeTypes.length !== 0) {
      for (let i = 0; i < storeTypes.page.totalPages; i++) {
        object.push(
          <button
            className={pageNum == i + 1 ? 'active' : ''}
            onClick={() => setPage(i + 1)}
          >
            {i + 1}
          </button>,
        )
      }
    }
    return object
  }

  //   const filter_storeTypes =
  //     storeTypes && storeTypes !== 'isLoading'
  //       ? storeTypes.filter((s) => {
  //           return s.name.toLowerCase().includes(query.toLowerCase())
  //         })
  //       : []
  console.log(storeTypes)
  return (
    <>
      <div className="container mt-5">
        <InnerHeader
          title="Store Types"
          description="Manage all your store types or add new one"
          handleSearch={handleSearch}
          value={selected.textSearch}
          serverSideSearch={true}
          searchPlaceholder="Search store type"
          permission={permissions.includes('CanAddStoreType')}
          buttonTitle="+ Add Store Type"
          onClick={handleOpenAdd}
        />
        <div className="row">
          <div className="col-md-12">
            {storeTypes === 'isLoading' && (
              <div style={{ height: '70vh' }}>
                <div className="d-flex my-5" style={{ height: '100%' }}>
                  <CircularProgress
                    sx={{ margin: 'auto', color: 'rgba(0, 45, 114, 1)' }}
                  />
                </div>
              </div>
            )}
            {storeTypes && storeTypes !== 'isLoading' && (
              <>
                <TableComponent
                  data={storeTypes.storeTypes}
                  headers={['Name', 'Description', 'Status']}
                  keys={['name', 'description', 'active']}
                  setData={setStoreType}
                  doubleTitle={false}
                  onEyeClick={handleOpenView}
                  onEditClick={handleOpenEdit}
                  onDeleteClick={handleOpenDelete}
                />
                {/* <div className="row">
                  <div className="col-12">
                    <div className="task-pagination-wrapper">
                      {getPaginationNo(page)}
                    </div>
                  </div>
                </div> */}
                {storeTypes !== 'isLoading' && (
                  <div className="task-pagination-wrapper">
                    <Pagination
                      total={
                        storeTypes.page != undefined &&
                        storeTypes.page.totalPages * storeTypes.page.pageSize
                      }
                      current={
                        storeTypes.page != undefined &&
                        storeTypes.page.pageNumber
                      }
                      pageSize={
                        storeTypes.page != undefined && storeTypes.page.pageSize
                      }
                      onChange={(current) => setPage(current)}
                      showLessItems
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <DeleteModal
        title="Delete Store"
        type="store"
        open={openDelete}
        onClose={handleCloseDelete}
      />
      {storeType && (
        <ViewModal
          title="Store"
          headings={['Name', 'Description', 'Status']}
          keys={['name', 'description', 'active']}
          icons={[StoreTypeBlueIcon, DescriptionBlueIcon, StatusblueIcon]}
          data={storeType}
          open={openView}
          onClose={handleCloseView}
        />
      )}
      <AUModalType
        type="add"
        onViewClose={handleCloseView}
        open={openAdd}
        onClose={handleCloseAdd}
      />
      {storeType && (
        <AUModalType
          type="update"
          onViewClose={handleCloseView}
          storeType={storeType}
          open={openEdit}
          onClose={handleCloseEdit}
        />
      )}
    </>
  )
}

export default StoreTypes
