import {
    EMPLOYMENT_STATUS_SUCCESS,
    EMPLOYMENT_STATUS_FAIL,
    EMPLOYMENT_STATUS_PENDING,
    ADD_EMPLOYMENT_STATUS_SUCCESS,
    ADD_EMPLOYMENT_STATUS_FAIL,
    ADD_EMPLOYMENT_STATUS_PENDING,
    UPDATE_EMPLOYMENT_STATUS_SUCCESS,
    UPDATE_EMPLOYMENT_STATUS_FAIL,
    UPDATE_EMPLOYMENT_STATUS_PENDING,
} from "../../actions/types";

const initialState = { 
    employmentStatuses: [],
    addEmploymentStatus : '',
    updateEmploymentStatus : ''
    };

const employmentStatuses_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case EMPLOYMENT_STATUS_SUCCESS:
            return {
                ...state,
                employmentStatuses: payload.employmentStatuses,
            };
        case EMPLOYMENT_STATUS_PENDING:
            return {
                ...state,
                employmentStatuses: payload.employmentStatuses,
            };
        case EMPLOYMENT_STATUS_FAIL:
            return {
                ...state,
                employmentStatuses: []
            };
        case ADD_EMPLOYMENT_STATUS_SUCCESS:
            return {
                ...state,
                addEmploymentStatus: payload.addEmploymentStatus,
            };
        case ADD_EMPLOYMENT_STATUS_PENDING:
            return {
                ...state,
                addEmploymentStatus: payload.addEmploymentStatus,
            };
        case ADD_EMPLOYMENT_STATUS_FAIL:
            return {
                ...state,
                addEmploymentStatus: false
            };
        case UPDATE_EMPLOYMENT_STATUS_SUCCESS:
            return {
                ...state,
                updateEmploymentStatus: payload.updateEmploymentStatus,
            };
        case UPDATE_EMPLOYMENT_STATUS_PENDING:
            return {
                ...state,
                updateEmploymentStatus: payload.updateEmploymentStatus,
            };
        case UPDATE_EMPLOYMENT_STATUS_FAIL:
            return {
                ...state,
                updateEmploymentStatus: false
            };
        default:
            return state;
    }
}
export default employmentStatuses_reducer