import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { addLocationType, updateLocationType } from '../../actions/userActions/locationTypeAction';
import Loader from '../subComponents/Loader'
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import TextField from "@mui/material/TextField";
import { SaveButton } from "../subComponents/buttons/SaveButton";
import { CancelButton } from "../subComponents/buttons/CancelButton";
import HrComponent from '../reuse_components/HrComponent';
function AddLocationTypes() {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const initialValues = { name: "", description: "" };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});

  
    useEffect(() => {
        if (location.locationType) {
            setFormValues({
                ...formValues,
                name: location.locationType.row.name,
                description: location.locationType.row.description,
                id: location.id,
            });
        }
    }, [location]);

    const addLocationTypeHandle = async (e) => {
        const errors = validate(formValues);
        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            if (location.locationType) {
                dispatch(updateLocationType(formValues));
            } else {
                dispatch(addLocationType(formValues));
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    }

    const validate = (values) => {
        const errors = {};
        if (!values.name) {
            errors.name = "Name is required!"
        }
        if (!values.description) {
            errors.description = "Description is required!"
        }
        return errors;
    }
    const updateLocationTypeStatus = useSelector(state => state.location_type_reducer.updateLocationType);
    const addLocationTypeStatus = useSelector(state => state.location_type_reducer.addLocationType);
    const permissions = JSON.parse(localStorage.getItem("permissionsList"));
    const handleGoBack = () => {
        history.goBack();
    };
    return (
        <>
            {(addLocationTypeStatus && addLocationTypeStatus !== "isLoading") && <Redirect to="/admin/locationTypes" />}
            {!location.locationType && <Redirect to="/admin/AddLocationTypes" />}
            {!permissions.includes("CanAddPracticeLocation") && <Redirect to="/admin" />}
            {!permissions.includes("CanUpdatePracticeLocation") && <Redirect to="/admin" />}
            <div>
                <div className="row">
                    <div className="col-md-6">
                        <h2 className="d-inine-block float-left">
                            <i
                                onClick={history.goBack}
                                className="fas fa-arrow-left"
                                aria-hidden="true"
                                style={{ cursor: "pointer" }}
                            />{" "}
                            {location.locationType ? "Update Practice Location Type" : "Add Practice Location Type"}
                        </h2>
                    </div>
                <HrComponent />
                    <div className="col-md-6"></div>
                </div>
                <div className="row">
                    <div className="col-md-12 mt-5">
                        <TextField
                            style={{ width: "100%", fontSize: "46px" }}
                            className="first_textfield"
                            id="outlined-controlled"
                            label="Name *"
                            name="name"
                            value={formValues.name}
                            onChange={handleChange}
                        />
                        <div style={{ color: "red" }}>{formErrors.name}</div>
                    </div>

                    <div className="col-md-12 mt-5">
                        <TextField
                            style={{ width: "100%" }}
                            multiline
                            rows={3}
                            value={formValues.description}
                            name="description"
                            onChange={handleChange}
                            label="Description *"
                        />
                        <div style={{ color: "red" }}>{formErrors.description}</div>
                    </div>

                    <div className="col-lg-12 mt-10" style={{ display: "flex", justifyContent: "flex-end", gap: "20px" }}>
                        <CancelButton
                            onClick={handleGoBack}
                        />
                        <SaveButton
                            onClick={addLocationTypeHandle}
                            isLoading={addLocationTypeStatus === "isLoading" || updateLocationTypeStatus === "isLoading"}
                            text={location.locationType ? "Update" : "Save"}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddLocationTypes
