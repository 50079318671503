import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { archiveTask } from "../../actions/userActions/archiveTaskAction";
import Loader from "../subComponents/Loader";
import Pagination from "../paginatioin/pagination";
import moment from "moment";
import {
  Button,
  Menu,
  TextField,
  Tooltip,
  Drawer,
  ToggleButtonGroup,
  ToggleButton,
  Autocomplete,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ApplyButton from "../subComponents/buttons/ApplyButton";
import ResetButton from "../subComponents/buttons/ResetButton";
import ServerSideSearch from "../subComponents/search/ServerSideSearch";
import {
  getTasks,
  getTaskStatuses,
  getTaskTypes,
} from "../../actions/userActions/tasks/taskAction";
import { getTaskPriorities } from "../../actions/userActions/taskPriorityAction";
import { SIZE_OF_PAGE } from "../../actions/types";

const CompletedTask = () => {
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState('');

  const currentDate = moment();

  const defaultSelectedButton = "last 7 days";
  const defaultSelected = {
    taskType: [],
    taskPriority: [],
    taskStatus: [],
    taskAssignee: [],
    textSearch: "",
    taskFromDate: moment(currentDate).subtract(7, "days").format("YYYY-MM-DD"),
    taskToDate: moment(currentDate).format("YYYY-MM-DD"),
  };

  const defaultFilters = {
    taskType: [],
    taskPriority: [],
    taskStatus: [],
    taskAssignee: [],
    textSearch: "",
    taskFromDate: moment(currentDate).subtract(1, "year").format("YYYY-MM-DD"),
    taskToDate: moment(currentDate).format("YYYY-MM-DD"),
  };
  const user = JSON.parse(localStorage.getItem("user"));

  const getInitialState = (key, defaultState) => {
    const savedState = sessionStorage.getItem(key);
    return savedState ? JSON.parse(savedState) : defaultState;
  };


  const [selected, setSelected] = useState(
    getInitialState("selected", defaultSelected)
  );
  const [filters, setFilters] = useState(
    getInitialState("filters", defaultFilters)
  );
  const [selectedButton, setSelectedButton] = useState(
    getInitialState("selectedButton", defaultSelectedButton)
  );
  const [customDate, setCustomDate] = useState(
    getInitialState("selectedButton", defaultSelectedButton) === "custom"
  );

  const dispatch = useDispatch();

  const body = {
    filter: filters,
    pageNumber: page,
    pageSize: 10,
    employeeId: user.result[0].user.id,
    // employeeId: 1,
  };
  const employeeId =
    user.result[0] !== undefined ? user.result[0].user.id : false;

  useEffect(() => {
    const pageNumber = -1
    dispatch(archiveTask(body))
      .then(() => {
        dispatch(getTasks());
        dispatch(getTaskTypes());
        dispatch(getTaskPriorities(pageNumber, SIZE_OF_PAGE, query));
        dispatch(getTaskStatuses());
        dispatch(getEmployeeSubOrdinates(employeeId));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [page, , selected.textSearch]);

  const ArchiveTasks = useSelector(
    (state) => state.archive_task_reducer.archiveTask
  );
  const taskTypes = useSelector((state) => state.tasks_reducer.taskTypes);
  const taskPriorities = useSelector(
    (state) => state.tasks_reducer.taskPriorities
  );
  const taskPrioritiesData = taskPriorities && taskPriorities.result;
  
  const employees = useSelector(
    (state) => state.employees_reducer.employeeSubordinates
  );

  

  const handleSearch = (e) => {
    setSelected({ ...selected, ["textSearch"]: e.target.value });
    if (e.target.value.length > 0) {
      setTimeout(() => {
        setFilters({ ...filters, ["textSearch"]: e.target.value });
      }, 500);
    }
    if (e.target.value == "") {
      setFilters({ ...filters, ["textSearch"]: e.target.value });
    }
  };

  const handleApply = () => {
    const body = {
      filter: filters,
      employeeId: user.result[0].user.id,
      // employeeId: 1
    };
    dispatch(archiveTask(body));
    handleClose();
  };
  const handleReset = () => {
    setSelected({
      ...filters,
      ["taskType"]: [],
      ["taskPriority"]: [],
      ["taskStatus"]: [],
      ["taskAssignee"]: [],
      taskFromDate: moment(currentDate)
        .subtract(7, "days")
        .format("YYYY-MM-DD"),
      taskToDate: moment(currentDate).format("YYYY-MM-DD"),
    });
    setFilters({
      ...filters,
      ["taskStatus"]: [],
      ["taskType"]: [],
      ["taskPriority"]: [],
      ["taskAssignee"]: [],
      taskFromDate: moment(currentDate)
        .subtract(7, "days")
        .format("YYYY-MM-DD"),
      taskToDate: moment(currentDate).format("YYYY-MM-DD"),
    });
    setSelectedButton("last 7 days");
  };
  //
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggleSelection = (event, newSelection) => {
    if (newSelection === "last 7 days") {
      setCustomDate(false);
      const fromDate = moment(currentDate)
        .subtract(7, "days")
        .format("YYYY-MM-DD");
      const toDate = moment(currentDate).format("YYYY-MM-DD");
      setSelected((prevValues) => ({
        ...prevValues,
        taskFromDate: fromDate,
        taskToDate: toDate,
      }));
      setFilters((prevValues) => ({
        ...prevValues,
        taskFromDate: fromDate,
        taskToDate: toDate,
      }));
    } else if (newSelection === "today") {
      setCustomDate(false);
      const currentDate = moment().toDate();
      setSelected((prevValues) => ({
        ...prevValues,
        taskFromDate: currentDate,
        taskToDate: currentDate,
      }));
      setFilters((prevValues) => ({
        ...prevValues,
        taskFromDate: currentDate,
        taskToDate: currentDate,
      }));
    } else if (newSelection === "yesterday") {
      setCustomDate(false);
      const yesterdayDate = moment().subtract(1, "days").toDate();
      setSelected((prevValues) => ({
        ...prevValues,
        taskFromDate: yesterdayDate,
        taskToDate: yesterdayDate,
      }));
      setFilters((prevValues) => ({
        ...prevValues,
        taskFromDate: yesterdayDate,
        taskToDate: yesterdayDate,
      }));
    } else if (newSelection === "last 30 days") {
      setCustomDate(false);
      const fromDate = moment().subtract(29, "days").toDate();
      const toDate = moment().toDate();
      setSelected((prevValues) => ({
        ...prevValues,
        taskFromDate: fromDate,
        taskToDate: toDate,
      }));
      setFilters((prevValues) => ({
        ...prevValues,
        taskFromDate: fromDate,
        taskToDate: toDate,
      }));
    } else if (newSelection === "this month") {
      setCustomDate(false);
      const fromDate = moment().startOf("month").toDate();
      const toDate = moment().endOf("month").toDate();
      setSelected((prevValues) => ({
        ...prevValues,
        taskFromDate: fromDate,
        taskToDate: toDate,
      }));
      setFilters((prevValues) => ({
        ...prevValues,
        taskFromDate: fromDate,
        taskToDate: toDate,
      }));
    } else if (newSelection === "last month") {
      setCustomDate(false);
      const fromDate = moment().subtract(1, "month").startOf("month").toDate();
      const toDate = moment().subtract(1, "month").endOf("month").toDate();
      setSelected((prevValues) => ({
        ...prevValues,
        taskFromDate: fromDate,
        taskToDate: toDate,
      }));
      setFilters((prevValues) => ({
        ...prevValues,
        taskFromDate: fromDate,
        taskToDate: toDate,
      }));
    } else if (newSelection === "last year") {
      setCustomDate(false);
      const fromDate = moment().subtract(1, "year").startOf("year").toDate();
      const toDate = moment().subtract(1, "year").endOf("year").toDate();
      setSelected((prevValues) => ({
        ...prevValues,
        taskFromDate: fromDate,
        taskToDate: toDate,
      }));
      setFilters((prevValues) => ({
        ...prevValues,
        taskFromDate: fromDate,
        taskToDate: toDate,
      }));
    } else if (newSelection === "custom") {
      setCustomDate(true);
      setSelected((prevValues) => ({
        ...prevValues,
        taskFromDate: "",
        taskToDate: "",
      }));
      setFilters((prevValues) => ({
        ...prevValues,
        taskFromDate: "",
        taskToDate: "",
      }));
    }

    setSelectedButton(newSelection);
  };

  const handleEmployeesChange = (event, selectedEmployees) => {
    const selectedEmployeeIds = selectedEmployees.map(
      (employee) => employee.id
    );
    setSelected((prevValues) => ({
      ...prevValues,
      taskAssignee: selectedEmployees,
    }));
    setFilters((prevValues) => ({
      ...prevValues,
      taskAssignee: selectedEmployeeIds,
    }));
  };
  const handleStatusesChange = (event, selectedStatuses) => {
    const selectedStatusesIds = selectedStatuses.map((Status) => Status.id);
    setSelected((prevValues) => ({
      ...prevValues,
      taskStatus: selectedStatuses,
    }));
    setFilters((prevValues) => ({
      ...prevValues,
      taskStatus: selectedStatusesIds,
    }));
  };
  const handlePrioritiesChange = (event, selectedPriorities) => {
    const selectedPrioritiesIds = selectedPriorities.map(
      (Priority) => Priority.id
    );
    setSelected((prevValues) => ({
      ...prevValues,
      taskPriority: selectedPriorities,
    }));
    setFilters((prevValues) => ({
      ...prevValues,
      taskPriority: selectedPrioritiesIds,
    }));
  };
  const handleTypesChange = (event, selectedTypes) => {
    const selectedTypesIds = selectedTypes.map((Type) => Type.id);
    setSelected((prevValues) => ({
      ...prevValues,
      taskType: selectedTypes,
    }));
    setFilters((prevValues) => ({
      ...prevValues,
      taskType: selectedTypesIds,
    }));
  };
  const handleFromDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setSelected((prevValues) => ({
      ...prevValues,
      taskFromDate: formattedDate,
    }));
    setFilters((prevValues) => ({
      ...prevValues,
      taskFromDate: formattedDate,
    }));
  };
  const handleToDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setSelected((prevValues) => ({
      ...prevValues,
      taskToDate: formattedDate,
    }));
    setFilters((prevValues) => ({
      ...prevValues,
      taskToDate: formattedDate,
    }));
  };

  return (
    <>
      <div>
        <div className="row">
          <div className="col-md-6">
            <h2 className="d-inine-block">Archive Tasks</h2>
          </div>
          <div
            className="col-6 md-4 d-flex justify-content-end"
            style={{ gap: "16px" }}
          >
            <ServerSideSearch
              placeholder="Search Task"
              onChange={handleSearch}
              value={selected.textSearch}
            />

            <Button
              aria-label="delete"
              size="large"
              variant="outlined"
              // startIcon={<FilterAltOutlinedIcon fontSize="inherit"/>}
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              startIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="23"
                  viewBox="0 0 22 23"
                  fill="none"
                >
                  <path
                    d="M6.42122 6.0013H15.5879L10.9954 11.7763L6.42122 6.0013ZM3.90039 5.6438C5.75206 8.01797 9.17122 12.418 9.17122 12.418V17.918C9.17122 18.4221 9.58372 18.8346 10.0879 18.8346H11.9212C12.4254 18.8346 12.8379 18.4221 12.8379 17.918V12.418C12.8379 12.418 16.2479 8.01797 18.0996 5.6438C18.5671 5.0388 18.1362 4.16797 17.3754 4.16797H4.62456C3.86372 4.16797 3.43289 5.0388 3.90039 5.6438Z"
                    fill="#505157"
                  />
                </svg>
              }
              // startIcon={<FilterAltOutlinedIcon  sx={{ fontSize: 30 }} />}
              sx={{
                color: "#505157",
                borderColor: "#D9D9D9",
                border: "1px solid",
                background: "#FFFFFF",
                padding: "4px 14px",
                "& .MuiButton-startIcon": {
                  color: "#505157",
                  marginRight: "0px",
                  marginLeft: "0px",
                },
                "&:hover": {
                  color: "#2F54EB",
                  background: "#FFFFFF",
                  borderColor: "#2F54EB",
                  border: "1px solid",
                  "& .MuiButton-startIcon": {
                    color: "#2F54EB",
                  },
                },
                height: "40px",
                minWidth: "40px",
                boxShadow: "2px #00000004",
                borderRadius: "2px",
              }}
            ></Button>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              PaperProps={{
                style: {
                  width: "989px",
                  top: "-47614px",
                  left: "-4486px",
                  borderRadius: "4px",
                  background: "#FFFFFF",
                  boxShadow: "0px 11px 15px -7px #00000033",
                },
              }}
            >
              <div
                className="row"
                style={{
                  padding: "16px 24px 16px 24px",
                  justify: "space-between",
                  display: "flex",
                  gap: "24px",
                }}
              >
                <div className="col-12 d-flex ml-2">
                  <h6
                    style={{
                      fontFamily: "Roboto",
                      fontSize: "20px",
                      fontWeight: "500",
                      lineHeight: "32px",
                      textAlign: "left",
                      color: "#000000DE",
                    }}
                  >
                    Filters
                  </h6>

                  <Tooltip title="Close Filter" arrow enterDelay={500}>
                    <CloseIcon
                      style={{ marginLeft: "auto", cursor: "pointer" }}
                      onClick={handleClose}
                    />
                  </Tooltip>
                </div>
              </div>
              <div
                className="row"
                style={{
                  gap: "24px",
                  borderBottom: "1px solid #0000001F",
                  padding: "24px 16px 24px 16px",
                  alignItems: "center",
                }}
              >
                <div className="row" style={{ gap: "16px" }}>
                  <div
                    className="col-12 d-flex justify-content-center align-items-center"
                    style={{ height: "48px" }}
                  >
                    <ToggleButtonGroup
                      exclusive
                      value={selectedButton}
                      onChange={handleToggleSelection}
                      aria-label="button group"
                    >
                      <ToggleButton
                        value="today"
                        style={{
                          width: "114px",
                          height: "32px",
                          padding: "4px 8px 4px 8px",
                          gap: "8px",
                          borderRadius: "4px",
                          textTransform: "none",
                          backgroundColor:
                            selectedButton === "today" ? "#2F54EB" : "inherit",
                          color:
                            selectedButton === "today" ? "#fff" : "inherit",
                        }}
                      >
                        Today
                      </ToggleButton>
                      <ToggleButton
                        value="yesterday"
                        style={{
                          width: "114px",
                          height: "32px",
                          padding: "4px 8px 4px 8px",
                          gap: "8px",
                          borderRadius: "4px",
                          textTransform: "none",
                          backgroundColor:
                            selectedButton === "yesterday"
                              ? "#2F54EB"
                              : "inherit",
                          color:
                            selectedButton === "yesterday" ? "#fff" : "inherit",
                        }}
                      >
                        Yesterday
                      </ToggleButton>
                      <ToggleButton
                        value="last 7 days"
                        style={{
                          width: "114px",
                          height: "32px",
                          padding: "4px 8px 4px 8px",
                          gap: "8px",
                          borderRadius: "4px",
                          textTransform: "none",
                          backgroundColor:
                            selectedButton === "last 7 days"
                              ? "#2F54EB"
                              : "inherit",
                          color:
                            selectedButton === "last 7 days"
                              ? "#fff"
                              : "inherit",
                        }}
                      >
                        Last 7 days
                      </ToggleButton>
                      <ToggleButton
                        value="last 30 days"
                        style={{
                          width: "114px",
                          height: "32px",
                          padding: "4px 8px 4px 8px",
                          gap: "8px",
                          borderRadius: "4px",
                          textTransform: "none",
                          backgroundColor:
                            selectedButton === "last 30 days"
                              ? "#2F54EB"
                              : "inherit",
                          color:
                            selectedButton === "last 30 days"
                              ? "#fff"
                              : "inherit",
                        }}
                      >
                        Last 30 days
                      </ToggleButton>
                      <ToggleButton
                        value="this month"
                        style={{
                          width: "114px",
                          height: "32px",
                          padding: "4px 8px 4px 8px",
                          gap: "8px",
                          borderRadius: "4px",
                          textTransform: "none",
                          backgroundColor:
                            selectedButton === "this month"
                              ? "#2F54EB"
                              : "inherit",
                          color:
                            selectedButton === "this month"
                              ? "#fff"
                              : "inherit",
                        }}
                      >
                        This Month
                      </ToggleButton>
                      <ToggleButton
                        value="last month"
                        style={{
                          width: "114px",
                          height: "32px",
                          padding: "4px 8px 4px 8px",
                          gap: "8px",
                          borderRadius: "4px",
                          textTransform: "none",
                          backgroundColor:
                            selectedButton === "last month"
                              ? "#2F54EB"
                              : "inherit",
                          color:
                            selectedButton === "last month"
                              ? "#fff"
                              : "inherit",
                        }}
                      >
                        Last Month
                      </ToggleButton>
                      <ToggleButton
                        value="last year"
                        style={{
                          width: "114px",
                          height: "32px",
                          padding: "4px 8px 4px 8px",
                          gap: "8px",
                          borderRadius: "4px",
                          textTransform: "none",
                          backgroundColor:
                            selectedButton === "last year"
                              ? "#2F54EB"
                              : "inherit",
                          color:
                            selectedButton === "last year" ? "#fff" : "inherit",
                        }}
                      >
                        Last Year
                      </ToggleButton>
                      <ToggleButton
                        value="custom"
                        style={{
                          width: "114px",
                          height: "32px",
                          padding: "4px 8px 4px 8px",
                          gap: "8px",
                          borderRadius: "4px",
                          textTransform: "none",
                          backgroundColor:
                            selectedButton === "custom" ? "#2F54EB" : "inherit",
                          color:
                            selectedButton === "custom" ? "#fff" : "inherit",
                        }}
                      >
                        Custom
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                  <div
                    className="col-12 d-flex"
                    style={{
                      height: "56px",
                    }}
                  >
                    <div className="col-6">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="From"
                          valueType="date"
                          value={
                            selected.taskFromDate &&
                            selected.taskFromDate.length != 0
                              ? dayjs(selected.taskFromDate)
                              : null
                          }
                          onChange={handleFromDateChange}
                          disabled={!customDate}
                          renderInput={(params) => (
                            <TextField fullWidth {...params} />
                          )}
                          sx={{ width: "100%" }}
                        />
                      </LocalizationProvider>
                    </div>
                    <div className="col-6">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="To"
                          valueType="date"
                          value={
                            selected.taskToDate &&
                            selected.taskToDate.length != 0
                              ? dayjs(selected.taskToDate)
                              : null
                          }
                          onChange={handleToDateChange}
                          disabled={!customDate}
                          renderInput={(params) => (
                            <TextField fullWidth {...params} />
                          )}
                          sx={{ width: "100%" }}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ gap: "16px" }}>
                  <div className="col-12 d-flex">
                    <div className="col-6">
                      <Autocomplete
                        multiple
                        limitTags={3}
                        id="multiple-limit-tags-taskType"
                        value={selected.taskType}
                        onChange={handleTypesChange}
                        menuprops={{
                          PaperProps: { style: { maxHeight: 200, width: 250 } },
                        }}
                        options={
                          taskTypes === "isLoading"
                            ? [{ name: "Loading...", id: -1 }]
                            : taskTypes.length === 0
                            ? [{ name: "No data", id: -1 }]
                            : taskTypes
                                .filter((taskType) => taskType.active)
                                .sort((a, b) => a.name.localeCompare(b.name))
                        }
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField {...params} label="Type" />
                        )}
                      />
                    </div>
                    <div className="col-6">
                      <Autocomplete
                        multiple
                        limitTags={3}
                        value={selected.taskPriority}
                        id="multiple-limit-tags-priority"
                        onChange={handlePrioritiesChange}
                        menuprops={{
                          PaperProps: { style: { maxHeight: 200, width: 250 } },
                        }}
                        options={
                          taskPriorities === "isLoading"
                            ? [{ name: "Loading...", id: -1 }]
                            : taskPriorities !== 'isLoading' && Array.isArray(taskPrioritiesData) && taskPrioritiesData.length === 0 
                            ? [{ name: "No data", id: -1 }]
                            : taskPriorities && Array.isArray(taskPrioritiesData)&& taskPrioritiesData !== 'isLoading' && taskPrioritiesData.length !== 0 && taskPrioritiesData
                                .filter((taskPriority) => taskPriority.active)
                                .sort((a, b) => a.name.localeCompare(b.name))
                        }
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField {...params} label="Priority" />
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-12 d-flex">
                    {/* <div className="col-12">
                      <Autocomplete
                        multiple
                        limitTags={1}
                        value={selected.taskStatus}
                        id="multiple-limit-tags-status"
                        onChange={handleStatusesChange}
                        menuprops={{
                          PaperProps: { style: { maxHeight: 200, width: 250 } },
                        }}
                        options={
                          taskStatuses === "isLoading"
                            ? [{ name: "Loading...", id: -1 }]
                            : taskStatuses.length === 0
                            ? [{ name: "No data", id: -1 }]
                            : taskStatuses
                                .filter((taskStatus) => taskStatus.active)
                                .sort((a, b) => a.name.localeCompare(b.name))
                        }
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField {...params} label="Status" />
                        )}
                      />
                    </div> */}
                    <div className="col-12">
                      <Autocomplete
                        multiple
                        limitTags={2}
                        id="multiple-limit-tags-employee"
                        onChange={handleEmployeesChange}
                        value={selected.taskAssignee}
                        menuprops={{
                          PaperProps: { style: { maxHeight: 200, width: 250 } },
                        }}
                        options={
                          employees === "isLoading"
                            ? [{ name: "Loading...", id: -1 }]
                            : employees.length === 0
                            ? [{ name: "No data", id: -1 }]
                            : employees.sort((a, b) => {
                                const firstNameComparison =
                                  a.firstName.localeCompare(b.firstName);
                                if (firstNameComparison !== 0) {
                                  return firstNameComparison;
                                }
                                return a.lastName.localeCompare(b.lastName);
                              })
                        }
                        getOptionLabel={(option) =>
                          `${option.firstName} ${option.lastName}`
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="Employee" />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="row mr-4"
                style={{
                  padding: "16px 24px 16px 24px",
                  gap: "8px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <ApplyButton onClick={handleApply} />
                <ResetButton onClick={handleReset} />
              </div>
            </Menu>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <table className="table mt-4">
              <thead className="text-left">
                <tr>
                  <th scope="col" className="task-table-header">
                    Title
                  </th>

                  <th scope="col" className="task-table-header">
                    Assigned To
                  </th>
                  <th scope="col" className="task-table-header">
                    Status
                  </th>
                  <th scope="col" className="task-table-header">
                    Task Priority
                  </th>
                  <th scope="col" className="task-table-header">
                    Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {ArchiveTasks &&
                  ArchiveTasks.result &&
                  ArchiveTasks.result[0] &&
                  ArchiveTasks.result[0].tasks &&
                  ArchiveTasks.result[0].tasks.length === 0 && (
                    <tr
                      className="text-center"
                      style={{
                        backgroundColor: "transparent",
                        "--x-table-accent-bg": "transparent",
                      }}
                    >
                      <td colSpan={6}>No data found</td>
                    </tr>
                  )}

                {ArchiveTasks === "isLoading" && ArchiveTasks !== undefined && (
                  <tr
                    style={{
                      backgroundColor: "transparent",
                      "--x-table-accent-bg": "transparent",
                    }}
                  >
                    <td colSpan={6}>
                      <Loader colored={true} />
                    </td>
                  </tr>
                )}
                {ArchiveTasks &&
                  ArchiveTasks.result &&
                  ArchiveTasks.result[0] &&
                  ArchiveTasks.result[0].tasks &&
                  ArchiveTasks.result[0].tasks.length > 0 &&
                  ArchiveTasks.result[0].tasks.map((row, i) => (
                    <tr key={i}>
                      <td style={{ verticalAlign: "middle" }}>{row.title}</td>
                      <td
                        style={{
                          height: "54px",
                          width: "178.67px",
                          verticalAlign: "middle",
                        }}
                      >
                        {" "}
                        <span>{row.assigneeName}</span>
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {" "}
                        <span
                          className="badge badge-lg badge-dot"
                          style={{ color: row.taskStatusColor }}
                        >
                          <i className="bg-success" />
                          {row.taskStatusName}
                        </span>
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        <span style={{ color: row.taskPriorityColor }}>
                          {row.taskPriorityName}
                        </span>
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        <span>
                          {moment(row.taskCreateDate).format(
                            "ddd, MMM D, YYYY"
                          )}
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>

        {ArchiveTasks !== "isLoading" && (
          <div className="task-pagination-wrapper">
            <Pagination
              total={
                ArchiveTasks.page != undefined &&
                ArchiveTasks.page.totalPages * ArchiveTasks.page.pageSize
              }
              current={
                ArchiveTasks.page != undefined && ArchiveTasks.page.pageNumber
              }
              pageSize={
                ArchiveTasks.page != undefined && ArchiveTasks.page.pageSize
              }
              onChange={(current) => setPage(current)}
              showLessItems
            />
          </div>
        )}
      </div>
    </>
  );
};

export default CompletedTask;
