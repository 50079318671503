import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { MODAL_STYLE } from '../../Constatns'
import {
  addStock,
  updateStock,
  nullUpdateStock,
} from '../../actions/userActions/stockAction'
import CrossIcon from '../../assets/images/cross_icon.svg'
import { getStores } from '../../actions/userActions/storeAction'
import { getProducts } from '../../actions/userActions/productAction'
import CircularProgress from '@mui/material/CircularProgress'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import moment from 'moment'
import {
  Box,
  Typography,
  Modal,
  Button,
  TextField,
  Autocomplete,
} from '@mui/material'
import { getUnitTypes } from '../../actions/userActions/unitTypeAction'

function AUStockModal(props) {
  const userObject = JSON.parse(localStorage.getItem('user'))
  const user = userObject.result[0].user
  const dispatch = useDispatch()
  const initialValues = {
    productId: '',
    storeId: '',
    quantity: '',
    quantityOut: '',
    unitTypeId: '',
    recordDate: dayjs(moment().format('YYYY-MM-DD')),
    createdBy: user.id,
  }

  const [formValues, setFormValues] = useState(initialValues)
  const [formErrors, setFormErrors] = useState({})
  const [storelocation, setStoreLocation] = useState('')
  const [isSubmit, setIsSubmit] = useState(false)
  const resetValues = () => {
    setFormErrors({})
    setFormValues(initialValues)
    setIsSubmit(false)
  }
  const [page, setPage] = useState(1)

  const [Filters, setFilters] = useState({
    textSearch: '',
    isActive: true,
  })

  const [storeFilters, setStoreFilters] = useState({
    isActive: true,
    textSearch: '',
    type: [],
    state: [],
    city: [],
    area: [],
    block: [],
  })

  const unitBody = {
    filter: Filters,
    pageNumber: page,
    pageSize: 10,
  }

  const storeBody = {
    filter: storeFilters,
    pageNumber: page,
    pageSize: 100,
  }

  const [productFilters, setProductFilters] = useState({
    textSearch: '',
  })
  const prooductBody = {
    filter: productFilters,
    pageNumber: page,
    pageSize: 100,
  }

  useEffect(() => {
    dispatch(getStores(storeBody))
    dispatch(getProducts(prooductBody))
    dispatch(getUnitTypes(unitBody))
    {
      props.type == 'update' &&
        props.stock &&
        setFormValues({
          ...formValues,
          ['productId']: props.stock.productId,
          ['storeId']: props.stock.storeId,
          ['quantity']: props.stock.quantity,
          ['unitTypeId']: props.stock.unitTypeId,
          ['recordDate']: dayjs(
            moment(props.stock.recordDate).format('YYYY-MM-DD'),
          ),
          ['createdBy']: props.stock.createdBy,
          ['id']: props.stock.id,
        })
      props.type == 'update' &&
        props.stock &&
        setStoreLocation(props.stock.storeLocation)
    }
  }, [])

  const hanleChange = (e) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
  }

  const validate = (values) => {
    const errors = {}
    if (values.productId == '' || values.productId == null) {
      errors.productId = 'Product is required !'
    }
    if (values.storeId == '' || values.storeId == null) {
      errors.storeId = 'Store is required !'
    }
    if (values.quantity === '') {
      errors.quantity = 'Quantity is required !'
    }
    if (values.quantityOut === '') {
      errors.quantityOut = 'quantityOut is required !'
    }
    if (values.unitTypeId === '') {
      errors.unitTypeId = 'Unit Type is required!'
    }
    if (values.recordDate === '') {
      errors.recordDate = 'Record date is required!'
    }

    if (Object.keys(errors).length === 0) {
      setIsSubmit(true)
    } else {
      setIsSubmit(false)
    }
    return errors
  }
  const allStores = useSelector((state) => state.stores_reducer.stores)
  const allProducts = useSelector((state) => state.products_reducer.products)
  const allUnitTypes = useSelector(
    (state) => state.unit_types_reducer.unit_types,
  )
  const addStocks = useSelector((state) => state.stocks_reducer.addStock)
  const updateStocks = useSelector((state) => state.stocks_reducer.updateStock)
  console.log(allProducts)
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      if (props.type == 'add') {
        dispatch(addStock(formValues))
      }
      if (props.type == 'update') {
        dispatch(updateStock(formValues))
      }
    }
  }, [formErrors])

  useEffect(() => {
    if (addStocks && addStocks !== 'isLoading') {
      if (props.type == 'add') {
        props.onClose()
        resetValues()
        setIsSubmit(false)
      }
    }
  }, [addStocks])

  useEffect(() => {
    if (updateStocks && updateStocks != 'isLoading') {
      if (props.type == 'update') {
        props.onClose()
        setIsSubmit(false)
        dispatch(nullUpdateStock())
      }
    }
  }, [updateStocks])

  const submitHandle = async (e) => {
    setFormErrors(validate(formValues))
  }

  console.log(allStores)
  console.log(allProducts.products)
  console.log(allUnitTypes)

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => {
          resetValues()
          props.onClose()
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={MODAL_STYLE}>
          <Typography
            sx={{ marginBottom: '30px !important', fontWeight: '600' }}
            className="text-center mb-5"
            id="modal-modal-title"
            variant="h5"
            component="h2"
          >
            {props.type == 'add' && 'Add Stock'}{' '}
            {props.type == 'update' && 'Edit Stock'}{' '}
            <img
              onClick={() => {
                resetValues()
                props.onClose()
              }}
              style={{
                position: 'absolute',
                top: '35px',
                right: '30px',
                height: '30px',
                cursor: 'pointer',
              }}
              src={CrossIcon}
              alt=""
            />
          </Typography>
          {allStores &&
          allStores !== 'isLoading' &&
          allProducts &&
          allProducts !== 'isLoading' &&
          allUnitTypes &&
          allUnitTypes !== 'isLoading' ? (
            <div>
              <div className="row mt-2">
                <div className="col-6 pr-2">
                  <Autocomplete
                    id="grouped-demo"
                    name="productId"
                    size="small"
                    value={
                      allProducts.products.find(
                        (product) => product.id == formValues.productId,
                      )?.active
                        ? allProducts.products.find(
                            (product) => product.id == formValues.productId,
                          )
                        : null
                    }
                    onChange={(e, newVal) => {
                      setFormValues({
                        ...formValues,
                        ['productId']: newVal ? newVal.id : null,
                      })
                    }}
                    options={allProducts.products}
                    //   groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        error={formErrors.productId}
                        helperText={formErrors.productId}
                        {...params}
                        label="Product *"
                        name="product"
                      />
                    )}
                    required
                  />
                </div>
                <div className="col-6 pl-2">
                  <Autocomplete
                    id="grouped-demo"
                    name="type"
                    size="small"
                    value={
                      allStores.stores.find(
                        (store) => store.id == formValues.storeId,
                      )?.active
                        ? allStores.stores.find(
                            (store) => store.id == formValues.storeId,
                          )
                        : null
                    }
                    onChange={(e, newVal) => {
                      setFormValues({
                        ...formValues,
                        ['storeId']: newVal ? newVal.id : null,
                      })
                      setStoreLocation(
                        [newVal.block, newVal.area, newVal.city, newVal.state]
                          .filter((e) => e !== null)
                          .join(', '),
                      )
                    }}
                    options={allStores.stores.filter((e) => e.active)}
                    //   groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        error={formErrors.storeId}
                        helperText={formErrors.storeId}
                        {...params}
                        label="Store *"
                        name="storeId"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-6 pr-2">
                  <TextField
                    name="storelocation"
                    title={storelocation}
                    value={storelocation}
                    size="small"
                    className="form-control"
                    id="outlined-basic"
                    label="Store location"
                    variant="outlined"
                    disabled
                    required
                  />
                </div>
                <div className="col-6 pl-2">
                  <TextField
                    name="quantity"
                    error={formErrors.quantity}
                    helperText={formErrors.quantity}
                    value={formValues.quantity}
                    onChange={hanleChange}
                    size="small"
                    className="form-control"
                    id="outlined-basic"
                    label="Quantity"
                    variant="outlined"
                    required
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-6 pl-2">
                  <TextField
                    name="quantityOut"
                    error={formErrors.quantityOut}
                    helperText={formErrors.quantityOut}
                    value={formValues.quantityOut}
                    onChange={hanleChange}
                    size="small"
                    className="form-control"
                    id="outlined-basic"
                    label="Quantity Out"
                    variant="outlined"
                    required
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-6 pr-2">
                  <Autocomplete
                    id="grouped-demo"
                    name="unitTypeId"
                    size="small"
                    value={
                      allUnitTypes.unitTypes.find(
                        (unitType) => unitType.id == formValues.unitTypeId,
                      )?.active
                        ? allUnitTypes.unitTypes.find(
                            (unitType) => unitType.id == formValues.unitTypeId,
                          )
                        : null
                    }
                    onChange={(e, newVal) => {
                      setFormValues({
                        ...formValues,
                        ['unitTypeId']: newVal ? newVal.id : null,
                      })
                    }}
                    options={allUnitTypes.unitTypes.filter((e) => e.active)}
                    //   groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        error={formErrors.unitTypeId}
                        helperText={formErrors.unitTypeId}
                        {...params}
                        label="Unit Type *"
                        name="unitTypeId"
                      />
                    )}
                  />
                </div>
                <div className="col-6 pl-2">
                  <DatePicker
                    label="Record Date"
                    defaultValue={formValues.recordDate}
                    slotProps={{ textField: { size: 'small' } }}
                    onChange={(newValue) =>
                      setFormValues({ ...formValues, ['recordDate']: newValue })
                    }
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-6 pr-2">
                  <TextField
                    name="createdBy"
                    onChange={hanleChange}
                    value={user.firstName + ' ' + user.lastName}
                    size="small"
                    className="form-control"
                    id="outlined-basic"
                    label="Contact Name"
                    disabled
                    variant="outlined"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <Button
                    sx={{ marginTop: '30px', fontWeight: '600' }}
                    onClick={submitHandle}
                    type="submit"
                    name="submit"
                    className=" btn btn-primary text-white w-100 text-capitalize"
                    disabled={isSubmit && formErrors != {}}
                  >
                    {addStocks === 'isLoading' ||
                    updateStocks === 'isLoading' ? (
                      <CircularProgress sx={{ color: 'white' }} />
                    ) : props.type == 'add' ? (
                      'Add'
                    ) : (
                      'Update'
                    )}
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className="d-flex">
              <CircularProgress
                sx={{ color: 'rgba(0, 45, 114, 1)', margin: 'auto' }}
              />
            </div>
          )}
        </Box>
      </Modal>
    </>
  )
}

export default AUStockModal
