import {
    LOCATION_TYPE_SUCCESS,
    LOCATION_TYPE_PENDING,
    LOCATION_TYPE_FAIL,
    ADD_LOCATION_TYPE_SUCCESS,
    ADD_LOCATION_TYPE_PENDING,
    ADD_LOCATION_TYPE_FAIL,
    UPDATE_LOCATION_TYPE_SUCCESS,
    UPDATE_LOCATION_TYPE_PENDING,
    UPDATE_LOCATION_TYPE_FAIL,
    SET_MESSAGE
} from "../types";
import { toast } from 'react-toastify';
import UserService from "../../services/user.service";

//All location types

export const getLocationType = (pageNumber, pageSize, searchQuery) => async (dispatch) => {
    dispatch({
        type: LOCATION_TYPE_PENDING,
        payload: { locationTypes: "isLoading" },
    });
    return await UserService.getLocationType(pageNumber, pageSize, searchQuery).then(
        (data) => {
            data.data.status ?
                dispatch({
                    type: LOCATION_TYPE_SUCCESS,
                    payload: { locationTypes: data.data },
                }) :
                dispatch({
                    type: LOCATION_TYPE_FAIL,
                });
            return Promise.resolve();
        },
        (error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: LOCATION_TYPE_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        console.log(error)
    });
};

//add location types
export const addLocationType = (body) => async (dispatch) => {
    dispatch({
        type: ADD_LOCATION_TYPE_PENDING,
        payload: { addLocationTypes: "isLoading" },
    });
    return await UserService.addLocationType(body).then(
        (data) => {
            data.data.status ? toast.success("Location type added Successfully !", { autoClose: 2000 }) : toast.error(data.data.message, { autoClose: 2000 });
            data.data.status ?
                dispatch({
                    type: ADD_LOCATION_TYPE_SUCCESS,
                    payload: { addLocationType: data.data.status },
                }) :
                dispatch({
                    type: ADD_LOCATION_TYPE_FAIL,
                    payload: { addLocationType: data.data.status },
                });
            return Promise.resolve();
        },
        (error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: ADD_LOCATION_TYPE_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        console.log(error)
    }).finally(()=>{
        dispatch({
            type:ADD_LOCATION_TYPE_FAIL
        });
    })
}   

//update location types
export const updateLocationType = (body) => async (dispatch) => {
    dispatch({
        type: UPDATE_LOCATION_TYPE_PENDING,
        payload: { updateLocationType: "isLoading" },
    });
    return await UserService.updateLocationType(body).then(
        (data) => {
            data.data.status ? toast.success("Location type updated Successfully !", { autoClose: 2000 }) : toast.error(data.data.message, { autoClose: 2000 });
            data.data.status ?
                dispatch({
                    type: UPDATE_LOCATION_TYPE_SUCCESS,
                    payload: { updateLocationType: data.data.status },
                }) :
                dispatch({
                    type: LOCATION_TYPE_FAIL,
                    payload: { updateLocationType: data.data.status },
                });
            return Promise.resolve();
        },
        (error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: UPDATE_LOCATION_TYPE_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        console.log(error)
    }).finally(()=>{
        dispatch({
            type:UPDATE_LOCATION_TYPE_FAIL
        });
    })
}   