import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { addTaskTypes, updateTaskTypes } from '../../actions/userActions/taskTypeAction'
import Loader from '../subComponents/Loader'
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import CancelButton from '../subComponents/buttons/CancelButton';
import SaveButton from '../subComponents/buttons/SaveButton';
function AddTaskTypes() {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const initialValues = { name: "", description: "", active: false };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        if (location.taskType) {
          setFormValues({
            ...formValues,
            name: location.taskType.row.name,
            description: location.taskType.row.description,
            id: location.id,
            active: location.taskType.row.active,
          });
        }
      }, [location]);

    const addTaskTypeHandle = (e) => {
        e.preventDefault();
        const errors = validate(formValues);
        setFormErrors(errors);
    
        if (Object.keys(errors).length === 0) {
          if (location.taskType) {
            dispatch(updateTaskTypes(formValues));
          } else {
            dispatch(addTaskTypes(formValues));
          }
        }
      };
    const handleGoBack = () => {
        history.goBack();
    };
    const hanleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
        if (name === "active" && formValues.active === true) {
            setFormValues({ ...formValues, [name]: false });
        }
        else if (name === "active" && formValues.active === false) {
            setFormValues({ ...formValues, [name]: true });
        }
    }

    const validate = (values) => {
        const errors = {};
        if (!values.name) {
            errors.name = "Name is required!"
        }
        if (!values.description) {
            errors.description = "Description is required!"
        }
        return errors;
    }
    const addTaskType = useSelector(state => state.task_types_reducer.addTaskTypes);
    const updateTaskType = useSelector(state => state.task_types_reducer.updateTaskTypes);
    const permissions = JSON.parse(localStorage.getItem("permissionsList"));
    return (
        <>
            {addTaskType && addTaskType !== "isLoading" && <Redirect to="/admin/taskTypes" />}
            {/* {updateTaskType && updateTaskType !== "isLoading" && isSubmit && <Redirect to="/admin/taskTypes"/>} */}
            {!location.taskType && <Redirect to="/admin/AddTaskTypes" />}
            {!permissions.includes("CanAddTaskType") && <Redirect to="/admin" />}
            {!permissions.includes("CanUpdateTaskType") && <Redirect to="/admin" />}

            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-6">
                        <h2 className='d-inine-block float-left'><i onClick={history.goBack} className="fas fa-arrow-left" aria-hidden="true" style={{ cursor: "pointer" }} /> {location.taskType ? "Update Task Type" : "Add Task Type"}</h2>
                    </div>
                    <div className="col-md-6">
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="form-control-label">NAME</label>
                            <input name='name' type="text" className="form-control"
                                value={formValues.name}
                                onChange={hanleChange}
                            />
              <div style={{ color: "red" }}>{formErrors.name}</div>
              </div>
                        <div className="form-group">
                            <label className="form-control-label">Description</label>
                            <input name='description' type="text" className="form-control"
                                value={formValues.description}
                                onChange={hanleChange}
                            />
              <div style={{ color: "red" }}>{formErrors.description}</div>
              </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="form-control-label">Is task type active ?</label>
                                <input name='active' type="checkbox" checked={formValues.active} className='ml-2 mt-2'
                                    onChange={hanleChange}
                                />
                            </div>
                        </div>
                        {/* {addTaskType === "isLoading" ?
                            <div className="col-lg-12 loginbttm">
                                <Loader style={{ color: "red" }} />
                            </div>
                            : */}

                            <div
                                className="col-lg-12"
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    gap: "20px",
                                }}
                            >
                                <CancelButton onClick={handleGoBack} />
                                <SaveButton onClick={addTaskTypeHandle}
                                    isLoading={
                                        addTaskType === "isLoading" ||
                                        updateTaskType === "isLoading"
                                      }
                                    text={location.taskType ? "Update " : "Save"}></SaveButton>
                                </div>
                            {/*  }*/}
                    </div>
                </div>
            </div>
        </>
    )
}


export default AddTaskTypes
