import {
    UNIT_TYPE_SUCCESS,
    UNIT_TYPE_FAIL,
    UNIT_TYPE_PENDING,
    ADD_UNIT_TYPE_SUCCESS,
    ADD_UNIT_TYPE_FAIL,
    ADD_UNIT_TYPE_PENDING,
    UPDATE_UNIT_TYPE_SUCCESS,
    UPDATE_UNIT_TYPE_FAIL,
    UPDATE_UNIT_TYPE_PENDING,
} from "../../actions/types";

const initialState = { 
    unitTypes: [],
    addUnitTypes : '',
    updateUnitType : '',
    };

const unit_types_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case UNIT_TYPE_SUCCESS:
            return {
                ...state,
                unit_types: payload.unitTypes,
            };
        case UNIT_TYPE_PENDING:
            return {
                ...state,
                unit_types: payload.unitTypes,
            };
        case UNIT_TYPE_FAIL:
            return {
                ...state,
                unit_types: []
            };
        case ADD_UNIT_TYPE_SUCCESS:
            return {
                ...state,
                addUnitTypes: payload.addUnitTypes,
            };
        case ADD_UNIT_TYPE_PENDING:
            return {
                ...state,
                addUnitTypes: payload.addUnitTypes,
            };
        case ADD_UNIT_TYPE_FAIL:
            return {
                ...state,
                addUnitTypes: false
            };
            case UPDATE_UNIT_TYPE_SUCCESS:
                return {
                    ...state,
                    updateUnitType: payload.updateUnitType,
                };
            case UPDATE_UNIT_TYPE_PENDING:
                return {
                    ...state,
                    updateUnitType: payload.updateUnitType,
                };
            case UPDATE_UNIT_TYPE_FAIL:
                return {
                    ...state,
                    updateUnitType: false
                };
        default:
            return state;
    }
}
export default unit_types_reducer