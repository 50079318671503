import {
    CALL_AVERAGE_TASKS_SUCCESS,
    CALL_AVERAGE_TASKS_FAIL,
    CALL_AVERAGE_TASKS_PENDING,
  } from "../../actions/types";
  
  const initialState = {
    callAvgTasks: [],
  };
  
  const call_avg_tasks_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case CALL_AVERAGE_TASKS_SUCCESS:
        return {
          ...state,
          callAvgTasks: payload.callAvgTasks,
        };
      case CALL_AVERAGE_TASKS_PENDING:
        return {
          ...state,
          callAvgTasks: payload.callAvgTasks,
        };
      case CALL_AVERAGE_TASKS_FAIL:
        return {
          ...state,
          callAvgTasks: [],
        };
      default:
        return state;
    }
  };
  export default call_avg_tasks_reducer;