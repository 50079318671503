import {
    PERMISSION_SUCCESS,
    PERMISSION_FAIL,
    PERMISSION_PENDING,
    ALL_PERMISSION_SUCCESS,
    ALL_PERMISSION_FAIL,
    ALL_PERMISSION_PENDING,
    PERMISSION_GROUP_SUCCESS,
    PERMISSION_GROUP_FAIL,
    PERMISSION_GROUP_PENDING,
    ADD_PERMISSION_SUCCESS,
    ADD_PERMISSION_FAIL,
    ADD_PERMISSION_PENDING,
    UPDATE_PERMISSION_SUCCESS,
    UPDATE_PERMISSION_FAIL,
    UPDATE_PERMISSION_PENDING
} from "../../actions/types";

const initialState = { 
    permissions: [],
    allPermissions: [],
    allPermissionsGroup: [],
    addPermission: '',
    updatePermission: '',
    };

const permissions_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case PERMISSION_SUCCESS:
            return {
                ...state,
                permissions: payload.permissions,
            };
        case PERMISSION_PENDING:
            return {
                ...state,
                permissions: payload.permissions,
            };
        case PERMISSION_FAIL:
            return {
                ...state,
                permissions: []
            };
            case ALL_PERMISSION_SUCCESS:
            return {
                ...state,
                allPermissions: payload.allPermissions,
            };
        case ALL_PERMISSION_PENDING:
            return {
                ...state,
                allPermissions: payload.allPermissions,
            };
        case ALL_PERMISSION_FAIL:
            return {
                ...state,
                allPermissions: []
            };
            case PERMISSION_GROUP_SUCCESS:
            return {
                ...state,
                allPermissionsGroup: payload.allPermissionsGroup,
            };
        case PERMISSION_GROUP_PENDING:
            return {
                ...state,
                allPermissionsGroup: payload.allPermissionsGroup,
            };
        case PERMISSION_GROUP_FAIL:
            return {
                ...state,
                allPermissionsGroup: []
                
            };
            case ADD_PERMISSION_SUCCESS:
            return {
                ...state,
                addPermission: payload.addPermission,
            };
        case ADD_PERMISSION_PENDING:
            return {
                ...state,
                addPermission: payload.addPermission,
            };
        case ADD_PERMISSION_FAIL:
            return {
                ...state,
                addPermission: false
            };
            case UPDATE_PERMISSION_SUCCESS:
            return {
                ...state,
                updatePermission: payload.updatePermission,
            };
        case UPDATE_PERMISSION_PENDING:
            return {
                ...state,
                updatePermission: payload.updatePermission,
            };
        case UPDATE_PERMISSION_FAIL:
            return {
                ...state,
                updatePermission: false
            };
        default:
            return state;
    }
}
export default permissions_reducer