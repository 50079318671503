import {
  CUSTOMER_RANK_SUCCESS,
  CUSTOMER_RANK_FAIL,
  CUSTOMER_RANK_PENDING,
  ADD_CUSTOMER_RANK_SUCCESS,
  ADD_CUSTOMER_RANK_FAIL,
  ADD_CUSTOMER_RANK_PENDING,
  UPDATE_CUSTOMER_RANK_SUCCESS,
  UPDATE_CUSTOMER_RANK_PENDING,
  UPDATE_CUSTOMER_RANK_FAIL,
} from "../../actions/types";

const initialState = {
  customerRank: [],
  addDesignation : '',
};

const customer_rank_reducer = (state = initialState, action) => {
  const { type, payload } = action;
  
  switch (type) {
    case CUSTOMER_RANK_SUCCESS:
      return {
        ...state,
        customerRank: payload.customerRank,
      };
    case CUSTOMER_RANK_PENDING:
      return {
        ...state,
        customerRank: payload.customerRank,
      };
    case CUSTOMER_RANK_FAIL:
      return {
        ...state,
        customerRank: [],
      };

      case ADD_CUSTOMER_RANK_SUCCESS:
        return {
            ...state,
            addCustomerRank: payload.addCustomerRank,
        };
    case ADD_CUSTOMER_RANK_PENDING:
        return {
            ...state,
            addCustomerRank: payload.addCustomerRank,
        };
    case ADD_CUSTOMER_RANK_FAIL:
        return {
            ...state,
            addCustomerRank: false
        };
        case UPDATE_CUSTOMER_RANK_SUCCESS:
            return {
                ...state,
                updateCustomerRank: payload.updateCustomerRank,
            };
        case UPDATE_CUSTOMER_RANK_PENDING:
            return {
                ...state,
                updateCustomerRank: payload.updateCustomerRank,
            };
        case UPDATE_CUSTOMER_RANK_FAIL:
            return {
                ...state,
                updateCustomerRank: false
            };
    default:
      return state;
  }
};
export default customer_rank_reducer;
