import {
  STORE_TYPE_SUCCESS,
  STORE_TYPE_FAIL,
  SET_MESSAGE,
  STORE_TYPE_PENDING,
  ADD_STORE_TYPE_SUCCESS,
  ADD_STORE_TYPE_FAIL,
  ADD_STORE_TYPE_PENDING,
  UPDATE_STORE_TYPE_SUCCESS,
  UPDATE_STORE_TYPE_FAIL,
  UPDATE_STORE_TYPE_PENDING,
} from "../types";
import { toast } from "react-toastify";
import UserService from "../../services/user.service";

export const getStoreTypes = (body) => async (dispatch) => {
  dispatch({
    type: STORE_TYPE_PENDING,
    payload: { storeTypes: "isLoading" },
  });
  return await UserService.getStoreTypes(body)
    .then(
      (data) => {
        console.log(data);
        data.data.status
          ? dispatch({
              type: STORE_TYPE_SUCCESS,
              payload: { storeTypes: data.data.result[0] },
            })
          : dispatch({
              type: STORE_TYPE_FAIL,
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: STORE_TYPE_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};

//add store
export const addStoreType = (body) => async (dispatch) => {
  dispatch({
    type: ADD_STORE_TYPE_PENDING,
    payload: { addStoreTypes: "isLoading" },
  });
  return await UserService.addStoreType(body)
    .then(
      (data) => {
        data.data.status
          ? toast.success("Store Type added Successfully !", {
              autoClose: 2000,
            })
          : toast.error(data.data.message, { autoClose: 2000 });
        data.data.status
          ? dispatch({
              type: ADD_STORE_TYPE_SUCCESS,
              payload: { addStoreTypes: data.data.status },
            })
          : dispatch({
              type: ADD_STORE_TYPE_FAIL,
              payload: { addStoreTypes: data.data.status },
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: ADD_STORE_TYPE_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};
// Update storeTypes
export const updateStoreType = (body) => async (dispatch) => {
  dispatch({
    type: UPDATE_STORE_TYPE_PENDING,
    payload: { updateStoreType: "isLoading" },
  });
  return await UserService.updateStoreType(body)
    .then(
      (data) => {
        data.data.status
          ? toast.success("Store Type updated Successfully !", {
              autoClose: 2000,
            })
          : toast.error(data.data.message, { autoClose: 2000 });
        data.data.status
          ? dispatch({
              type: UPDATE_STORE_TYPE_SUCCESS,
              payload: { updateStoreType: data.data.status },
            })
          : dispatch({
              type: UPDATE_STORE_TYPE_FAIL,
              payload: { updateStoreType: data.data.status },
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: UPDATE_STORE_TYPE_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};

export const nullUpdateStoreType = () => (dispatch) => {
  return dispatch({
    type: UPDATE_STORE_TYPE_PENDING,
    payload: { updateStoreType: "" },
  });
};
