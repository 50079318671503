import axios from "axios";
import authHeader from './auth-header';
import { BASE_URL } from "../../Constatns";

const API_URL = BASE_URL+"Auth/";

class AuthService {
  
  async refreshToken(body){
     return axios.post(API_URL + 'GetAccessToken',body,{ headers: authHeader() });
   }

  async login(email, password) {
   
    const body = {
      "email": email,
      "password": password
    };
    console.log('Login API Request Body:', body);
    return axios
    
      .post(API_URL+"LoginAWS",body)
      .then((response) => {
        console.log(response, "response of login");
        if (response.data) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      }).catch((error => {
        return error;
      }));
  }

   getDeviceID() {
    if (navigator && navigator.userAgent) {
      //setDeviceId(navigator.userAgent);
      return navigator.userAgent;
    } else {
     // onsetDeviceId("default_device_id");
      return "default_device_id";
    }
  }

  async logout(employeeId, currentPassword) {
    let deviceId=this.getDeviceID();
    console.log(deviceId, "text");
    const body = {
      employeeId,
      currentPassword,
      deviceId
    };
    console.log(body);

    try {
      const response = await axios.post(API_URL + 'Logout', body, { headers: authHeader() });
      if (response.data.status) {
        localStorage.removeItem("user");
        localStorage.removeItem("path");
        localStorage.removeItem("fcmPosted");
        localStorage.removeItem("permissionsList");
        // Optional: Redirect to login page or home page after logout
        window.location.href = '/';
      }
      return response.data;
    } catch (error) {
      return error;
    }
  }
}

export default new AuthService();
