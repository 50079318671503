import React from 'react'
import '../../subComponents/statusChip/StatusChip.css'

function StatusChip(props) {
    return (
        <>
        <div className={props.status ? "chip-green" : "chip-red"}>
            <div className={props.status ? "text-green" : "text-red"}>
                <p>{props.status ? "Active" : "In-active"}</p>
            </div>
        </div>
        </>
    )
}
export default StatusChip
