import { React, useEffect } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import StatusChip from '../statusChip/StatusChip'
import TableAction from '../tableActions/TableAction'
import moment from 'moment'
import { DATE_FORMAT } from '../../../Constatns'
function TableComponent(props) {
  const styles = {
    titleBold: {
      fontSize: '16px',
      fontWeight: '600',
      marginBottom: '8px',
      color: '#272727',
    },
    titleRegular: {
      fontSize: '16px',
      marginBottom: '8px',
      color: '#272727',
    },
  }
  return (
    <>
      <Table aria-label="simple table" className="mt-5">
        <TableHead>
          <TableRow>
            {props.headers &&
              props.headers.map((head, index) => (
                <TableCell
                  key={index}
                  style={{
                    fontSize: '18px',
                    fontWeight: '600',
                    color: 'black',
                  }}
                >
                  {head}
                </TableCell>
              ))}
            <TableCell
              align={'center'}
              style={{ fontSize: '18px', fontWeight: '600', color: 'black' }}
            >
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data &&
            props.data.map((d, index) => (
              <TableRow key={index}>
                {props.keys &&
                  props.keys.map((k, i) => (
                    <TableCell key={i} component="th" scope="row">
                      <div className="d-flex flex-column">
                        <p
                          style={
                            props.doubleTitle && i == 0
                              ? styles.titleBold
                              : styles.titleRegular
                          }
                        >
                          {k == 'active' ? (
                            <span style={{ fontSize: '14px' }}>
                              <StatusChip status={d[k]} />
                            </span>
                          ) : moment(
                              d[k],
                              'Y-MM-DDTHH:mm:SS',
                              true,
                            ).isValid() ? (
                            moment(d[k]).format(DATE_FORMAT)
                          ) : (
                            d[k]
                          )}
                        </p>
                        {props.doubleTitle && i == 0 && (
                          <p
                            style={{
                              color: 'rgba(58, 58, 58, 0.48)',
                              fontSize: '14px',
                              fontWeight: '600',
                            }}
                          >
                            {props.descriptionKeys &&
                              props.descriptionKeys
                                .map((dk) => {
                                  if (d[dk] != undefined) {
                                    return d[dk]
                                  }
                                })
                                .filter(
                                  (e) =>
                                    e !== undefined && e !== ' ' && e !== null,
                                )
                                .join(', ')}
                          </p>
                        )}
                      </div>
                    </TableCell>
                  ))}
                <TableCell align="right">
                  <TableAction
                    onEyeClick={() => {
                      props.onEyeClick()
                      props.setData(d)
                    }}
                    onEditClick={() => {
                      props.onEditClick()
                      props.setData(d)
                    }}
                    onDeleteClick={props.onDeleteClick}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </>
  )
}

export default TableComponent
