import React from "react";
import { Link } from "react-router-dom";

const MenuItem = ({
  icon,
  text,
  to = "#",
  onClick = null,
  className = "",
  isSidebarOpen = true,
  hasArrow = true,
}) => {
  return (
    <li className={`menu-item ${className} ${isSidebarOpen ? "" : "no-arrow"}`}>
      <Link
        to={to}
        className="d-flex align-items-center"
        onClick={onClick}
      >
        <div className="icon-wrapper">
          <img className="icon" src={icon} alt={text} />
        </div>
        {isSidebarOpen && <span className="sidebar-menu-title">{text}</span>}
      </Link>
    </li>
  );
};

export default MenuItem;
